import { useQuery } from "react-query";
import axiosClient, { setAccessToken } from "../../client/restApi";

export const useGetAllCurrenciesQuery = () => {
  return useQuery("currencies", fetchAllCurrencies);
};

export const fetchAllCurrencies = async () => {
  const accessTokenResult = await setAccessToken();
  let response = {};
  if (accessTokenResult) {
    response = await axiosClient.get("/currencies");
  }
  return response?.data?.data || [];
};
