import qs from "qs";
import { useQuery } from "react-query";
import axiosClient, { setAccessToken } from "../../client/restApi";
import { isEmptyDate } from "../DateTimeUtil";

export const useGetAllProductsOfEcosystemQuery = (
  ecosystemId,
  filterParams,
  sortParams,
  paginationParams,
  dataTableFilterParams
) => {
  return useQuery(
    [
      "productTracking",
      "products-data-table",
      ecosystemId,
      filterParams,
      sortParams,
      paginationParams,
      dataTableFilterParams,
    ],
    () =>
      fetchAllProductsOfEcosystem(
        ecosystemId,
        filterParams,
        sortParams,
        paginationParams,
        dataTableFilterParams
      ),
    {
      enabled:
        !isEmptyDate(filterParams?.dateFrom) && ecosystemId !== undefined,
    }
  );
};

const fetchAllProductsOfEcosystem = async (
  ecosystemId,
  filterParams,
  sortParams,
  paginationParams,
  dataTableFilterParams
) => {
  await setAccessToken();
  const response = await axiosClient.get(
    `/ecosystems/${ecosystemId}/products`,
    {
      params: {
        ...filterParams,
        ...sortParams,
        ...paginationParams,
        filters: {
          ...dataTableFilterParams,
        },
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false });
      },
    }
  );
  return response.data.data || [];
};

export const useGetAllAvailabilities = (
  ecosystemId,
  filterParams,
  dataTableFilterParams
) => {
  return useQuery(
    [
      "productTracking",
      "all-availabilities",
      ecosystemId,
      filterParams,
      dataTableFilterParams,
    ],
    () =>
      fetchAllAvailabilities(ecosystemId, filterParams, dataTableFilterParams),
    {
      enabled:
        !isEmptyDate(filterParams?.dateFrom) && ecosystemId !== undefined,
    }
  );
};

const fetchAllAvailabilities = async (
  ecosystemId,
  filterParams,
  dataTableFilterParams
) => {
  await setAccessToken();
  const response = await axiosClient.get(
    `/ecosystems/${ecosystemId}/products/availabilities`,
    {
      params: {
        ...filterParams,
        filters: {
          ...dataTableFilterParams,
        },
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false });
      },
    }
  );
  return response.data.data || [];
};
