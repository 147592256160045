import React, { useState } from "react";
import EcosystemEditModal from "../EcosystemEditModal";

const EcosystemPopoverTitle = ({
  handlePopoverVisible,
  ecosystemName,
  ecosystemId,
  ecosystemIndustry,
  ecosystemCurrencyId,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleOnClick = () => {
    setIsVisible(true);
    handlePopoverVisible(false);
  };

  return (
    <>
      <span onClick={handleOnClick}>Edit Ecosystem</span>
      <EcosystemEditModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        currentEcosystemName={ecosystemName}
        ecosystemId={ecosystemId}
        ecosystemIndustry={ecosystemIndustry}
        ecosystemCurrencyId={ecosystemCurrencyId}
      />
    </>
  );
};

export default EcosystemPopoverTitle;
