import { Popover, Button } from "antd";
import PopoverContent from "./PopoverContent";

const FontSizeChange = ({ setFontSize, fontSize }) => {
  return (
    <Popover
      placement="bottomLeft"
      content={<PopoverContent setFontSize={setFontSize} fontSize={fontSize} />}
      trigger="click"
    >
      <Button className="data-table-filter">Font Size</Button>
    </Popover>
  );
};

export default FontSizeChange;
