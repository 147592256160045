import { Spin, Empty } from "antd";

const CustomSpin = () => {
  return (
    <Spin>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </Spin>
  );
};

export default CustomSpin;
