import { useQuery } from "react-query";
import axiosClient, { setAccessToken } from "../../client/restApi";

export const useGetGGAuthorizationURI = (enabled, ecosystemId, websiteId) => {
  return useQuery(
    ["gg-authorization-uri", ecosystemId, websiteId],
    () => fetchGGAuthorizationURI(ecosystemId, websiteId),
    {
      enabled: enabled,
    }
  );
};

const fetchGGAuthorizationURI = async (ecosystemId, websiteId) => {
  const accessTokenResult = await setAccessToken();
  let response = {};
  if (accessTokenResult) {
    response = await axiosClient.get(
      `ecosystems/${ecosystemId}/website/${websiteId}/analytics/authorizationURI`
    );
  }
  return response?.data?.data || [];
};

export const useGetGGAccount = (enabled, ecosystemId, websiteId) => {
  return useQuery(["get-gg-account", ecosystemId, websiteId], () =>
    fetchGGAccount(ecosystemId, websiteId),
    { enabled: enabled }

  );
};

const fetchGGAccount = async (ecosystemId, websiteId) => {
  const accessTokenResult = await setAccessToken();
  let response = {};
  if (accessTokenResult) {
    response = await axiosClient.get(
      `ecosystems/${ecosystemId}/website/${websiteId}/analytics/account`
    );
  }

  return response?.data?.data;
};

export const useGetGAAccount = (enabled, ecosystemId, websiteId) => {
  return useQuery(
    ["get-ga-account", ecosystemId, websiteId],
    () => fetchGetGAAccount(ecosystemId, websiteId),
    { enabled: enabled }
  );
};

const fetchGetGAAccount = async (ecosystemId, websiteId) => {
  const accessTokenResult = await setAccessToken();
  let response = {};
  if (accessTokenResult) {
    response = await axiosClient.get(
      `ecosystems/${ecosystemId}/website/${websiteId}/analytics/listAccounts`
    );
  }

  return response?.data?.data || [];
};
