import { Button, Col, Divider, Form, Input, Row } from "antd";
import React, { useCallback, useState } from "react";
import "./PopoverContent.scss";

const PopoverContent = ({ setFilters, filters, handleApply, setApplied }) => {
  const [allTitleKeywords, setAllTitleKeywords] = useState("");
  const [anyTitleKeywords, setAnyTitleKeywords] = useState("");
  const [noTitleKeywords, setNoTitleKeywords] = useState("");
  const [allURLKeywords, setAllURLKeywords] = useState("");
  const [anyURLKeywords, setAnyURLKeywords] = useState("");
  const [noURLKeywords, setNoURLKeywords] = useState("");
  const [urlStructure, setUrlStructure] = useState("");

  const [form] = Form.useForm();


  const handleKeywords = useCallback((changedFilters, keywordType, allKeywords, anyKeywords, noKeywords, structure = null) => {
    function splitAndPreserveQuotes(input) {
      let parts = [];
      let currentPart = "";
      let quoted = false;

      for (let i = 0; i < input.length; i++) {
        const char = input[i];
        const nextChar = input[i + 1];

        if (char === "\"") {
          quoted = !quoted;
          if (!quoted && nextChar && /\w/.test(nextChar)) {
            currentPart += char + nextChar;
            parts.push(currentPart);
            currentPart = "";
            i++;
          } else {
            currentPart += char;
          }
        } else if ((char === "," || char === " ") && !quoted) {
          parts.push(currentPart.trim());
          currentPart = "";
          if (nextChar === " ") {
            i++;
          }
        } else {
          currentPart += char;
        }
      }

      parts.push(currentPart.trim());

      return parts.join(",");
    }

    changedFilters[keywordType] = {};

    if (allKeywords) {
      changedFilters[keywordType].contains = splitAndPreserveQuotes(allKeywords);
    }

    if (anyKeywords) {
      changedFilters[keywordType].isAnyOf = splitAndPreserveQuotes(anyKeywords);
    }

    if (noKeywords) {
      changedFilters[keywordType].whereNotIn = splitAndPreserveQuotes(noKeywords);
    }

    if (structure) {
      changedFilters[keywordType].like = structure;
    }
  }, []);

  const handleApplyOnClick = useCallback(() => {
    const changedFilters = { ...filters };

    handleKeywords(changedFilters, "title", allTitleKeywords, anyTitleKeywords, noTitleKeywords);
    handleKeywords(changedFilters, "url", allURLKeywords, anyURLKeywords, noURLKeywords, urlStructure);

    setFilters(changedFilters);
    handleApply();
  }, [filters, setFilters, allTitleKeywords, anyTitleKeywords, noTitleKeywords, allURLKeywords, anyURLKeywords, noURLKeywords, urlStructure, handleApply, handleKeywords]);

  const handleRefreshOnClick = useCallback(() => {
    setAllTitleKeywords("");
    setAnyTitleKeywords("");
    setNoTitleKeywords("");
    setAllURLKeywords("");
    setAnyURLKeywords("");
    setNoURLKeywords("");
    setUrlStructure("");

    const changedFilters = { ...filters };

    // Delete only the fields added by handleApply
    if ("title" in changedFilters) {
      delete changedFilters.title;
      delete changedFilters.title;
    }

    if ("url" in changedFilters) {
      delete changedFilters.url;
      delete changedFilters.url;
    }

    setFilters(changedFilters);
    // Reset the applied status and hide the popover
    setApplied(false);
    form.resetFields();
  }, [
    filters,
    setFilters,
    setAllTitleKeywords,
    setAnyTitleKeywords,
    setNoTitleKeywords,
    setAllURLKeywords,
    setAnyURLKeywords,
    setNoURLKeywords,
    setUrlStructure,
    setApplied,
    form,
  ]);

  return (
    <Form form={form} onFinish={handleApplyOnClick}>
      <div style={{ marginBottom: 16, display: "flex", alignItems: "center" }}>
        <div style={{ flex: 1 }}>
          <span style={{ fontSize: 18 }}>Advanced Search</span>
        </div>
        <div>
          <img src="/images/add-website-modal-icon.png" alt="Advanced Search" />
        </div>
      </div>
      <Divider />
      <span style={{ color: "#093159", fontSize: 18, fontWeight: "bold", lineHeight: 2.5 }}>Find Keywords in the page title</span>
      <Form.Item
        name="allTitleKeywords"
        label="All of these words"
        labelCol={{ span: 0 }}
        wrapperCol={{ span: 24, offset: 1 }}
      >
        < Input
          className="keyword-input"
          placeholder="separated by commas"
          value={allTitleKeywords}
          onChange={(e) => setAllTitleKeywords(e.target.value)}
        />
      </Form.Item>
      <Form.Item name="anyTitleKeywords" label="Any of these words" wrapperCol={{ span: 15, offset: 0 }}>
        <Input
          className="keyword-input"
          placeholder="separated by commas"
          value={anyTitleKeywords}
          onChange={(e) => setAnyTitleKeywords(e.target.value)}
          style={{ marginLeft: "8px" }}
        />
      </Form.Item>
      <Form.Item name="noTitleKeywords" label="None of these words" wrapperCol={{ span: 24, offset: 0 }}>
        <Input
          className="keyword-input"
          placeholder="separated by commas"
          value={noTitleKeywords}
          onChange={(e) => setNoTitleKeywords(e.target.value)}
        />
      </Form.Item>
      <span style={{ color: "#093159", fontSize: 18, fontWeight: "bold", lineHeight: 2.5 }}>Find Keywords in the page URL</span>
      <Form.Item name="allURLKeywords" label="All of these words" wrapperCol={{ span: 24, offset: 1 }}>
        <Input
          className="keyword-input"
          placeholder="separated by commas"
          value={allURLKeywords}
          onChange={(e) => setAllURLKeywords(e.target.value)}

        />
      </Form.Item>
      <Form.Item name="anyURLKeywords" label="Any of these words" wrapperCol={{ span: 15, offset: 0 }}>
        <Input
          className="keyword-input"
          placeholder="separated by commas"
          value={anyURLKeywords}
          onChange={(e) => setAnyURLKeywords(e.target.value)}
          style={{ marginLeft: "8px" }}

        />
      </Form.Item>
      <Form.Item name="noURLKeywords" label="None of these words">
        <Input
          className="keyword-input"
          placeholder="separated by commas"
          value={noURLKeywords}
          onChange={(e) => setNoURLKeywords(e.target.value)}
        />
      </Form.Item>
      <span style={{ color: "#093159", fontSize: 18, fontWeight: "bold", lineHeight: 2.5 }}>URL Structure</span>
      <Form.Item name="urlStructure" label="Page Path">
        <Input
          className="page-path-input"
          placeholder="/"
          value={urlStructure}
          onChange={(e) => setUrlStructure(e.target.value)}
        />
      </Form.Item>

      <Divider />
      <Row align="middle" justify="space-between">
        <Col>
          <Button className="text-filter-button" onClick={handleApplyOnClick} style={{width: "240px"}}>
            Apply
          </Button>
        </Col>
        <Col>
          <img
            className="refresh-icon"
            style={{ cursor: "pointer" }}
            src="/images/icon-refresh.png"
            alt="Icon Refresh"
            onClick={handleRefreshOnClick}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default PopoverContent;
