/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { PostData } from "../services/PostData";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import { CountryDropdown } from "react-country-region-selector";
import { LoggedInStatus } from "../services/LoggedInStatus";

const UpdatePayment = ({ location }) => {
  const [messages, _setMessages] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [success, setSuccess] = useState(false);

  const [signupValues, setSignupValues] = useState({
    name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  });
  const [errValues, setErrValues] = useState({
    name: "",
    country: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  });
  const [disableBtn, setDisableBtn] = useState(false);

  const [country, setCountry] = useState(null);

  useEffect(() => {
    let li = LoggedInStatus();
    setLoggedIn(li);
  }, []);
  //sub
  const setMessage = (message) => {
    _setMessages(`${messages}\n\n${message}`);
  };

  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    return "";
  }

  function signupChange(e) {
    setSignupValues({ ...signupValues, [e.target.name]: e.target.value });
    setDisableBtn(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisableBtn(true);
    let errors = false;
    const errList = {
      name: "",
      country: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
    };
    if (/^([a-zA-Z0-9 _-]+)$/.test(signupValues.name)) {
      errList.name = "";
    } else {
      errList.name = "This field is invalid";
      errors = true;
    }
    if (country !== "") {
      errList.country = "";
    } else {
      errList.country = "This field is invalid";
      errors = true;
    }
    if (signupValues.address1 !== "") {
      errList.address1 = "";
    } else {
      errList.address1 = "This field is invalid";
      errors = true;
    }
    setErrValues(errList);
    if (!errors) {
      createPayment();
    }
  };

  const createPayment = async () => {
    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setMessage(error.message);
    } else {
      const userDetails = {
        name: signupValues.name,
        country: country,
        address1: signupValues.address1,
        address2: signupValues.address2,
        state: signupValues.state,
        city: signupValues.city,
        zip: signupValues.zip,
        payment_method: paymentMethod.id,
      };
      const postOptions = {
        postRoute: "/update-customer",
        method: "PUT",
        token: true,
        formData: userDetails,
      };
      PostData(postOptions).then((result) => {
        const successTrue = result.success;
        setSuccess(successTrue);
      });
    }
  };

  const updateLogin = () => {
    setLoggedIn(true);
  };

  const logout = () => {
    setLoggedIn(false);
  };

  return (
    <Fragment>
      <Header updateLogin={updateLogin} logout={logout} loggedIn={loggedIn} />
      {success && (
        <div class="notification is-success">
          Payment method has been successfully changed
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <section className="section home-content has-background-light-grey">
          <div className="container">
            <div className="box max-width-box">
              <h4 className="subtitle is-4 has-text-centered">
                Payment Details
              </h4>
              <hr />
              <div className="content">
                <Fragment>
                  <div className="field">
                    <label>Name on card</label>
                    <p className="control">
                      <input
                        className="input"
                        type="text"
                        name="name"
                        placeholder="John Doe"
                        onChange={signupChange}
                      />
                    </p>
                    <p className="help is-danger">{errValues.name}</p>
                  </div>
                  <div className="field">
                    <div className="mb-3">
                      <label>Card number</label>
                    </div>
                    <div className="control">
                      <CardElement
                        className="border-card"
                        options={{ hidePostalCode: true }}
                      />
                    </div>
                    <p className="help is-danger">{messages}</p>
                  </div>

                  <div className="field">
                    <div className="mb-3">
                      <label>Country</label>
                    </div>
                    <div className="control">
                      <CountryDropdown
                        className="border-country"
                        name="country"
                        value={country}
                        onChange={setCountry}
                      />
                    </div>
                    <p className="help is-danger">{errValues.country}</p>
                  </div>
                  <div className="field">
                    <label>Address 1</label>
                    <p className="control">
                      <input
                        className="input"
                        type="text"
                        name="address1"
                        onChange={signupChange}
                      />
                    </p>
                    <p className="help is-danger">{errValues.address1}</p>
                  </div>
                  <div className="field">
                    <label>Address 2</label>
                    <p className="control">
                      <input
                        className="input"
                        type="text"
                        name="address2"
                        onChange={signupChange}
                      />
                    </p>
                    <p className="help is-danger">{errValues.address2}</p>
                  </div>
                  <div className="field">
                    <label>City/Suburb</label>
                    <p className="control">
                      <input
                        className="input"
                        type="text"
                        name="city"
                        onChange={signupChange}
                      />
                    </p>
                    <p className="help is-danger">{errValues.city}</p>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <label>State</label>
                      <p className="control is-expanded">
                        <input
                          className="input"
                          type="text"
                          name="state"
                          onChange={signupChange}
                        />
                      </p>
                      <p className="help is-danger">{errValues.state}</p>
                    </div>
                    <div className="field">
                      <label>Zip/Postcode</label>
                      <p className="control is-expanded">
                        <input
                          className="input"
                          type="text"
                          name="zip"
                          onChange={signupChange}
                        />
                      </p>
                      <p className="help is-danger">{errValues.zip}</p>
                    </div>
                  </div>
                  <div className="buttons is-centered mt-5">
                    <button
                      className="button is-success is-rounded"
                      disabled={disableBtn}
                    >
                      Update card
                    </button>
                  </div>
                </Fragment>
              </div>
            </div>
          </div>
        </section>
      </form>
      <Footer />
    </Fragment>
  );
};

export default UpdatePayment;
