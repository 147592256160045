import React from "react";
import EcosystemBoardContent from "./EcosystemBoardContent";
import EcosystemBoardHeader from "./EcosystemBoardHeader";
import "./index.scss";

const EcosystemBoard = ({ ecosystem, ecosystems, teamId }) => {
  return (
    <div className="ecosystem-board-container">
      {ecosystem && (
        <EcosystemBoardHeader
          ecosystemName={ecosystem?.name}
          ecosystemId={ecosystem?.id}
          ecosystemUuid={ecosystem?.uuid}
          ecosystemIndustry={ecosystem?.industry}
          ecosystemCurrencyId={ecosystem?.currency_id}


        />
      )}
      <EcosystemBoardContent
        ecosystem={ecosystem}
        ecosystems={ecosystems}
        teamId={teamId}
      />
    </div>
  );
};

export default EcosystemBoard;
