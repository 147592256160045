import { Input, Modal, Popover } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useDeleteTag, useUpdateTag } from "../../../utils/Queries/TagQueries";
import "./index.scss";

const TagCardExtra = ({ tag }) => {
  const { mutate: updateTag } = useUpdateTag();
  const { mutate: deleteTag } = useDeleteTag();
  const [isModalEditTagVisible, setIsModalEditTagVisible] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [tagName, setTagName] = useState(tag.name);

  const rule = useMemo(() => {
    let ruleData = {};
    if (tag.rules[0]) {
      ruleData = JSON.parse(tag.rules[0].rule);
    }
    return ruleData;
  }, [tag.rules]);

  const [allTitleWords, setAllTitleWords] = useState(Array.isArray(rule["allTitleKeyWords"]) ? rule["allTitleKeyWords"].join(",") : "");
  const [anyTitleWords, setAnyTitleWords] = useState(Array.isArray(rule["anyTitleKeyWords"]) ? rule["anyTitleKeyWords"].join(",") : "");
  const [noTitleWords, setNoTitleWords] = useState(Array.isArray(rule["noTitleKeyWords"]) ? rule["noTitleKeyWords"].join(",") : "");
  const [allURLWords, setAllURLWords] = useState(Array.isArray(rule["allURLKeyWords"]) ? rule["allURLKeyWords"].join(",") : "");
  const [anyURLWords, setAnyURLWords] = useState(Array.isArray(rule["anyURLKeyWords"]) ? rule["anyURLKeyWords"].join(",") : "");
  const [noURLWords, setNoURLWords] = useState(Array.isArray(rule["noURLKeyWords"]) ? rule["noURLKeyWords"].join(",") : "");
  const [pagePath, setPagePath] = useState(rule["urlStructure"]);

  const [isModalDeleteTagVisible, setIsModalDeleteTagVisible] = useState(false);

  const handleOnPopoverVisibleChange = useCallback(() => {
    setIsPopoverVisible(!isPopoverVisible);
  }, [isPopoverVisible]);

  const showModalEditTag = useCallback(() => {
    setIsModalEditTagVisible(true);
  }, []);

  const showModalDeleteTag = useCallback(() => {
    setIsModalDeleteTagVisible(true);
  }, []);

  const handleCancelDeleteTag = useCallback(() => {
    setIsModalDeleteTagVisible(false);
  }, []);

  const handleDeleteTagOnClick = useCallback(() => {
    setIsModalDeleteTagVisible(false);
    deleteTag(tag?.id);
  }, [deleteTag, tag?.id]);

  const handleCancelEditTag = useCallback(() => {
    setTagName(tag.name);
    setAllTitleWords(Array.isArray(rule["allTitleKeyWords"]) ? rule["allTitleKeyWords"].join(",") : "");
    setAnyTitleWords(Array.isArray(rule["anyTitleKeyWords"]) ? rule["anyTitleKeyWords"].join(",") : "");
    setNoTitleWords(Array.isArray(rule["noTitleKeyWords"]) ? rule["noTitleKeyWords"].join(",") : "");
    setAllURLWords(Array.isArray(rule["allURLKeyWords"]) ? rule["allURLKeyWords"].join(",") : "");
    setAnyURLWords(Array.isArray(rule["anyURLKeyWords"]) ? rule["anyURLKeyWords"].join(",") : "");
    setNoURLWords(Array.isArray(rule["noURLKeyWords"]) ? rule["noURLKeyWords"].join(",") : "");
    setPagePath(rule["urlStructure"]);
    setIsModalEditTagVisible(false);
  }, [tag.name, rule]);

  function splitAndPreserveQuotes(input) {
    let parts = [];
    let currentPart = "";
    let quoted = false;

    for (let i = 0; i < input.length; i++) {
      const char = input[i];
      const nextChar = input[i + 1];

      if (char === "\"") {
        quoted = !quoted;
        if (!quoted && nextChar && /\w/.test(nextChar)) {
          parts.push(currentPart + char + nextChar);
          currentPart = "";
          i++;
        } else {
          currentPart += char;
        }
      } else if ((char === "," || char === " ") && !quoted) {
        parts.push(currentPart.trim());
        currentPart = "";
        if (nextChar === " ") {
          i++;
        }
      } else {
        currentPart += char;
      }
    }

    parts.push(currentPart.trim());

    return parts.filter(Boolean);
  }

  const handleEditTagOnClick = useCallback(() => {
    setIsModalEditTagVisible(false);
    const tagData = {
      tagId: tag?.id,
      newTagName: tagName,
      newAllTitleKeyWords: splitAndPreserveQuotes(allTitleWords),
      newAnyTitleKeyWords: splitAndPreserveQuotes(anyTitleWords),
      newNoTitleKeyWords: splitAndPreserveQuotes(noTitleWords),
      newAllURLKeyWords: splitAndPreserveQuotes(allURLWords),
      newAnyURLKeyWords: splitAndPreserveQuotes(anyURLWords),
      newNoURLKeyWords: splitAndPreserveQuotes(noURLWords),
    };

    if (pagePath && pagePath.length > 0) {
      tagData.newURLStructure = pagePath;
    }

    updateTag(tagData);
  }, [updateTag, tag?.id, tagName, allTitleWords, anyTitleWords, noTitleWords, allURLWords, anyURLWords, noURLWords, pagePath]);

  const handleOnChange = useCallback((e, setState) => {
    setState(e.target.value);
  }, []);

  const popoverContent = useMemo(() => {
    return (
      <>
        <span
          style={{
            fontFamily: "Inter, sans-serif",
            color: "red",
            cursor: "pointer",
          }}
          onClick={showModalDeleteTag}
        >
          Delete Tag
        </span>
        <Modal
          title={
            <h2 className="title is-size-4 has-text-weight-normal">
              Are you sure you want to delete?
            </h2>
          }
          open={isModalDeleteTagVisible}
          onOk={handleDeleteTagOnClick}
          okText="Yes"
          onCancel={handleCancelDeleteTag}
          cancelText="No, don't delete"
          closable={false}
          width={500}
          className="delete-tag-modal"
          centered
        >
          <span style={{ color: "red", fontSize: "16px" }}>
            Your webpages may now be unassigned if you delete the tag
          </span>
        </Modal>
      </>
    );
  }, [
    handleCancelDeleteTag,
    handleDeleteTagOnClick,
    isModalDeleteTagVisible,
    showModalDeleteTag,
  ]);

  const popoverTitle = useMemo(() => {
    return (
      <>
        <span
          style={{ fontFamily: "Inter, sans-serif", cursor: "pointer" }}
          onClick={showModalEditTag}
        >
          Edit Tag
        </span>
        <Modal
          title={
            <h2 className="title is-size-4 has-text-weight-normal">
              Edit a Vertical Tag
            </h2>
          }
          open={isModalEditTagVisible}
          onOk={handleEditTagOnClick}
          okText="Save"
          onCancel={handleCancelEditTag}
          closable={false}
          width={500}
          className="edit-tag-modal"
          centered
        >
          <label className="label">Tag name</label>
          <div className="control">
            <Input
              className="input mb-3"
              type="text"
              value={tagName}
              onChange={(e) => handleOnChange(e, setTagName)}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <span style={{ color: "#093159", fontSize: 18, fontWeight: "bold", paddingTop: "5px" }}>
             Use keywords in the page title
            </span>
          </div>
          <div className="form-group">
            <label className="label" style={{ paddingRight: "30px" }}>All of these words</label>
            <div className="control">
              <Input
                className="input"
                type="text"
                value={allTitleWords}
                onChange={(e) => handleOnChange(e, setAllTitleWords)}
                placeholder="separated by commas"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="label" style={{ paddingRight: "20px" }}>Any of these words</label>
            <div className="control">
              <Input
                className="input"
                type="text"
                value={anyTitleWords}
                onChange={(e) => handleOnChange(e, setAnyTitleWords)}
                placeholder="separated by commas"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="label">None of these words</label>
            <div className="control">
              <Input
                className="input"
                type="text"
                value={noTitleWords}
                onChange={(e) => handleOnChange(e, setNoTitleWords)}
                placeholder="separated by commas"
              />
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            <span style={{ color: "#093159", fontSize: 18, fontWeight: "bold", paddingTop: "5px" }}>
              Use keywords in the page URL
            </span>
          </div>
          <div className="form-group">
            <label className="label" style={{ paddingRight: "30px" }}>All of these words</label>
            <div className="control">
              <Input
                className="input"
                type="text"
                value={allURLWords}
                onChange={(e) => handleOnChange(e, setAllURLWords)}
                placeholder="separated by commas"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="label" style={{ paddingRight: "20px" }}>Any of these words</label>
            <div className="control">
              <Input
                className="input"
                type="text"
                value={anyURLWords}
                onChange={(e) => handleOnChange(e, setAnyURLWords)}
                placeholder="separated by commas"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="label">None of these words</label>
            <div className="control">
              <Input
                className="input"
                type="text"
                value={noURLWords}
                onChange={(e) => handleOnChange(e, setNoURLWords)}
                placeholder="separated by commas"
              />
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            <span style={{ color: "#093159", fontSize: 18, fontWeight: "bold", paddingTop: "5px" }}>
              Set by URL Structure
            </span>
          </div>
          <div className="form-group">
            <label className="label">Page Path</label>
            <div className="control">
              <Input
                className="input"
                type="text"
                value={pagePath}
                onChange={(e) => handleOnChange(e, setPagePath)}
                placeholder="/"
              />
            </div>
          </div>
        </Modal>
      </>
    );
  }, [
    handleCancelEditTag,
    handleEditTagOnClick,
    handleOnChange,
    isModalEditTagVisible,
    showModalEditTag,
    tagName,
    allTitleWords,
    anyTitleWords,
    noTitleWords,
    allURLWords,
    anyURLWords,
    noURLWords,
    pagePath,
  ]);

  return (
    <>
      <Popover
        content={popoverContent}
        trigger="click"
        title={popoverTitle}
        placement="bottomRight"
        overlayClassName="tag-card-extra-popover"
        open={isPopoverVisible}
        onOpenChange={handleOnPopoverVisibleChange}
      >
        <i className="fa fa-2x fa-ellipsis-v"></i>
      </Popover>
    </>
  );
};

export default TagCardExtra;
