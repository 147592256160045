import React from "react";
import { Col, Row } from "antd";
import { OWNED, COMPETITOR_COLOR, OWNED_COLOR } from "../../Constants";

export default function CustomLegendContent({ data }) {
  return (
    <>
      <Row align="top" wrap={true} justify="center">
        {data.map((text, index) => (
          <React.Fragment key={index}>
            <Col xs={12} md={12} lg={4}>
              <Row align="middle">
                <svg
                  className="recharts-surface"
                  width="14"
                  height="14"
                  viewBox="0 0 32 32"
                  version="1.1"
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: "4px",
                    marginTop: "0px",
                  }}
                >
                  <path
                    fill={text === OWNED ? OWNED_COLOR : COMPETITOR_COLOR}
                    cx="16"
                    cy="16"
                    type="square"
                    className="recharts-symbols"
                    transform="translate(16, 16)"
                    d="M-16,-16h32v32h-32Z"
                  ></path>
                </svg>
                <span>{text}</span>
              </Row>
            </Col>
          </React.Fragment>
        ))}
      </Row>
    </>
  );
}
