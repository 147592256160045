import React, { useState } from "react";
import WebsiteDeleteModal from "../WebsiteDeleteModal";

const WebsitePopoverContent = ({
  handlePopoverVisible,
  websiteId,
  ecosystemId,
}) => {
  const [isVisible, setIsVisible] = useState("");

  const handleOnClick = () => {
    handlePopoverVisible(false);
    setIsVisible(true);
  };

  return (
    <>
      <span style={{ color: "red" }} onClick={handleOnClick}>
        Delete website
      </span>
      <WebsiteDeleteModal
        setIsVisible={setIsVisible}
        isVisible={isVisible}
        websiteId={websiteId}
        ecosystemId={ecosystemId}
      />
    </>
  );
};

export default WebsitePopoverContent;
