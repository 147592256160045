import { Col, Row, Space } from "antd";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { VISIBLE_COLUMN_TITLE } from "../../../../constants/TableColumnConstant";
import { useGetEcosystemIdByUuidQuery } from "../../../../utils/Queries/EcosystemQueries";
import { useGetAllStatusCode } from "../../../../utils/Queries/WebpageQueries";
import AssignVertical from "../../../AssignVertical";
import FontSizeChange from "../../../DataTableFilter/FontSizeChange";
import MultipleChoiceFilter from "../../../DataTableFilter/MultipleChoiceFilter";
import TextFilter from "../../../DataTableFilter/TextFilter";
import VisibleColumnFilter from "../../../DataTableFilter/VisibleColumnFilter";
import "./TableHeader.scss";
import AdvancedSearchFilter from "../../../DataTableFilter/AdvancedSearchFilter";

const TableHeader = ({
                       selectedWebsitePageIds,
                       setSelectedWebsitePageIds,
                       tags,
                       ecosystemId: ecosystemUuid,
                       filters,
                       setFilters,
                       visibleColumns,
                       setVisibleColumns,
                       fontSize,
                       setFontSize,
                     }) => {
  const datePicker = useSelector((state) => state.websiteTracking.datePicker);
  const websiteFilter = useSelector(
    (state) => state.websiteTracking.websiteFilter,
  );
  const tagFilter = useSelector((state) => state.websiteTracking.tagFilter);

  const { data: ecosystemId } = useGetEcosystemIdByUuidQuery(ecosystemUuid);

  const { data: allStatusCode, isLoading } = useGetAllStatusCode(
    ecosystemId,
    {
      dateFrom: datePicker,
      website: websiteFilter,
      tag: tagFilter,
    },
    filters,
  );

  const visibleColumnTitle = useMemo(() => {
    return VISIBLE_COLUMN_TITLE;
  }, []);

  return (
    <Row className="website-table-header" justify="space-between">
      <Col span={24} sm={20} md={16}>
        <Space
          size="small"
          align="center"
          wrap
          style={{ marginBottom: "10px" }}
        >
          <TextFilter
            title="Title"
            setFilters={setFilters}
            filters={filters}
            customKeys={{
              is: "is",
              contains: "contains all of these words",
              isAnyOf: "contains any of these words",
            }}
          />
          <TextFilter
            title="URL"
            setFilters={setFilters}
            filters={filters}
            customKeys={{
              is: "is",
              contains: "contains all of these words",
              isAnyOf: "contains any of these words",
              like: "page path",
            }}
          />

          <MultipleChoiceFilter
            isLoading={isLoading}
            title="Status code"
            setFilters={setFilters}
            filters={filters}
            choices={allStatusCode}
          />
          <VisibleColumnFilter
            title="Fields"
            setVisibleColumns={setVisibleColumns}
            visibleColumns={visibleColumns}
            visibleColumnTitle={visibleColumnTitle}
          />
          <FontSizeChange setFontSize={setFontSize} fontSize={fontSize} />
          <AdvancedSearchFilter setFilters={setFilters} filters={filters} />
        </Space>
      </Col>
      <Col span={12} sm={6} xl={3} className="assign-vertical-btn-col">
        <AssignVertical
          selectedWebsitePageIds={selectedWebsitePageIds}
          setSelectedWebsitePageIds={setSelectedWebsitePageIds}
          tags={tags}
          ecosystemId={ecosystemId}
          invalidatedQueryKeys={["websiteTracking", "webpages-data-table"]}
        />
      </Col>
    </Row>
  );
};

export default TableHeader;
