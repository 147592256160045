import { Modal } from "antd";
import React from "react";
import { useDeleteWebsite } from "../../../utils/Mutations/WebsiteMutation";

const WebsiteDeleteModal = ({
  isVisible,
  setIsVisible,
  websiteId,
  ecosystemId,
}) => {
  const mutation = useDeleteWebsite(ecosystemId);

  const handleOnOk = () => {
    mutation.mutate({
      websiteId: websiteId,
      ecosystemId: ecosystemId,
    });
    setIsVisible(false);
  };

  const handleOnCancel = () => {
    setIsVisible(false);
  };

  return (
    <Modal
      title={<p>Delete Website</p>}
      open={isVisible}
      onOk={handleOnOk}
      onCancel={handleOnCancel}
      wrapClassName="website-delete-modal"
      centered
    >
      <p className="warning-message">
        If deleted all of your website data will be lost
      </p>
    </Modal>
  );
};

export default WebsiteDeleteModal;
