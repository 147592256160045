import React from 'react';

export default function MessageModal(props) {

    function closeModal() {
        props.modalState(false);
    }

    return (
        <div className={props.modalState !== "" ? "modal is-active" : "modal"}>
            <div className="modal-background" onClick={closeModal}></div>
            <div className="modal-content small-modal modal-padding has-background-white">
                <div className="modal-heading">
                    {props.message}
                </div>
                <button className="button is-success is-pulled-right" onClick={closeModal}>OK</button>
            </div>
            <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
        </div>
    );
}