import { Table } from "antd";
import "./TrackingTable.scss";
import { useCallback } from "react";

export default function TrackingTable({
  columns,
  dataSource,
  isLoading,
  paginationConfig,
  setPage,
}) {
  const handleOnChange = useCallback(
    (pagination) => {
      setPage(pagination.current);
    },
    [setPage]
  );

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      className="tracking-table"
      pagination={{
        pageSize: 10,
        position: ["bottomCenter"],
        total: paginationConfig?.total,
        showSizeChanger: false,
      }}
      loading={isLoading}
      onChange={handleOnChange}
      scroll={{ x: 'max-content' }}
    />
  );
}
