import { configureStore } from "@reduxjs/toolkit";
import ProductTrackingReducer from "./ProductTracking";
import WebsiteTrackingReducer from "./WebsiteTracking/WebsiteTracking";
import currencyReducer from "./Currencies";

export default configureStore({
  reducer: {
    websiteTracking: WebsiteTrackingReducer,
    productTracking: ProductTrackingReducer,
    currencies: currencyReducer,

  },
});
