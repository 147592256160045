import React, { useCallback, useMemo } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Legend } from "recharts/lib";
import CustomLegendContent from "../CustomChartComponents/CustomLegendContent";
import "./LineChartContainer.scss";

const LineChartContainer = ({
  chartData,
  chartTotal,
  colorPallette,
  chartType,
  hideLegends,
  setHideLegends,
}) => {
  const renderYTick = useCallback((value, index) => {
    return index % 2 !== 1 ? value : "";
  }, []);

  const tickConfig = useMemo(() => {
    return {
      fontFamily: "'Inter', sans-serif",
      fontSize: "12px",
      color: "#7e8e9f",
    };
  }, []);

  return (
    <ResponsiveContainer width="100%" height="90%">
      <LineChart
        width={730}
        height={250}
        data={chartData?.chartData}
        margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="0" vertical={false} stroke="#e6e6e6" />
        <XAxis
          dataKey="date"
          padding={{ left: 30, right: 30 }}
          axisLine={false}
          tickLine={false}
          tickMargin={20}
          tick={tickConfig}
        />
        <YAxis
          width={100}
          axisLine={false}
          tickMargin={20}
          tickLine={false}
          tickCount={5}
          tickFormatter={renderYTick}
          tick={tickConfig}
        />
        <Tooltip />
        <Legend
          iconType="circle"
          iconColor="#2e4cbd"
          align="left"
          content={
            <CustomLegendContent
              colorPallette={colorPallette}
              chartTotal={chartTotal}
              hideLegends={hideLegends}
              setHideLegends={setHideLegends}
            />
          }
        />

        {chartType === "Changes" && hideLegends.length === 0 && (
          <Line
            dataKey="changes"
            stroke={colorPallette[0]}
            dot={false}
            strokeWidth={3}
            name="Changes"
          />
        )}

        {chartData &&
          chartData?.total?.map((i, index) => {
            return (
              !hideLegends?.includes(index) && (
                <Line
                  key={`${i?.label.split(" ")[0]}.changes`}
                  dataKey={`${i?.label.split(" ")[0]}.changes`}
                  stroke={colorPallette[index]}
                  dot={false}
                  strokeWidth={3}
                  name={i?.label}
                />
              )
            );
          })}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartContainer;
