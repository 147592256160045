import "./TrackingBarChartContainer.scss";

export default function BarChartContainer({ children, title, description }) {
  return (
    <div className="tracking-bar-chart-container">
      <div className="text">
        <p className="title">{title}</p>
        <p className="description">{description}</p>
      </div>
      <div className="chart">{children}</div>
    </div>
  );
}
