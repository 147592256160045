import React, { useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import CustomLegendContent from "../CustomLegendContent";
import "./TrackingBarChart.scss";
import { Spin } from "antd";
import { COMPETITOR, OWNED } from "../../Constants";

export default function TrackingBarChart({ data, isLoading, customTooltip, customTick }) {
  const legends = useMemo(() => {
    const result = [];
    data?.forEach((item) => {
      if (item.owner === true && !result.includes("Owned")) {
        result.push(OWNED);
      } else if (item.owner === false && !result.includes("Competitor")) {
        result.push(COMPETITOR);
      }
    });
    return result;
  }, [data]);

  return isLoading ? (
    <Spin />
  ) : (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data} margin={{ bottom: 20 }}>
        <CartesianGrid strokeDasharray="0" vertical={false} stroke="#e6e6e6" />
        <XAxis dataKey="website_name" axisLine={false} tickLine={false} tick={customTick} />
        <YAxis axisLine={false} tickLine={false} />
        <Tooltip content={customTooltip} />
        <Legend
          iconType="square"
          iconColor="#2e4cbd"
          align="left"
          content={<CustomLegendContent data={legends} />}
          wrapperStyle={{ position: "relative" }}
        />
        <Bar dataKey="quantity">
          {data?.map((entry, index) => {
            return (
              <Cell
                cursor="pointer"
                fill={entry.owner ? "#00f9b2" : "#f90047"}
                key={`cell-${index}`}
              />
            );
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
