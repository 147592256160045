import { Space, Radio } from "antd";
import { useCallback } from "react";
import "./PopoverContent.scss";

const PopoverContent = ({ setFontSize, fontSize }) => {
  const handleOnChange = useCallback(
    (e) => {
      setFontSize(e.target.value);
    },
    [setFontSize]
  );

  return (
    <Radio.Group
      onChange={handleOnChange}
      value={fontSize}
      className="font-size-change-popover-content"
    >
      <Space direction="vertical">
        <Radio value="font-size-10">10px</Radio>
        <Radio value="font-size-12">12px</Radio>
        <Radio value="font-size-14">14px</Radio>
        <Radio value="font-size-16">16px</Radio>
        <Radio value="font-size-18">18px</Radio>
      </Space>
    </Radio.Group>
  );
};

export default PopoverContent;
