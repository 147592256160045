import { Checkbox, Space, Spin } from "antd";
import React, { useEffect, useCallback } from "react";
import "./PopoverContent.scss";

const PopoverContent = ({
  setIsSelect,
  setFilters,
  filters,
  type,
  choices,
  isLoading,
  filterBtn,
}) => {

  useEffect(() => {
    if (choices.length > 0) {
      setIsSelect(true);
    }
  }, [choices, setIsSelect]);

  const handleOnChange = useCallback(
    (values) => {
      const unchecked = choices.filter(choice => !values.includes(choice.id)).map(choice => choice.id);
      setIsSelect(unchecked.length !== choices.length);

      if (unchecked.length !== 0) {
        filterBtn.current.style.border = "2px solid #00fcb4"; // Set border when any item is unchecked
      } else {
        filterBtn.current.style.border = ""; // Reset border when all items are checked
      }

      const changedFilters = { ...filters };
      changedFilters[type] = {
        value: `[${unchecked}]`,
        operator: "notIn",
      };

      if (unchecked.length === 0) {
        delete changedFilters[type];
      }

      setFilters(changedFilters);
    },
    [choices, filters, filterBtn, setFilters, setIsSelect, type]
  );

  return (
    <Checkbox.Group
      defaultValue={choices.map(choice => choice.id)}
      onChange={handleOnChange}
    >
      <Space direction="vertical">
        {isLoading ? (
          <Spin />
        ) : (
          <>
            {choices.length === 0 ? (
              <span className="multiple-filter-default-message">
                Has no filter yet
              </span>
            ) : (
              choices.map((choice) => (
                <Checkbox
                  key={choice.id}
                  value={choice.id}
                  className="multiple-filter-checkbox"
                >
                  <span className="text-filter-title">
                    {choice.name}
                  </span>
                </Checkbox>
              ))
            )}
          </>
        )}
      </Space>
    </Checkbox.Group>
  );
};

export default PopoverContent;
