import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LoggedInStatus } from "../../services/LoggedInStatus";
import { UserLogin } from "../Other/UserLogin";
import { UserProfileModal } from "../Other/UserProfileModal";
import "./Header.scss";
import { Link } from "react-router-dom";

export default function Header(props) {
  const [loggedInStatus, setLoggedInStatus] = useState(false);
  const [menuIsActive, setMenuIsActive] = useState(false);
  const [loginMenuIsActive, setLoginMenuIsActive] = useState(false);

  let location = useLocation();

  useEffect(() => {
    // Using location.pathname in LoggedInStatus stops infinite redirects
    let page = location.pathname === "/" ? "home" : "";
    let lis = LoggedInStatus(page);
    setLoggedInStatus(lis);
  }, [loggedInStatus, location]);

  function menuActive() {
    setMenuIsActive(!menuIsActive);
  }

  function loginMenuActive() {
    setLoginMenuIsActive(!loginMenuIsActive);
  }

  function updateLogin() {
    props.updateLogin();
  }

  function loginError(err) {
    props.loginError(err);
  }

  return (
    <header>
      <nav className="navbar is-link">
        <div className="container">
          <div className="navbar-brand">
            <div className="navbar-item">
              <Link to="/welcome">
                <img
                  src="/images/Logo-slight.png"
                  width="100%"
                  alt="DataNoodle"
                />
              </Link>
            </div>
            <span
              role="button"
              className={
                menuIsActive ? "navbar-burger is-active" : "navbar-burger"
              }
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
              onClick={menuActive}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </span>
          </div>
          <div
            className={menuIsActive ? "navbar-menu is-active" : "navbar-menu"}
          >
            <div className="navbar-start">
              <div className="navbar-item">
                <a
                  href="https://datanoodle.com/help.html"
                  target="_blank"
                  rel="noreferrer"
                  className="has-text-white"
                >
                  Help
                </a>
              </div>
              <div className="navbar-item">
                <a
                  href="https://datanoodle.com/help/faq.html "
                  target="_blank"
                  rel="noreferrer"
                  className="has-text-white"
                >
                  FAQ
                </a>
              </div>
              {props.loggedIn === true && (
                <div className="navbar-item has-dropdown is-hoverable">
                  <div className="navbar-link">
                    <Link to={"/welcome"} className="all-ecosystems">
                      All Ecosystems
                    </Link>
                  </div>
                  <div className="navbar-dropdown">
                    {props.ecosystems &&
                      props.ecosystems.data.map((eco) => (
                        <Link
                          to={"/ecosystem/" + eco.id.toString() + "/webpages"}
                          key={eco.id.toString()}
                          className="navbar-item"
                        >
                          {props.ecosystemId !== null &&
                            props.ecosystemId === eco.id && (
                              <span className="icon has-text-success pr-2">
                                <i className="fa fa-check"></i>
                              </span>
                            )}
                          {eco.name}
                        </Link>
                      ))}
                  </div>
                </div>
              )}
            </div>
            <div className="navbar-end">
              {!loggedInStatus || props.pageName === "Home" ? (
                <UserLogin
                  updateLogin={updateLogin}
                  loginError={loginError}
                  loginMenuIsActive={loginMenuIsActive}
                  loginMenuActive={loginMenuActive}
                />
              ) : (
                props.pageName !== "Home" && <UserProfileModal />
              )}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
