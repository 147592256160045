import React, { Component, Fragment } from "react";
import { Navigate, Link } from "react-router-dom";
import { LoggedInStatus } from "../services/LoggedInStatus";
import Header from "./Layout/Header";
import SubHeader from "./Layout/SubHeader";
import Footer from "./Layout/Footer";
import Loading from "./Animations/Loading";
import { PostData } from "../services/PostData";
import { WebsiteBox } from "./Other/WebsiteBox";
import { TagBox } from "./Other/TagBox";
import { GetTeams } from "../services/GetTeams";
import { GetEcosystems } from "../services/GetEcosystems";
import FormModal from "./Modals/FormModal";

class Ecosystem extends Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    sessionStorage.setItem("ecosystemId", params.ecosystemId);
    this.state = {
      loggedIn: null,
      ecosystems: null,
      ecosystemId: parseInt(params.ecosystemId),
      websiteId: null,
      currentEcosystem: null,
      tags: null,
      addEcosystemModalState: false,
      editEcosystemModalState: false,
      addTagModalState: false,
      tab: 1,
      teams: null,
      loading: true,
    };
  }

  componentDidMount() {
    let li = LoggedInStatus();
    this.setState(
      {
        loggedIn: li,
      },
      function () {
        if (li !== false && li !== "exit") {
          this.getAllEcosystems();
        }
      }
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.currentEcosystem !== null &&
      nextState.tags !== null &&
      nextState.ecosystems !== null
    ) {
      return true;
    }
    if (nextState.loggedIn === false || nextState.loggedIn === "exit") {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.ecosystemId !== this.props.match.params.ecosystemId
    ) {
      const {
        match: { params },
      } = this.props;
      let eid = parseInt(params.ecosystemId);
      sessionStorage.setItem("ecosystemId", eid);
      this.setState(
        {
          ecosystemId: eid,
        },
        function () {
          this.getThisEcosystem();
        }
      );
    }
  }

  checkLoading() {
    if (
      Object.keys(this.state.currentEcosystem).length !== 0 &&
      this.state.tags !== null
    ) {
      this.setState({
        loading: false,
      });
    }
  }

  async getAllEcosystems(mustUpdate = false) {
    // Get all ecosystems
    const allEcosystems = await GetEcosystems(mustUpdate);
    this.setState(
      {
        ecosystems: allEcosystems,
      },
      function () {
        this.getThisEcosystem();
      }
    );
  }

  getThisEcosystem() {
    const postOptions = {
      postRoute: "/ecosystem/" + this.state.ecosystemId + "/settings",
      method: "GET",
      token: true,
    };
    PostData(postOptions).then((result) => {
      this.setState(
        {
          currentEcosystem: result,
        },
        function () {
          this.getTagsForEcosystem();
        }
      );
    });
  }

  async getTagsForEcosystem() {
    let teams = await GetTeams();
    const postOptions = {
      postRoute: "/ecosystems/" + this.state.ecosystemId + "/tags",
      method: "GET",
      token: true,
    };
    PostData(postOptions).then((result) => {
      this.setState(
        {
          tags: result,
          teams: teams,
        },
        function () {
          this.checkLoading();
        }
      );
    });
  }

  updateEcosystem = (mustUpdate = false) => {
    this.setState(
      {
        loading: true,
      },
      function () {
        this.getAllEcosystems(mustUpdate);
      }
    );
  };

  editEcosystem = (fields) => {
    let ecosystemName = fields[0];
    if (ecosystemName !== null && ecosystemName.length > 0) {
      const postOptions = {
        postRoute: "/ecosystem/" + this.state.ecosystemId,
        method: "PUT",
        type: "x-www-form-urlencoded",
        token: true,
        formData: {
          name: ecosystemName,
        },
      };
      PostData(postOptions).then((result) => {
        if (result.success) {
          this.updateEcosystem();
          this.setState({
            editEcosystemModalState: false,
          });
        }
      });
    } else {
      // TODO: Handle for validation...
    }
  };

  addWebsite = (fields) => {
    let websiteName = fields[0];
    let websiteUrl = fields[1];
    if (
      websiteName !== null &&
      websiteName.length > 0 &&
      websiteUrl !== null &&
      websiteUrl.length > 0
    ) {
      const postOptions = {
        postRoute: "/ecosystem/" + this.state.ecosystemId + "/module",
        method: "POST",
        token: true,
        formData: {
          module_id: 1,
          website: websiteUrl,
          name: websiteName,
        },
      };
      PostData(postOptions).then((result) => {
        if (result.success) {
          this.setState(
            {
              addWebsiteModalState: false,
            },
            function () {
              this.updateEcosystem(true);
            }
          );
        }
      });
    } else {
      // TODO: Handle for validation...
    }
  };

  addVertical = (fields) => {
    let tagName = fields[0];
    let tagDescription = fields[1];
    if (tagName !== "") {
      const postOptions = {
        postRoute: "/ecosystems/" + this.state.ecosystemId + "/tags",
        method: "POST",
        type: "formData",
        token: true,
        formData: {
          name: tagName,
          description: tagDescription,
        },
      };
      PostData(postOptions).then((result) => {
        if (result.success) {
          this.setState(
            {
              addTagModalState: false,
            },
            function () {
              this.updateEcosystem(true);
            }
          );
        }
      });
    } else {
      // TODO: Handle for validation...
    }
  };

  changeEcosystemModalState = () => {
    this.setState({
      addEcosystemModalState: !this.state.addEcosystemModalState,
    });
  };

  changeEditEcosystemModalState = () => {
    this.setState({
      editEcosystemModalState: !this.state.editEcosystemModalState,
    });
  };

  changeWebsiteModalState = () => {
    this.setState({
      addWebsiteModalState: !this.state.addWebsiteModalState,
    });
  };

  changeAddTagModalState = () => {
    this.setState({
      addTagModalState: !this.state.addTagModalState,
    });
  };

  updateLogin = (val) => {
    this.setState({ loggedIn: val });
  };

  logout = () => {
    this.setState({ loggedIn: false });
  };

  render() {
    if (
      this.state.loggedIn === false ||
      this.state.loggedIn === "exit" ||
      this.state.loggedIn === undefined
    ) {
      return <Navigate to={"/"} />;
    } else if (this.state.loading || this.state.loggedIn === null) {
      return <Loading pad={200} />;
    } else {
      return (
        <Fragment>
          <FormModal
            title="Add a website"
            modalState={this.state.addWebsiteModalState}
            changeModalState={this.changeWebsiteModalState}
            fields={[
              {
                type: "text",
                label: "Website name",
                placeholder: "My Website",
              },
              {
                type: "text",
                label: "Website homepage",
                placeholder: "https://example.com",
              },
            ]}
            buttonTextCancel="Cancel"
            buttonText="Save"
            response={this.addWebsite}
          />
          <FormModal
            title="Edit ecosystem"
            modalState={this.state.editEcosystemModalState}
            changeModalState={this.changeEditEcosystemModalState}
            fields={[
              {
                type: "text",
                label: "Ecosystem name",
                defaultValue: this.state.currentEcosystem.data[0].name,
              },
            ]}
            buttonTextCancel="Cancel"
            buttonText="Save"
            response={this.editEcosystem}
          />
          <FormModal
            title="Add a vertical"
            modalState={this.state.addTagModalState}
            changeModalState={this.changeAddTagModalState}
            fields={[
              {
                type: "text",
                label: "Name",
                placeholder: "My new vertical tag",
              },
              {
                type: "text",
                label: "Description",
                placeholder: "Short description of my new vertical",
              },
            ]}
            buttonTextCancel="Cancel"
            buttonText="Save"
            response={this.addVertical}
          />
          <Header
            updateLogin={this.updateLogin}
            logout={this.logout}
            loggedIn={this.state.loggedIn}
            ecosystems={this.state.ecosystems}
            ecosystemId={this.state.ecosystemId}
            pageName={"Ecosystem"}
          />
          <SubHeader
            ecosystemId={this.state.ecosystemId}
            ecosystemName={this.state.currentEcosystem.data[0].name}
          />
          <section className="section has-background-light-grey">
            <div className="container">
              <div className="page-heading is-clearfix">
                <h1 className="title is-size-3-desktop is-size-3-tablet is-size-4-mobile is-pulled-left">
                  Ecosystem Settings
                </h1>
                <Link to={"/welcome"} className="is-pulled-right">
                  <span className="icon is-large">
                    <i className="fa fa-info-circle fa-2x"></i>
                  </span>
                </Link>
              </div>
              <div className="py-5">
                <span className="title is-size-5">
                  {this.state.currentEcosystem.data[0].name}
                </span>
                <span
                  className="icon has-text-link is-clickable ml-3"
                  onClick={this.changeEditEcosystemModalState}
                >
                  <i className="fa fa-edit"></i>
                </span>
              </div>
              <div className="tabs is-normal toggle toggle-rounded">
                <ul>
                  <li className={this.state.tab === 1 ? "is-active" : ""}>
                    <a
                      href="nowhere"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ tab: 1 });
                      }}
                    >
                      Website
                    </a>
                  </li>
                  <li className={this.state.tab === 2 ? "is-active" : ""}>
                    <a
                      href="nowhere"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ tab: 2 });
                      }}
                    >
                      Vertical tags
                    </a>
                  </li>
                </ul>
              </div>
              {this.state.tab === 1 && (
                <div className="columns is-multiline">
                  {this.state.currentEcosystem.data[1].map((ws, i) => (
                    <WebsiteBox
                      key={i}
                      website={ws}
                      updateEcosystem={this.updateEcosystem}
                      ecosystemId={this.state.ecosystemId}
                    />
                  ))}
                  <div className="column is-3-desktop is-4-tablet">
                    <div className="card">
                      <div className="card-content">
                        <div className="columns is-mobile">
                          <div className="column is-9 mt-1">
                            <p className="is-size-5">Add website</p>
                          </div>
                          <div className="column has-text-right">
                            <span
                              className="icon is-large has-text-primary is-clickable"
                              onClick={this.changeWebsiteModalState}
                            >
                              <i className="fa fa-plus-circle fa-2x"></i>
                            </span>
                          </div>
                        </div>
                        <div className="has-text-centered">
                          <p>
                            You can add two more websites with your current
                            plan.
                          </p>
                          <p>&nbsp;</p>
                          <p>
                            If you need more you can{" "}
                            <Link to="/plans">upgrade your plan here</Link>.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.tab === 2 && (
                <div className="columns is-multiline">
                  {this.state.tags.data.tags.map((tag, i) => (
                    <TagBox
                      key={i}
                      tag={tag}
                      updateEcosystem={this.updateEcosystem}
                    />
                  ))}
                  <div className="column is-3-desktop is-4-tablet">
                    <div className="card">
                      <div className="card-content">
                        <div className="columns is-mobile">
                          <div className="column is-9 mt-1">
                            <p className="is-size-5">Add a vertical</p>
                          </div>
                          <div className="column has-text-right">
                            <span
                              className="icon is-large has-text-primary is-clickable"
                              onClick={this.changeAddTagModalState}
                            >
                              <i className="fa fa-plus-circle fa-2x"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
          <Footer />
        </Fragment>
      );
    }
  }
}

export default Ecosystem;
