import React, { useEffect, useState, Fragment } from "react";
import { useParams, Navigate } from "react-router-dom";
import { LoggedInStatus } from "../services/LoggedInStatus";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import Loading from "../components/Animations/Loading";
import { PostData } from "../services/PostData";
import { GetEcosystems } from "../services/GetEcosystems";
import SubHeaderUser from "../components/Layout/SubHeaderUser";
import MessageModal from "../components/Modals/MessageModal";
import UpdateProfileForm from "../components/UpdateProfileForm";

const UserProfilePage = () => {
  const { userId } = useParams();
  const [loggedIn, setLoggedIn] = useState(null);
  const [ecosystems, setEcosystems] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getAllEcosystems = async () => {
      try {
        const allEcosystems = await GetEcosystems();
        setEcosystems(allEcosystems.data);
        getThisUser(userId);
      } catch (error) {
        setError(true);
      }
    };

    sessionStorage.setItem("userId", userId);
    const li = LoggedInStatus();
    setLoggedIn(li);
    if (li !== false && li !== "exit") {
      getAllEcosystems();
    }
  }, [userId]);

  const getThisUser = (userId) => {
    const postOptions = {
      postRoute: `/user/${userId}/profile`,
      method: "GET",
      token: true,
    };
    PostData(postOptions).then((result) => {
      setCurrentUser(result);
      setLoading(false);
    });
  };

  const updateLogin = (val) => {
    setLoggedIn(val);
  };

  const logout = () => {
    setLoggedIn(false);
  };

  const errorModalState = (ms = false) => {
    setError(ms);
  };

  if (loggedIn === false || loggedIn === "exit" || loggedIn === undefined) {
    return <Navigate to={"/"} />;
  } else if (loading || loggedIn === null) {
    return <Loading pad={200} />;
  } else if (error !== false) {
    return (
      <MessageModal modalState={errorModalState} message={error} />
    );
  } else {
    return (
      <Fragment>
        <Header
          updateLogin={updateLogin}
          logout={logout}
          loggedIn={loggedIn}
          ecosystems={ecosystems}
          ecosystemId={ecosystems?.ecosystemId}
        />
        <SubHeaderUser
          firstName={currentUser.data.first_name}
          userId={userId}
        />
        <UpdateProfileForm
          firstName={currentUser.data.first_name}
          lastName={currentUser.data.last_name}
          userName={currentUser.data.username}
          email={currentUser.data.email}
          userId={userId}
          getThisUser={() => getThisUser(userId)}
        />
        <Footer />
      </Fragment>
    );
  }
};

export default UserProfilePage;
