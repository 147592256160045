import React from 'react';
import './Loading.scss';

export default function Loading({pad = 0}) {

    let padding = pad > 0 ? pad.toString()+"px" : "0";

    const styles = {
        paddingTop: padding,
        paddingBottom: padding
    }

    return (
        <div style={styles}>
            <div className="spinner">
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div>
            </div>
        </div>
    );
}