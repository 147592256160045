import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Tooltip, Select } from "antd";
import { intervalToDuration, formatDuration, format } from "date-fns";
const { Option } = Select;

export const renderTags = (obj) => {
  return obj?.web_page_tags?.split(",").map((tagName) => <p>{tagName}</p>);
};

export const renderProductChange = (productLastAvailabilityAt) => {
  if (productLastAvailabilityAt === "0000-00-00 00:00:00") {
    return "0 day";
  }

  let durationText = "";
  if (productLastAvailabilityAt !== null) {
    const duration = intervalToDuration({
      start: new Date(productLastAvailabilityAt),
      end: new Date(),
    });
    durationText = formatDuration(duration, {
      format: ["years", "months", "days"],
      delimiter: " / ",
    });
  }

  return durationText;
};

export const getHeaderFontSizeBy = (contentFontSize) => {
  const fontSize = contentFontSize.split("-")[2];
  return `font-size-${parseInt(fontSize) + 1}`;
};

/*
const hasKey = (obj, key) => {
  return Object.keys(obj).includes(key);
};

export const getRevisionChangeList = (revisions) => {
  const productChangeList = [];
  let count = 0;

  for (let i = 0; i < revisions?.length; i++) {
    if (typeof revisions[i + 1] !== "undefined") {
      const changeList = {};

      let newCurrency = revisions[i].currency
        ? revisions[i].currency.trim()
        : null;
      let previousCurrency = revisions[i + 1].currency
        ? revisions[i + 1].currency.trim()
        : null;
      if (newCurrency !== previousCurrency) {
        changeList.currency = newCurrency;
      }

      let newDescription = revisions[i].description
        ? revisions[i].description.trim()
        : null;
      let previousDescription = revisions[i + 1].description
        ? revisions[i + 1].description.trim()
        : null;
      if (newDescription !== previousDescription) {
        changeList.description = newDescription;
      }

      let newName = revisions[i].name ? revisions[i].name.trim() : null;
      let previousName = revisions[i + 1].name
        ? revisions[i + 1].name.trim()
        : null;
      if (newName !== previousName) {
        changeList.name = newName;
      }

      if (revisions[i].price !== revisions[i + 1].price) {
        changeList.price = revisions[i].price;
      }

      let newAvailability = revisions[i].Availability
        ? revisions[i].Availability.trim()
        : null;
      let previousAvailability = revisions[i + 1].Availability
        ? revisions[i + 1].Availability.trim()
        : null;
      if (newAvailability !== previousAvailability) {
        changeList.availability = newAvailability;
      }

      let newSKU = revisions[i].SKU ? revisions[i].SKU.trim() : null;
      let previousSKU = revisions[i + 1].SKU
        ? revisions[i + 1].SKU.trim()
        : null;
      if (newSKU !== previousSKU) {
        changeList.sku = newSKU;
      }

      if (Object.keys(changeList).length !== 0) {
        const createdAt = format(
          new Date(revisions[i]?.created_at),
          "MMM dd yyyy"
        );
        changeList.createdAt = createdAt;

        productChangeList.push(changeList);
      }
    }

    if (productChangeList.length === 5) {
      break;
    }
  }

  return productChangeList;
};
*/

export const drawBorderActiveRow = (className, expandClassName, key, color) => {
  setTimeout(() => {
    const borderStyle = `1px solid ${color}`;
    const tableRowElement = document.querySelector(`.${className}-${key}`);
    tableRowElement?.childNodes.forEach((child, index) => {
      if (index === 1) {
        child.style.borderLeft = borderStyle;
      }
      if (index === tableRowElement.childNodes.length - 1) {
        child.style.borderRight = borderStyle;
      }
      child.style.borderTop = borderStyle;
    });

    const tableExpandRowElement = document.querySelector(
      `.${expandClassName}-${key}`
    );
    const tableContent = document.querySelector(".table .ant-table-content");
    tableContent?.addEventListener("scroll", () => {
      if (tableContent.scrollLeft === 0) {
        tableExpandRowElement.childNodes[0].style.borderLeft = borderStyle;
        tableExpandRowElement.childNodes[0].childNodes[0].style.borderLeft =
          "none";
      } else {
        tableExpandRowElement.childNodes[0].childNodes[0].style.borderLeft =
          borderStyle;
        tableExpandRowElement.childNodes[0].style.borderLeft = "none";
      }
    });
    if (tableExpandRowElement !== null) {
      tableExpandRowElement.childNodes[0].style.borderLeft = borderStyle;
      tableExpandRowElement.childNodes[0].style.borderBottom = borderStyle;
      tableExpandRowElement.childNodes[0].style.borderRight = borderStyle;
    }
  }, 500);
};

export const eraseBorderActiveRow = (className, expandClassName, key) => {
  const borderStyle = "none";
  const tableRows = document.querySelector(`.${className}-${key}`);
  tableRows.childNodes.forEach((child, index) => {
    if (index === 1) {
      child.style.borderLeft = borderStyle;
    }
    if (index === tableRows.childNodes.length - 1) {
      child.style.borderRight = borderStyle;
    }
    child.style.borderTop = borderStyle;
  });

  const tableExpandRowElement = document.querySelector(
    `.${expandClassName}-${key}`
  );
  tableExpandRowElement.childNodes[0].style.borderLeft = borderStyle;
  tableExpandRowElement.childNodes[0].style.borderBottom = borderStyle;
  tableExpandRowElement.childNodes[0].style.borderRight = borderStyle;
};

export const eraseAllBorderActiveRow = () => {
  const borderStyle = "none";
  const tableRows = document.querySelectorAll(
    ".ant-table-row.ant-table-row-level-0"
  );

  tableRows.forEach((row) => {
    row.childNodes.forEach((child, index) => {
      if (index === 1) {
        child.style.borderLeft = borderStyle;
      }
      if (index === row.childNodes.length - 1) {
        child.style.borderRight = borderStyle;
      }
      child.style.borderTop = "none";
    });
  });

  const tableExpandRowElement = document.querySelectorAll(
    `.ant-table-expanded-row.ant-table-expanded-row-level-1`
  );
  tableExpandRowElement.forEach((child) => {
    child.childNodes[0].style.borderLeft = borderStyle;
    child.childNodes[0].style.borderBottom = borderStyle;
    child.childNodes[0].style.borderRight = borderStyle;
  });
};

export const renderTooltip = (title = "") => (
  <Tooltip className="info-light-tooltip ml-1" placement="top" title={title}>
    <img src="/images/info-light.png" alt="Info Light" />
  </Tooltip>
);

export const formatTimeForDataTable = (time) => {
  return time && format(new Date(time), "dd/MM/yyyy");
};

export const renderClickDropdown = (items, overlayClassName, btnClassName) => (
  <Dropdown
    overlay={<Menu items={items} />}
    trigger={["click"]}
    overlayClassName={overlayClassName}
  >
    <Button className={btnClassName}>
      {items[0].label}
      <DownOutlined />
    </Button>
  </Dropdown>
);

export const renderWebsiteOption = (items) => {
  return (
    <Select
      defaultValue={items[0]?.label}
      style={{
        width: 125,
      }}
      className="website-table-select"
    >
      {items?.map((item) => (
        <Option key={item.key} value={item.value}>
          {item.label}
        </Option>
      ))}
    </Select>
  );
};
