import React from "react";

/**
 * props
 *
 * REQUIRED
 * modalState = true|false
 * changeModalState() (!modalState)
 * buttonText = string
 * fields[
 *  {
 *      REQUIRED
 *      type: string (text|textarea|select|checkbox) radio not supported yet
 *      label: string
 *
 *      IF APPLICABLE
 *      selectOptions: object {id:value, ...}
 *
 *      OPTIONAL
 *      placeholder: string
 *      help: string
 *      defaultValue: string
 *      iconLeft: string (className) not supported yet
 *      iconRight: string (className) not supported yet
 *  }
 * ]
 *
 * OPTIONAL
 * title = string
 * description = string
 */

function DeleteFormModal(props) {
  // Initialise field values
    const ff = Array(props.fields.length).fill("", 0, props.fields.length);
    props.fields.map((field, i) => (ff[i] = field.defaultValue ?? ""));

    function closeModal() {
    props.changeModalState();
    }

    function submitForm() {
    props.response();
    }

    return (
        <div className={props.modalState ? "modal is-active" : "modal"}>
            <div className="modal-background" onClick={closeModal}></div>
                <div className="modal-content small-modal modal-padding has-background-white">
                {props.title && (
                    <div className="modal-heading">
                    <h4 className="title is-size-5 has-text-weight-normal">
                        {props.title}
                    </h4>
                    {props.description && <p>{props.description}</p>}
                    </div>
                )}
                {props.fields.map((field, i) => {
                    return (
                    <div className="field lightbox-min-height" key={i}>
                        {field.label && (
                            <label className="label has-text-red has-text-weight-normal">
                                {field.label}
                            </label>
                        )}
                        {field.help && <p className="help">{field.help}</p>}
                    </div>
                    );
                })}
                <div className="buttons is-right">
                    <button className="button button-outlined-light-blue" onClick={closeModal}>{props.buttonTextCancel ?? "ERROR!"}</button>
                    <button className="button button-contained-light-blue" onClick={submitForm}>{props.buttonText ?? "ERROR!"}</button>
                </div>
            </div>
        </div>
    );
}

export default DeleteFormModal;
