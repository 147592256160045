import { Row, Popover } from "antd";
import React, { useState } from "react";
import EcosystemPopoverContent from "./EcosystemPopoverContent";
import EcosystemPopoverTitle from "./EcosystemPopoverTitle";
import "./EcosystemBoardHeader.scss";

const EcosystemBoardHeader = ({
  ecosystemId,
  ecosystemName,
  ecosystemUuid,
  ecosystemIndustry,
  ecosystemCurrencyId,
}) => {
  const [popoverVisible, setPopoverVisible] = useState(false);

  const handlePopoverVisible = (newVisible) => {
    setPopoverVisible(newVisible);
  };

  return (
    <Row className="ecosystem-name-container">
      <a
        className="ecosystem-name"
        href={`/ecosystem/${ecosystemUuid}/tracking-dashboard`}
      >
        View {ecosystemName} dashboard
      </a>

      <Popover
        content={
          <EcosystemPopoverContent
            handlePopoverVisible={handlePopoverVisible}
            ecosystemId={ecosystemId}
          />
        }
        title={
          <EcosystemPopoverTitle
            handlePopoverVisible={handlePopoverVisible}
            ecosystemName={ecosystemName}
            ecosystemId={ecosystemId}
            ecosystemIndustry={ecosystemIndustry}
            ecosystemCurrencyId={ecosystemCurrencyId}
          />
        }
        trigger="click"
        open={popoverVisible}
        onOpenChange={handlePopoverVisible}
        placement="bottomRight"
        overlayClassName="ecosystem-board-header-popover"
      >
        <i style={{ cursor: "pointer" }} className="fa fa-ellipsis-v"></i>
      </Popover>
    </Row>
  );
};

export default EcosystemBoardHeader;
