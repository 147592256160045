import React, { useEffect } from "react";
import MainDatePickerValuesConstant from "../../constants/MainDatePickerValuesConstant";
import "./MainDatePicker.scss";
import { Select } from "antd";
import { getExpectDate } from "../../utils/DateTimeUtil";
import { format, isValid } from "date-fns";
import { FILTER_SESSION_NAME } from "../../constants/FilterSessionName";
import { useSelector } from "react-redux";

const { Option } = Select;

const MainDatePicker = ({ onSelect, ecosystem, filterType }) => {
  const datePicker = useSelector((state) => state[filterType].datePicker);

  useEffect(() => {
    const filterSessionName = sessionStorage.getItem(FILTER_SESSION_NAME);

    if (filterSessionName !== "" && filterSessionName !== null) {
      const filterSession = JSON.parse(
        sessionStorage.getItem(filterSessionName),
      );
      onSelect(filterSession.filter.dateFrom);
    } else {
      onSelect(getExpectDate(30));
    }
  }, [onSelect]);

  return (
    <>
      {datePicker && (
        <Select
          className="date-picker-select is-flex-grow-0"
          defaultValue={datePicker}
          onSelect={onSelect}
        >
          {MainDatePickerValuesConstant.map((datePickerValue) => {
            const createdAt = new Date(ecosystem?.created_at);
            return (
              <Option
                className="date-picker-value"
                key={datePickerValue?.value}
                value={
                  getExpectDate(datePickerValue?.value) === ""
                    ? isValid(createdAt)
                      ? format(createdAt, "yyyy-M-d")
                      : ""
                    : getExpectDate(datePickerValue.value)
                }
              >
                {datePickerValue.text}
              </Option>
            );
          })}
        </Select>
      )}
    </>
  );
};

export default MainDatePicker;
