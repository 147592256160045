import React from "react";
import "./MainFilterContainer.scss";

const MainFilterContainer = ({ children }) => {
  return (
    <div className="main-filter-container is-flex is-flex-direction-row is-align-content-center">
      {children}
    </div>
  );
};

export default MainFilterContainer;
