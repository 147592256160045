import { Button, Space, Table } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllWebpagesOfEcosystemQuery } from "../../../../utils/Queries/WebpageQueries";
import { capitalizeFirstLetter } from "../../../../utils/StringUtil";
import {
  drawBorderActiveRow,
  eraseAllBorderActiveRow,
  eraseBorderActiveRow,
  getHeaderFontSizeBy,
} from "../../../../utils/TableUtil";
import { format } from "date-fns";
import { Link, useLocation } from "react-router-dom";
import {
  changeDatePicker,
  checkWebsiteFilter,
  checkTagFilter,
} from "../../../../store/WebsiteTracking/WebsiteTracking";
import {
  WEBPAGE_FILTER_SESSION,
  FILTER_SESSION_NAME,
  WEBPAGE_VISIBLE_COLUMN_SESSION,
  WEBPAGE_FONT_SIZE_SESSION,
} from "../../../../constants/FilterSessionName";
import { getFilterSession } from "../../../../utils/SessionUtil";
import {
  renderTags,
  renderTooltip,
  formatTimeForDataTable,
} from "../../../../utils/TableUtil";
import { useGetEcosystemIdByUuidQuery } from "../../../../utils/Queries/EcosystemQueries";
import Media from "react-media";
import "./TableContent.scss";

const TableContent = ({
  ecosystemId: ecosystemUuid,
  selectedWebsitePageIds,
  setSelectedWebsitePageIds,
  filters,
  visibleColumns,
  fontSize,
}) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [sortField, setSortField] = useState(() => {
    const filterSession = getFilterSession(WEBPAGE_FILTER_SESSION);
    if (filterSession !== null) {
      return filterSession.sort.sortField;
    }
    return [];
  });
  const [sortDirection, setSortDirection] = useState(() => {
    const filterSession = getFilterSession(WEBPAGE_FILTER_SESSION);
    if (filterSession !== null) {
      return filterSession.sort.sortDirection;
    }
    return [];
  });
  const [expandedRows, setExpandedRows] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();

  const tableRowClassName = useMemo(() => "table-row", []);
  const tableExpandRowClassName = useMemo(() => "table-expand-row", []);

  const datePicker = useSelector((state) => state.websiteTracking.datePicker);
  const websiteFilter = useSelector(
    (state) => state.websiteTracking.websiteFilter
  );
  const tagFilter = useSelector((state) => state.websiteTracking.tagFilter);

  useEffect(() => {
    if (location?.state?.isFromDetail) {
      const filterSessionName = sessionStorage.getItem(FILTER_SESSION_NAME);
      if (filterSessionName !== "" && filterSessionName !== null) {
        const webpageFilter = JSON.parse(
          sessionStorage.getItem(WEBPAGE_FILTER_SESSION)
        );
        dispatch(changeDatePicker(webpageFilter.filter.dateFrom));
        dispatch(checkWebsiteFilter(webpageFilter.filter.website));
        dispatch(checkTagFilter(webpageFilter.filter.tag));
      }
    }
  }, [dispatch, location?.state?.isFromDetail]);

  const { data: ecosystemId } = useGetEcosystemIdByUuidQuery(ecosystemUuid);

  const { data, isLoading } = useGetAllWebpagesOfEcosystemQuery(
    ecosystemId,
    {
      dateFrom: datePicker,
      website: websiteFilter,
      tag: tagFilter,
    },
    {
      sortField: sortField,
      sortDirection: sortDirection[0] === "ascend" ? ["asc"] : ["desc"],
    },
    {
      page: page,
      pageSize: pageSize,
    },
    filters
  );

  const handleViewPageOnClick = useCallback(() => {
    sessionStorage.setItem(
      WEBPAGE_FILTER_SESSION,
      JSON.stringify({
        filter: {
          dateFrom: datePicker,
          website: websiteFilter,
          tag: tagFilter,
        },
        sort: {
          sortField: sortField,
          sortDirection: sortDirection,
        },
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
    sessionStorage.setItem(FILTER_SESSION_NAME, WEBPAGE_FILTER_SESSION);
    sessionStorage.setItem(
      WEBPAGE_VISIBLE_COLUMN_SESSION,
      JSON.stringify(visibleColumns)
    );
    sessionStorage.setItem(WEBPAGE_FONT_SIZE_SESSION, fontSize);
  }, [
    datePicker,
    fontSize,
    page,
    pageSize,
    sortDirection,
    sortField,
    tagFilter,
    visibleColumns,
    websiteFilter,
  ]);

  const handleOnChange = useCallback((pagination, filters, sorter, extra) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
    if (sorter.order === undefined || sorter.order === null) {
      setSortField(["web_page_updated_at"]);
    } else {
      setSortField([sorter.columnKey]);
    }
    setSortDirection(
      sorter.order === undefined || sorter.order === null
        ? ["descend"]
        : sorter.order.includes("ascend")
          ? ["ascend"]
          : ["descend"]
    );
  }, []);

  const rowSelection = {
    selectedRowKeys: selectedWebsitePageIds,
    onChange: (selectedRowKeys) => {
      setSelectedWebsitePageIds(selectedRowKeys);
    },
  };

  const handleCloseOnClick = useCallback(
    (record) => {
      setExpandedRows(expandedRows.filter((row) => row !== record.key));
      eraseBorderActiveRow(
        tableRowClassName,
        tableExpandRowClassName,
        record.key
      );
    },
    [expandedRows, tableExpandRowClassName, tableRowClassName]
  );

  const colorPalletHistory = useMemo(() => {
    return {
      "<p>": "#307be4",
      "<ul>": "#9d96eb",
      "<ol>": "#9d96eb",
      "<h1>": "#7488e8",
      "<h2>": "#7488e8",
      "<h3>": "#7488e8",
      "<h4>": "#7488e8",
      "<h5>": "#7488e8",
      "<h6>": "#7488e8",
      "<meta>": "#f0799f",
      "<title>": "#e95e7e",
      "<a>": "#de425b",
      "<img>": "#ff66cc",
      "<table>": "#ffa600",
      "<form>": "#ff873c",
      "<footer>": "#ec6456",
      "<nav>": "#ec6456",
      "<video>": "#99d2ff",
      "<iframe>": "#69cef9",
      "<script>": "#1ac9e9",
    };
  }, []);

  const getSortDirection = useCallback(
    (key) => {
      return sortField[0] === key ? sortDirection[0] : null;
    },
    [sortDirection, sortField]
  );

  const headerFontSize = useMemo(() => {
    return getHeaderFontSizeBy(fontSize);
  }, [fontSize]);

  const columns = useMemo(
    () => [
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>
            Title & URL{" "}
            {renderTooltip(
              "This displays the title of the page along with the page address"
            )}
          </span>
        ),
        key: "url",
        className: `has-border-right col ${fontSize}`,
        render: (text) => (
          <React.Fragment key={`${text.id}`}>
            <p>
              {text?.title?.length > 50
                ? text?.title?.substring(0, 50) + "..."
                : text?.title}
            </p>
            <a
              href={text.url}
              className="cell-link"
              target="_blank"
              rel="noreferrer"
            >
              {text?.url?.length > 50
                ? text?.url?.substring(0, 50) + "..."
                : text?.url}
              <span className="icon is-align-items-middle">
                <i className="fa fa-external-link"></i>
              </span>
            </a>
          </React.Fragment>
        ),
        align: "left",
        fixed: true,
        hideOnMd: true,
        width: 400,
      },
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>
            Title & URL{" "}
            {renderTooltip(
              "This displays the title of the page along with the page address"
            )}
          </span>
        ),
        key: "url",
        className: `has-border-right ${fontSize}`,
        render: (text) => (
          <React.Fragment key={`${text.id}`}>
            <p>
              {text?.title?.length > 50
                ? text?.title?.substring(0, 50) + "..."
                : text?.title}
            </p>
            <a
              href={text.url}
              className="cell-link"
              target="_blank"
              rel="noreferrer"
            >
              {text?.url?.length > 50
                ? text?.url?.substring(0, 50) + "..."
                : text?.url}
              <span className="icon is-align-items-middle">
                <i className="fa fa-external-link"></i>
              </span>
            </a>
          </React.Fragment>
        ),
        align: "left",
        hideOnLg: true,
        width: 450,
      },
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>
            Website
          </span>
        ),
        className: `${fontSize}`,
        dataIndex: "website_name",
        key: "website_name",
        sorter: true,
        showSorterTooltip: false,
        width: 250,
        align: "left",
        sortOrder: getSortDirection("website_name"),
      },
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>Edits</span>
        ),
        className: `${fontSize}`,
        dataIndex: "num_revisions",
        key: "num_revisions",
        align: "left",
        sorter: true,
        showSorterTooltip: false,
        width: 100,
        sortOrder: getSortDirection("num_revisions"),
      },
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>
            Status {renderTooltip("Status code in human language ;)")}
          </span>
        ),
        className: `${fontSize}`,
        dataIndex: "status_code_description",
        key: "status_code_description",
        sorter: true,
        showSorterTooltip: false,
        align: "left",
        width: 180,
        sortOrder: getSortDirection("status_code_description"),
      },
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>
            Status Code{" "}
            {renderTooltip("Response code issued by the site’s server")}
          </span>
        ),
        className: `${fontSize}`,
        dataIndex: "status_code",
        key: "status_code",
        align: "left",
        sorter: true,
        showSorterTooltip: false,
        width: 150,
        sortOrder: getSortDirection("status_code"),
      },
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>
            Discovered{" "}
            {renderTooltip("The date Data Noodle first crawled the page")}
          </span>
        ),
        className: `${fontSize}`,
        dataIndex: "web_page_created_at",
        key: "web_page_created_at",
        render: (text) => <>{formatTimeForDataTable(text)}</>,
        sorter: true,
        showSorterTooltip: false,
        align: "left",
        width: 150,
        sortOrder: getSortDirection("web_page_created_at"),
      },
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>
            Last crawled
          </span>
        ),
        className: `${fontSize}`,
        dataIndex: "last_crawled_at",
        key: "last_crawled_at",
        align: "left",
        render: (text) => <>{formatTimeForDataTable(text)}</>,
        sorter: true,
        showSorterTooltip: false,
        width: 150,
        sortOrder: getSortDirection("last_crawled_at"),
      },
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>
            Last edited
          </span>
        ),
        className: `${fontSize}`,
        dataIndex: "web_page_updated_at",
        key: "web_page_updated_at",
        align: "left",
        render: (text) => <>{formatTimeForDataTable(text)}</>,
        sorter: true,
        showSorterTooltip: false,
        width: 150,
        sortOrder: getSortDirection("web_page_updated_at"),
      },
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>
            Vertical tag
          </span>
        ),
        className: `${fontSize}`,
        key: "web_page_tags",
        align: "left",
        showSorterTooltip: false,
        width: 200,
        render: (website) => <>{renderTags(website)}</>,
        sorter: true,
        sortOrder: getSortDirection("web_page_tags"),
      },
    ],
    [headerFontSize, fontSize, getSortDirection]
  );

  const getColumnsLgScreen = useCallback(
    () =>
      columns.filter(({ hideOnLg = false, key }) => {
        return !hideOnLg && visibleColumns[key];
      }),
    [columns, visibleColumns]
  );

  const getColumnsMdScreen = useCallback(
    () =>
      columns.filter(({ hideOnMd = false, key }) => {
        return !hideOnMd && visibleColumns[key];
      }),
    [columns, visibleColumns]
  );

  return (
    <>
      <Media query="(max-width: 992px)">
        <Table
          className="table"
          dataSource={data?.data}
          columns={getColumnsMdScreen()}
          onChange={handleOnChange}
          pagination={{
            pageSize: pageSize,
            position: ["bottomCenter"],
            showSizeChanger: true,
            current: page,
            total: data?.total,
          }}
          scroll={{ x: "max-content" }}
          loading={isLoading}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <Space>
                  <div
                    style={{
                      height: "120px",
                      width: "476px",
                      borderRight: "3px solid #f5f6fa",
                    }}
                  ></div>
                  <Space
                    direction="vertical"
                    size="small"
                    style={{ width: "850px" }}
                    className="expand-data"
                  >
                    {record?.history.length > 0 ? (
                      record.history.map((revision) => (
                        <>
                          <Space
                            direction="horizontal"
                            size="small"
                            className="history-change-row"
                            align="start"
                            wrap
                          >
                            <span key={revision.created_at}>
                              {format(
                                new Date(revision.created_at),
                                "MMM dd yyyy"
                              )}
                            </span>
                            {revision?.change?.map((revisionChange) => (
                              <>
                                <span
                                  className="field-name"
                                  style={{
                                    backgroundColor:
                                      colorPalletHistory[
                                      revisionChange.toLowerCase()
                                      ],
                                  }}
                                >
                                  {capitalizeFirstLetter(revisionChange)}
                                </span>
                              </>
                            ))}
                          </Space>
                          <div
                            style={{
                              height: "3px",
                              backgroundColor: "#f5f6fa",
                            }}
                          ></div>
                        </>
                      ))
                    ) : (
                      <p className="text-no-changes">No changes detected yet</p>
                    )}
                    <Space>
                      <Link
                        to={{
                          pathname: `/ecosystem/${ecosystemUuid}/webpages/${record.website_id}/revisions/${record.id}`,
                          state: {
                            prevPath: location.pathname,
                            isSaveSession: true,
                          },
                        }}
                        onClick={handleViewPageOnClick}
                      >
                        <Button className="view-button">View Page</Button>
                      </Link>
                      <Button
                        className="close-button"
                        onClick={() => handleCloseOnClick(record)}
                      >
                        Close
                        <i
                          style={{ marginLeft: "10px" }}
                          className="fa fa-times"
                          aria-hidden="true"
                        ></i>
                      </Button>
                    </Space>
                  </Space>
                </Space>
              );
            },
            expandRowByClick: true,
            expandedRowKeys: expandedRows,
            expandedRowClassName: (record) =>
              `${tableExpandRowClassName}-${record.key}`,
            onExpand: (expanded, record) => {
              eraseAllBorderActiveRow();
              drawBorderActiveRow(
                tableRowClassName,
                tableExpandRowClassName,
                record.key,
                "#25bea0"
              );
            },
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                if (!expandedRows.includes(record.key)) {
                  setExpandedRows([...expandedRows, record.key]);
                }
              },
            };
          }}
          rowClassName={(record) => `${tableRowClassName}-${record.key}`}
        />
      </Media>

      <Media query="(min-width: 993px)">
        <Table
          className="table"
          dataSource={data?.data}
          columns={getColumnsLgScreen()}
          onChange={handleOnChange}
          pagination={{
            pageSize: pageSize,
            position: ["bottomCenter"],
            showSizeChanger: true,
            current: page,
            total: data?.total,
          }}
          scroll={{ x: "max-content" }}
          loading={isLoading}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <Space>
                  <div
                    style={{
                      height: "120px",
                      width: "415px",
                      borderRight: "3px solid #f5f6fa",
                    }}
                  ></div>
                  <Space
                    direction="vertical"
                    size="small"
                    style={{ width: "850px" }}
                    className="expand-data"
                  >
                    {record?.history.length > 0 ? (
                      record.history.map((revision) => (
                        <>
                          <Space
                            direction="horizontal"
                            size="small"
                            className="history-change-row"
                            align="start"
                            wrap
                          >
                            <span key={revision.created_at}>
                              {format(
                                new Date(revision.created_at),
                                "MMM dd yyyy"
                              )}
                            </span>
                            {revision?.change?.map((revisionChange) => (
                              <>
                                <span
                                  className="field-name"
                                  style={{
                                    backgroundColor:
                                      colorPalletHistory[
                                      revisionChange.toLowerCase()
                                      ],
                                  }}
                                >
                                  {capitalizeFirstLetter(revisionChange)}
                                </span>
                              </>
                            ))}
                          </Space>
                          <div
                            style={{
                              height: "3px",
                              backgroundColor: "#f5f6fa",
                            }}
                          ></div>
                        </>
                      ))
                    ) : (
                      <p className="text-no-changes">No changes detected yet</p>
                    )}
                    <Space>
                      <Link
                        to={{
                          pathname: `/ecosystem/${ecosystemUuid}/webpages/${record.website_id}/revisions/${record.id}`,
                          state: {
                            prevPath: location.pathname,
                            isSaveSession: true,
                          },
                        }}
                        onClick={handleViewPageOnClick}
                      >
                        <Button className="view-button">View Page</Button>
                      </Link>
                      <Button
                        className="close-button"
                        onClick={() => handleCloseOnClick(record)}
                      >
                        Close
                        <i
                          style={{ marginLeft: "10px" }}
                          className="fa fa-times"
                          aria-hidden="true"
                        ></i>
                      </Button>
                    </Space>
                  </Space>
                </Space>
              );
            },
            expandRowByClick: true,
            expandIcon: () => <></>,
            columnWidth: 0,
            expandedRowKeys: expandedRows,
            expandedRowClassName: (record) =>
              `${tableExpandRowClassName}-${record.key}`,
            onExpand: (expanded, record) => {
              eraseAllBorderActiveRow();
              drawBorderActiveRow(
                tableRowClassName,
                tableExpandRowClassName,
                record.key,
                "#25bea0"
              );
            },
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                if (!expandedRows.includes(record.key)) {
                  setExpandedRows([...expandedRows, record.key]);
                }
              },
            };
          }}
          rowClassName={(record) => `${tableRowClassName}-${record.key}`}
        />
      </Media>
    </>
  );
};

export default TableContent;
