import React, { Component, Fragment } from "react";
import Button from "../Button/Button";
import "./Footer.scss";

class Footer extends Component {
  emailClick() {
    window.location.href = "mailto:sup@datanoodle.com";
  }
  render() {
    var current_date = new Date();
    let thisYear = current_date.getFullYear();
    return (
      <Fragment>
        <footer className="datanoodle-footer">
          <div className="container">
            <div className="columns is-centered">
              <div className="column has-text-centered footer-links">
                <a
                  href="https://datanoodle.com/features.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button buttonText="Features" />
                </a>
                <a
                  href="https://datanoodle.com/pricing.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button buttonText="Pricing" />
                </a>
                <a
                  href="https://datanoodle.com/faq.html "
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button buttonText="FAQ" />
                </a>
                <a
                  href="https://datanoodle.com/privacy.html "
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button buttonText="Privacy Policy" />
                </a>
                <a
                  href="https://datanoodle.com/terms.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button buttonText="Terms" />
                </a>
                <a
                  href="https://datanoodle.com/contact.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button buttonText="Contact" />
                </a>
              </div>
            </div>
          </div>
        </footer>
        <div className="copyright-statement">
          <div className="columns is-centered">
            <div className="column is-one-quarter">
              &copy; {thisYear} DataNoodle
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Footer;
