import React, { useCallback } from "react";
import "./ScoreBoardTitle.scss";

const ScoreBoardTitle = ({ totalNumber, totalNumberText }) => {
  const formatNumberWithCommas = useCallback((numberWithoutCommas) => {
    return parseFloat(numberWithoutCommas).toLocaleString("en-US");
  }, []);

  return (
    <>
      <div className="scoreboard-total-number">
        {isNaN(totalNumber) ? totalNumber : formatNumberWithCommas(totalNumber)}
      </div>
      <div className="scoreboard-total-number-text">{totalNumberText}</div>
    </>
  );
};

export default ScoreBoardTitle;
