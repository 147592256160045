import React from "react";
import { Col, Row } from "antd";
import "./CustomRevisionLegend.scss";

const CustomRevisionLegend = ({
  color,
  value,
  label,
  prefix = "",
  postfix = "",
}) => {
  return (
    <>
      <Col xs={12} md={12} lg={4}>
        <Row align="top">
          <Col>
            <svg
              className="recharts-surface"
              width="14"
              height="14"
              viewBox="0 0 32 32"
              version="1.1"
              style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginRight: "4px",
                marginTop: "12px",
              }}
            >
              <path
                fill={color}
                cx="16"
                cy="16"
                type="circle"
                className="recharts-symbols"
                transform="translate(16, 16)"
                d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"
              ></path>
            </svg>
          </Col>

          <Col>
            <Row>
              <span className="custom-revision-legend-value">
                {prefix}
                {new Intl.NumberFormat("en-US").format(value)}
                {postfix}
              </span>
            </Row>
            <Row>
              <Col span={6}>
                <div className="custom-revision-legend-label">{label}</div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default CustomRevisionLegend;
