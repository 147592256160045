import { Button, Modal, Select, Space, Spin } from "antd";
import "./GGIntegrationModal.scss";
import {
  useGetGAAccount,
  useGetGGAccount,
  useGetGGAuthorizationURI,
} from "../../../utils/Queries/IntegrateGGAnalytics";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAddGGAnalytic, useDeleteGGAnalytic } from "../../../utils/Mutations/GGMutation";
import { useRefreshEcosystem } from "../../../utils/Mutations/EcosystemMutation";

const GGIntegrationModal = ({
  isOpen,
  handleCancel,
  ecosystemId,
  websiteId,
  websiteName,
  hasAnalytics,
}) => {
  const [isClick, setIsClickSignIn] = useState(false);
  const [isGAAccountLinked, setIsGAAccountLinked] = useState(false);

  const history = useNavigate();

  const { data, isSuccess } = useGetGGAuthorizationURI(
    isClick,
    ecosystemId,
    websiteId,
  );
  const { data: GGAccountData } = useGetGGAccount(hasAnalytics, ecosystemId, websiteId);

  const shouldFetchGAAccount = hasAnalytics && GGAccountData !== undefined && GGAccountData !== null  && isOpen;
  const {
    data: GAAccountData,
    isSuccess: isGAAccountSuccess,
    isLoading: isGAAccountLoading,
  } = useGetGAAccount(shouldFetchGAAccount, ecosystemId, websiteId);

  const {
    mutate: addGGAnalytic,
    isLoading: isAddGGAnalyticLoading,
    isSuccess: isAddGGAnalyticSuccess,
  } = useAddGGAnalytic();

  const { mutate: refreshEcosystem } = useRefreshEcosystem(ecosystemId);
  const { mutate: deleteGGAnalytic } = useDeleteGGAnalytic(); // Add the DELETE mutation

  useEffect(() => {
    if (isSuccess) {
      window.location.replace(data.auth_uri);
    }
  }, [data, history, isSuccess]);

  useEffect(() => {
    if (isAddGGAnalyticSuccess) {
      setIsGAAccountLinked(true);
    }
  }, [isAddGGAnalyticSuccess]);

  useEffect(() => {
    if (shouldFetchGAAccount && GAAccountData && GAAccountData.length === 0) {
      deleteGGAnalytic({ ecosystemId, websiteId });
    }
  }, [shouldFetchGAAccount, GAAccountData, deleteGGAnalytic, ecosystemId, websiteId]);

  const handleOk = () => {
    if (isGAAccountLinked) {
      handleCancel();
    } else if (GAAccountData && GAAccountData.length === 0) {
      handleCancel();
    }
    else{
      refreshEcosystem();
    }
  };

  return (
    <Modal
      title="Integrate Google Analytics"
      open={isOpen}
      onCancel={handleCancel}
      wrapClassName="gg-integration-modal-wrap"
      closable={true}
      className={
        isGAAccountSuccess || isAddGGAnalyticSuccess
          ? "integration-message"
          : "integration"
      }
      onOk={handleOk}
    >
      {isGAAccountLoading ? (
        <Spin />
      ) : isGAAccountSuccess ? (
        GAAccountData.length === 0 ? (
          <div className="ga-integration-message">
            <p className="ga-integration-success-message">Oops!</p>
            <p>You don't have any GA Account</p>
          </div>
        ) : isAddGGAnalyticLoading ? (
          <Spin />
        ) : isAddGGAnalyticSuccess ? (
          <div className="ga-integration-message">
            <p className="ga-integration-success-message">Success!</p>
            <p>Google Analytics is now linked to {websiteName}</p>
          </div>
        ) : (
          <Select
            className="ga-account-select"
            onChange={(value) => {
              addGGAnalytic({
                ecosystemId: ecosystemId,
                websiteId: websiteId,
                analyticId: value,
              });
            }}
            options={
              isGAAccountSuccess &&
              GAAccountData !== undefined &&
              Object.keys(GAAccountData).map((key) => {
                const result = {};
                GAAccountData[key].forEach((element) => {
                  result["label"] = `${key} (${element})`;
                  result["value"] = element;
                });
                return result;
              })
            }
            placeholder="Choose your GA account"
          />
        )
      ) : (
        <Button
          className="signin-gg-btn"
          onClick={() => {
            setIsClickSignIn(true);
          }}
        >
          <Space align="center">
            <img
              src="/images/gg-logo.png"
              width={15}
              height={15}
              alt="Google Logo"
            />
            Sign in with Google
          </Space>
        </Button>
      )}
    </Modal>
  );
};

export default GGIntegrationModal;
