import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { PostData } from "../../services/PostData";
import FormModal from "../Modals/FormModal";
import DeleteFormModal from "../Modals/DeleteFormModal";

export const WebsiteBox = (props) => {
  const [editWebsiteModalState, setEditWebsiteModalState] = useState(false);
  const [dd, setDd] = useState("dropdown is-right is-pulled-right");

  const [deleteWebsiteModalState, setDeleteWebsiteModalState] = useState(false);

  function editWebsite(fields) {
    let websiteName = fields[0];
    if (websiteName !== null && websiteName.length > 0) {
      const postOptions = {
        postRoute:
          "/ecosystem/" + props.ecosystemId + "/website/" + props.website.id,
        method: "PUT",
        type: "x-www-form-urlencoded",
        token: true,
        formData: {
          name: websiteName,
        },
      };
      PostData(postOptions).then((result) => {
        if (result.success) {
          setEditWebsiteModalState(false);
          props.updateEcosystem(true);
        }
      });
    } else {
      // TODO: Handle for validation...
      console.log("Please enter the website name and homepage");
    }
  }

  function deleteWebsite() {
    const postOptions = {
      postRoute:
        "/ecosystem/" + props.ecosystemId + "/website/" + props.website.id,
      method: "DELETE",
      token: true,
    };
    PostData(postOptions).then((result) => {
      if (result.success) {
        setDeleteWebsiteModalState(false);
        props.updateEcosystem(true);
      }
    });
  }

  function changeEditWebsiteModalState() {
    setEditWebsiteModalState(!editWebsiteModalState);
  }

  function changeDeleteWebsiteModalState() {
    setDeleteWebsiteModalState(!deleteWebsiteModalState);
  }

  function ecoDropdown() {
    if (dd === "dropdown is-right is-pulled-right") {
      setDd("dropdown is-right is-pulled-right is-active");
    } else {
      setDd("dropdown is-right is-pulled-right");
    }
  }

  return (
    <Fragment>
      <FormModal
        title="Edit website"
        modalState={editWebsiteModalState}
        changeModalState={changeEditWebsiteModalState}
        fields={[
          {
            type: "text",
            defaultValue: props.website.name,
          },
        ]}
        buttonTextCancel="Cancel"
        buttonText="Save"
        response={editWebsite}
      />

      <DeleteFormModal
        title="Are you sure you want to delete?"
        modalState={deleteWebsiteModalState}
        changeModalState={changeDeleteWebsiteModalState}
        fields={[
          {
            type: "text",
            label: "If you delete all of your website data will be lost.",
          },
        ]}
        buttonTextCancel="No, don't delete"
        buttonText="Yes"
        response={deleteWebsite}
      />
      <div className="column is-3-desktop is-4-tablet">
        <div className="card">
          <div className="card-content">
            <div className="columns">
              <div className="column websiteBox-website is-10">
                <div data-tooltip={props.website.name}>
                  <Link
                    to={
                      "/ecosystem/" +
                      props.ecosystemId +
                      "/webpages/" +
                      props.website.id
                    }
                    className="title is-size-5"
                  >
                    {props.website.name}
                  </Link>
                </div>
              </div>
              <div className="column">
                <div className={dd} onClick={ecoDropdown}>
                  <div className="dropdown-trigger is-clickable">
                    <span className="icon">
                      <i className="fa fa-ellipsis-v"></i>
                    </span>
                  </div>
                  <div className="dropdown-menu">
                    <div className="dropdown-content">
                      <div className="dropdown-item dropdown-seprate is-clickable">
                        <span onClick={changeEditWebsiteModalState}>
                          Edit Website
                        </span>
                      </div>
                      <div className="dropdown-item is-clickable has-text-red">
                        <span onClick={changeDeleteWebsiteModalState}>
                          Delete Website
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="has-text-grey-lighter websiteBox-website">
              <div data-tooltip={props.website.homepage}>
                <p>{props.website.homepage}</p>
              </div>
            </div>
            <p>&nbsp;</p>
            <figure className="image">
              <img src="/images/website.jpg" alt="Website screenshot" />
            </figure>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
