import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Navigate } from "react-router-dom";
import { LoggedInStatus } from "../services/LoggedInStatus";
import SubHeader from "../components/Layout/SubHeader";
import Footer from "../components/Layout/Footer";
import Loading from "../components/Animations/Loading";
import MessageModal from "../../components/Modals/MessageModal";
import { useParams } from "react-router-dom";
import MainSection from "../components/MainSection/MainSection";
import MainSectionTitle from "../components/MainSectionTitle/MainSectionTitle";
import MainDatePicker from "../components/MainDatePicker/MainDatePicker";
import MainFilterContainer from "../components/MainFilterContainer/MainFilterContainer";
import MainFilter from "../components/MainFilter/MainFilter";
import { useGetAllWebsitesBelongsToEcosystemQuery } from "../utils/Queries/WebsiteQueries";
import { useGetAllTagsBelongsToEcosystemQuery } from "../utils/Queries/TagQueries";
import {
  TAG_FILTER,
  WEBSITE_FILTER,
  WEBSITE_TRACKING,
} from "../constants/MainFilterTypeConstant";
import {
  changeDatePicker,
  checkTagFilter,
  checkWebsiteFilter,
} from "../store/WebsiteTracking/WebsiteTracking";
import { useDispatch, useSelector } from "react-redux";
import ScoreBoardContainer from "../components/ScoreBoardContainer/ScoreBoardContainer";
import { useGetAllWebpagesStatisticBelongsToEcosystemQuery } from "../utils/Queries/WebpageQueries";
import WebsiteDataTable from "../components/WebsiteDataTable";
import Header from "../components/Layout/Header";
import {
  useGetAllEcosystemsOfCurrentUserQuery,
  useGetEcosystemIdByUuidQuery,
} from "../utils/Queries/EcosystemQueries";
import { CHART_TYPE_CHANGES, CHART_TYPE_STATUS } from "../constants/ChartType";
import WebsitePageRevisionChangesChart from "../components/Chart/WebsitePageRevisionChangesChart";
import WebsitePageRevisionStatusChart from "../components/Chart/WebsitePageRevisionStatusChart";
import MessageBox from "../components/MessageBox";
import MessageBoxModal from "../components/Modals/MessageBoxModal";
import { useFinishLearn } from "../utils/Mutations/UserMutation";

const WebsitePage = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [ecosystems, setEcosystems] = useState([]);
  const [websites] = useState(null);
  const [error] = useState(false);
  const [ecosystemName, setEcosystemName] = useState("");
  const [chartType, setChartType] = useState(CHART_TYPE_CHANGES);
  const [hideLegends, setHideLegends] = useState([]);
  const [user] = useState(() => JSON.parse(sessionStorage.getItem("user")));

  const dispatch = useDispatch();
  const datePicker = useSelector((state) => state.websiteTracking.datePicker);
  const websiteFilter = useSelector(
    (state) => state.websiteTracking.websiteFilter
  );
  const tagFilter = useSelector((state) => state.websiteTracking.tagFilter);

  const { ecosystemUuid, websiteId } = useParams();

  const { data: ecosystemId } = useGetEcosystemIdByUuidQuery(ecosystemUuid);

  const {
    data: allWebsitesOfCurrentEcosystemData,
    isSuccess: isAllWebsitesOfCurrentEcosystemSuccess,
  } = useGetAllWebsitesBelongsToEcosystemQuery(ecosystemId);
  const {
    data: allTagsOfCurrentEcosystemData,
    isSuccess: isAllTagsOfCurrentEcosystemSuccess,
  } = useGetAllTagsBelongsToEcosystemQuery(ecosystemId);
  const { data: allWebpagesOfCurrentEcosystemData } =
    useGetAllWebpagesStatisticBelongsToEcosystemQuery(ecosystemId, {
      dateFrom: datePicker,
      website: websiteFilter,
      tag: tagFilter,
    });

  const { data: allEcosystemOfUser, isSuccess: isSuccessAllEcosystemOfUser } =
    useGetAllEcosystemsOfCurrentUserQuery();

  useEffect(() => {
    setIsAuthenticated(LoggedInStatus());
    if (isSuccessAllEcosystemOfUser) {
      setEcosystems(allEcosystemOfUser);
    }
    sessionStorage.setItem("ecosystemId", ecosystemId);
    isSuccessAllEcosystemOfUser &&
      allEcosystemOfUser.forEach((ecosystem) => {
        if (ecosystem?.id === parseInt(ecosystemId)) {
          setEcosystemName(ecosystem?.name);
        }
      });
  }, [allEcosystemOfUser, isSuccessAllEcosystemOfUser, ecosystemId]);

  const subHeader = (websiteId) => {
    if (websiteId > 0) {
      let _webisteId = parseInt(websiteId);
      return websites.data[0].find((w) => w.id === _webisteId).name;
    }
  };

  const updateLogin = (val) => {
    setIsAuthenticated(val);
  };

  const logout = () => {
    setIsAuthenticated(false);
  };

  const handleMainFilterOnChange = useCallback(
    (filterType, checkedValues) => {
      switch (filterType) {
        case WEBSITE_FILTER:
          dispatch(checkWebsiteFilter(checkedValues));
          break;
        case TAG_FILTER:
          dispatch(checkTagFilter(checkedValues));
          break;
        default:
      }
    },
    [dispatch]
  );

  const handleSelectChartType = useCallback((chartType) => {
    setChartType(chartType);
    setHideLegends([]);
  }, []);

  const handleMainDatePickerOnSelect = useCallback(
    (e) => {
      dispatch(changeDatePicker(e));
    },
    [dispatch]
  );

  const canShowMainFilter = useMemo(
    () =>
      isAllWebsitesOfCurrentEcosystemSuccess &&
      isAllTagsOfCurrentEcosystemSuccess &&
      isSuccessAllEcosystemOfUser,
    [
      isAllTagsOfCurrentEcosystemSuccess,
      isAllWebsitesOfCurrentEcosystemSuccess,
      isSuccessAllEcosystemOfUser,
    ]
  );

  const { mutate: finishLearnEcosystem } = useFinishLearn();

  const handleWebpageMessageBoxOnClose = () => {
    finishLearnEcosystem({
      id: user.data.id,
      learnName: "finish_learn_webpage",
      value: 1,
    });
    user.data.finish_learn_webpage = 1;
    sessionStorage.setItem("user", JSON.stringify(user));
  };

  const handleWebpageDatatableMessageBoxOnClose = () => {
    finishLearnEcosystem({
      id: user.data.id,
      learnName: "finish_learn_webpage_datatable",
      value: 1,
    });
    user.data.finish_learn_webpage_datatable = 1;
    sessionStorage.setItem("user", JSON.stringify(user));
  };

  return (
    <>
      {isAuthenticated === false ||
        isAuthenticated === "exit" ||
        isAuthenticated === undefined ? (
        <Navigate to="/" />
      ) : isAuthenticated === null ? ( // need revisit
        <Loading pad={200} />
      ) : error !== false ? (
        <MessageModal modalState={this.errorModalState} message={error} />
      ) : (
        <>
          <div>
            <Header
              updateLogin={updateLogin}
              logout={logout}
              loggedIn={isAuthenticated}
              ecosystems={isSuccessAllEcosystemOfUser && ecosystems}
              ecosystemId={ecosystemId}
            />
            <SubHeader
              ecosystemName={ecosystemName}
              ecosystemId={ecosystemUuid}
              websiteId={websiteId}
              websiteName={subHeader(websiteId)}
            />
          </div>

          <MainSection>
            {user?.data && !user?.data?.finish_learn_webpage && (
              <MessageBox
                handleOnClose={handleWebpageMessageBoxOnClose}
                message={
                  <>
                    The Changes and Status graphs plots changes on the website
                    over a given period.{" "}
                    <MessageBoxModal
                      message="Click here to watch a video"
                      title="Content Graph"
                      videoSrc="https://player.vimeo.com/video/798157823?h=90bdbebe1c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    />{" "}
                    on how to use this feature. You can find more information on
                    our{" "}
                    <a href="https://datanoodle.com/get-started.html">
                      Get started guide
                    </a>
                    .
                  </>
                }
                style={{ marginBottom: "10px" }}
              />
            )}
            <MainSectionTitle title={"Website Changes"} />
            <MainFilterContainer>
              {canShowMainFilter && (
                <>
                  <MainDatePicker
                    onSelect={handleMainDatePickerOnSelect}
                    ecosystem={allWebsitesOfCurrentEcosystemData.data[0]}
                    filterType={WEBSITE_TRACKING}
                  />
                  <MainFilter
                    filterValues={{
                      websites: allWebsitesOfCurrentEcosystemData,
                      tags: allTagsOfCurrentEcosystemData,
                    }}
                    filterType={WEBSITE_TRACKING}
                    onChange={handleMainFilterOnChange}
                  />
                </>
              )}
            </MainFilterContainer>

            <ScoreBoardContainer
              statisticData={{
                "Total pages": allWebpagesOfCurrentEcosystemData?.totalWebPages,
                "New pages": allWebpagesOfCurrentEcosystemData?.totalNewPages,
                "In OK status":
                  allWebpagesOfCurrentEcosystemData?.totalStatusChanges,
              }}
            />
            {!canShowMainFilter ? (
              <Loading />
            ) : chartType === CHART_TYPE_CHANGES ? (
              <WebsitePageRevisionChangesChart
                ecosystem={allWebsitesOfCurrentEcosystemData?.data[0]}
                chartTypeLabels={[CHART_TYPE_CHANGES, CHART_TYPE_STATUS]}
                dateFrom={datePicker}
                handleSelectChartType={handleSelectChartType}
                chartType={chartType}
                websiteFilter={websiteFilter}
                tagFilter={tagFilter}
                hideLegends={hideLegends}
                setHideLegends={setHideLegends}
              />
            ) : (
              <WebsitePageRevisionStatusChart
                ecosystem={allWebsitesOfCurrentEcosystemData?.data[0]}
                chartTypeLabels={[CHART_TYPE_CHANGES, CHART_TYPE_STATUS]}
                dateFrom={datePicker}
                handleSelectChartType={handleSelectChartType}
                chartType={chartType}
                websiteFilter={websiteFilter}
                tagFilter={tagFilter}
                hideLegends={hideLegends}
                setHideLegends={setHideLegends}
              />
            )}

            {user?.data && !user?.data.finish_learn_webpage_datatable && (
              <MessageBox
                handleOnClose={handleWebpageDatatableMessageBoxOnClose}
                message={
                  <>
                    The Website Data gives you visibility of the pages tracked.{" "}
                    <MessageBoxModal
                      message="Click here to watch a video"
                      title="Website Data"
                      videoSrc="https://player.vimeo.com/video/798594747?h=ec14d8e014&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    />{" "}
                    on how to use this feature. You can find more information on
                    our{" "}
                    <a href="https://datanoodle.com/get-started.html">
                      Get started guide
                    </a>
                    .
                  </>
                }
                style={{ marginBottom: "5px", marginTop: "20px" }}
              />
            )}

            <WebsiteDataTable
              title="Website Data"
              ecosystemId={ecosystemUuid}
              tags={allTagsOfCurrentEcosystemData?.data?.tags}
            />
          </MainSection>
          <Footer />
        </>
      )}
    </>
  );
};

export default WebsitePage;
