import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Navigate, useParams, useLocation } from "react-router-dom";
import { LoggedInStatus } from "../services/LoggedInStatus";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import Loading from "../components/Animations/Loading";
import MessageModal from "../../components/Modals/MessageModal";
import MainSection from "../components/MainSection/MainSection";
import MainSectionTitle from "../components/MainSectionTitle/MainSectionTitle";
import SubHeader from "../components/Layout/SubHeader";
import Welcome from "../components/Welcome";
import { Col, Row, Tooltip } from "antd";
import { GetTeams } from "../../services/GetTeams";
import {
  useGetAllEcosystemsOfCurrentUserQuery,
  useGetEcosystemIdByUuidQuery,
} from "../utils/Queries/EcosystemQueries";
import MessageBox from "../components/MessageBox";
import MessageBoxModal from "../components/Modals/MessageBoxModal";
import { useFinishLearn } from "../utils/Mutations/UserMutation";
import "../css/pages/WelcomePage.scss";
import { useDispatch } from "react-redux";
import { setCurrencies, setLoading } from "../store/Currencies";
import { useGetAllCurrenciesQuery } from "../utils/Queries/CurrencyQueries";

const WelcomePage = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [ecosystems, setEcosystems] = useState([]);
  const [error, setError] = useState(false);
  const [teamId, setTeamId] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const { search } = useLocation();
  const searchQuery = useMemo(() => new URLSearchParams(search), [search]);
  const { ecosystemUuid } = useParams();
  const { data: ecosystemId } = useGetEcosystemIdByUuidQuery(ecosystemUuid);
  const getTeamId = useCallback(async () => {
    let teams = await GetTeams();
    setTeamId(teams.data[0].id);
  }, []);

  const { data: currencies, isLoading: loading, error: currencyError } = useGetAllCurrenciesQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    if (currencies) {
      dispatch(setCurrencies(currencies));
    }
    dispatch(setLoading(loading));
    if (currencyError) {
      dispatch(setError(currencyError.message));
    }
  }, [currencies, loading, currencyError, dispatch]);

  const {
    data: allEcosystemOfUser,
    isSuccess: isSuccessAllEcosystemOfUser,
    isLoading: isLoadingAllEcosystemOfUser,
  } = useGetAllEcosystemsOfCurrentUserQuery();
  const { mutate: finishLearnEcosystem } = useFinishLearn();

  useEffect(() => {
    setIsAuthenticated(LoggedInStatus());
    if (isSuccessAllEcosystemOfUser) {
      setEcosystems(allEcosystemOfUser);
    }

    const storedUser = sessionStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    getTeamId();
    setIsLoadingUser(false);
  }, [isSuccessAllEcosystemOfUser, allEcosystemOfUser, getTeamId]);

  const updateLogin = (val) => {
    setIsAuthenticated(val);
  };

  const logout = () => {
    setIsAuthenticated(false);
  };

  const handleMessageBoxOnClose = () => {
    finishLearnEcosystem({
      id: user.data.id,
      learnName: "finish_learn_ecosystem",
      value: 1,
    });
    const updatedUser = { ...user, data: { ...user.data, finish_learn_ecosystem: 1 } };
    setUser(updatedUser);
    sessionStorage.setItem("user", JSON.stringify(updatedUser));
  };

  const googleNotification = useRef();

  useEffect(() => {
    if (searchQuery.get("analytics")) {
      googleNotification.current.classList.add("open");
      setTimeout(() => {
        googleNotification.current.classList.remove("open");
      }, 5000);
    }
  }, [searchQuery]);

  if (isAuthenticated === false || isAuthenticated === "exit" || isAuthenticated === undefined) {
    return <Navigate to="/" />;
  }

  if (isAuthenticated === null || isLoadingUser) {
    return <Loading pad={200} />;
  }

  if (error) {
    return <MessageModal modalState={this.errorModalState} message={error} />;
  }

  return (
    <>
      <div className="gg-notification" ref={googleNotification}>
        Sign in with Google successfully
      </div>
      <Header
        updateLogin={updateLogin}
        logout={logout}
        loggedIn={isAuthenticated}
        ecosystems={isSuccessAllEcosystemOfUser && ecosystems}
        ecosystemId={ecosystemId}
      />
      <SubHeader userName={user?.data?.first_name} />
      <MainSection>
        {user?.data && !user?.data?.finish_learn_ecosystem && (
          <MessageBox
            handleOnClose={handleMessageBoxOnClose}
            message={
              <>
                Setting up an Ecosystem is easy,{" "}
                <MessageBoxModal
                  message="click here to watch a video"
                  title="Ecosystem Video"
                  videoSrc="https://player.vimeo.com/video/798268563?h=97586282ee&badge=0&autopause=0&player_id=0&app_id=58479/embed"
                />{" "}
                on how to start tracking. You can find more information on our{" "}
                <a href="https://datanoodle.com/get-started.html">Get started guide</a>.
              </>
            }
            style={{ marginBottom: "10px" }}
          />
        )}
        <Row>
          <Col span={23}>
            <MainSectionTitle title={"Ecosystems"} />
          </Col>
          <Col span={1}>
            <Tooltip
              className="info-light-tooltip ml-1"
              placement="topLeft"
              title="Setup ecosystems with websites you would like to track"
            >
              <img src="/images/info-light.png" alt="Info Light" />
            </Tooltip>
          </Col>
        </Row>
        <Welcome
          teamId={teamId}
          ecosystems={ecosystems}
          isLoadingGetAllEcosystems={isLoadingAllEcosystemOfUser}
        />
      </MainSection>
      <Footer />
    </>
  );
};

export default WelcomePage;
