import { useQuery } from "react-query";
import axiosClient, { setAccessToken } from "../../client/restApi";

export const useGetWebsitePageStatistics = (
  dateFrom,
  ecosystemId,
  websiteId,
  webpageId,
  isSelected
) => {
  return useQuery(
    [
      "google-analytics",
      "ecosystem",
      ecosystemId,
      "website",
      websiteId,
      "webpage",
      webpageId,
    ],
    () =>
      fetchWebsitePageStatistics(dateFrom, ecosystemId, websiteId, webpageId),
    {
      enabled: isSelected,
    }
  );
};

const fetchWebsitePageStatistics = async (
  dateFrom,
  ecosystemId,
  websiteId,
  webpageId
) => {
  let endpoint = `/ecosystems/${ecosystemId}/website/${websiteId}/webpage/${webpageId}/statistics`;
  if (dateFrom) {
    endpoint += `?dateFrom=${dateFrom}`;
  }

  await setAccessToken();
  const response = await axiosClient.get(endpoint);
  return response?.data?.data || [];
};

export const useGetProductRevenueStatistics = (
  dateFrom,
  ecosystemId,
  websiteId,
  productId,
  isSelected
) => {
  return useQuery(
    [
      "google-analytics",
      "ecosystem",
      ecosystemId,
      "website",
      websiteId,
      "product",
      productId,
    ],
    () =>
      fetchProductRevenueStatistics(dateFrom, ecosystemId, websiteId, productId),
    {
      enabled: isSelected,
    }
  );
};

const fetchProductRevenueStatistics = async (
  dateFrom,
  ecosystemId,
  websiteId,
  productId
) => {
  let endpoint = `/ecosystems/${ecosystemId}/website/${websiteId}/product/${productId}/statistics`;
  if (dateFrom) {
    endpoint += `?dateFrom=${dateFrom}`;
  }

  await setAccessToken();
  const response = await axiosClient.get(endpoint);
  return response?.data?.data || [];
};
