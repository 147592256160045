import { Popover } from "antd";
import React, { useState } from "react";
import WebsitePopoverContent from "./WebsitePopoverContent";
import WebsitePopoverTitle from "./WebsitePopoverTitle";
import "./WebsitePopover.scss";

const WebsitePopover = ({ websiteId, websiteName, ecosystemId, website }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);

  const handlePopoverVisible = (newVisible) => {
    setPopoverVisible(newVisible);
  };

  return (
    <Popover
      content={
        <WebsitePopoverContent
          handlePopoverVisible={handlePopoverVisible}
          websiteId={websiteId}
          ecosystemId={ecosystemId}
        />
      }
      title={
        <WebsitePopoverTitle
          handlePopoverVisible={handlePopoverVisible}
          currentWebsiteName={websiteName}
          websiteId={websiteId}
          ecosystemId={ecosystemId}
          website={website}
        />
      }
      trigger="click"
      open={popoverVisible}
      onOpenChange={handlePopoverVisible}
      placement="bottomRight"
      overlayClassName="website-board-header-popover"
    >
      <i style={{ cursor: "pointer" }} className="fa fa-ellipsis-v"></i>
    </Popover>
  );
};

export default WebsitePopover;
