/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { PostData } from "../services/PostData";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Link, Navigate } from "react-router-dom";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import { format } from "date-fns";
import { CountryDropdown } from "react-country-region-selector";
import { LoggedInStatus } from "../services/LoggedInStatus";

const Payment = ({ location }) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [plan, setPlan] = useState("prod_KZx9eG0uAfKHDL");
  const [interval, setInterval] = useState("year");
  const [messages, _setMessages] = useState("");
  const [paymentIntent, setPaymentIntent] = useState();
  const [loggedIn, setLoggedIn] = useState(false);
  // const [paymentMethod, setPaymentMethod] = useState("");

  const [signupValues, setSignupValues] = useState({
    name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  });
  const [errValues, setErrValues] = useState({
    name: "",
    country: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  });
  const [disableBtn, setDisableBtn] = useState(false);
  const [products, setProductId] = useState([]);
  const [priceArray, setPriceArray] = useState([]);
  const [priceId, setPriceId] = useState("");
  const [priceShowUp, setPriceShowUp] = useState(0);
  const [percent, setPercent] = useState(15);
  const [trial, setTrial] = useState(true);
  const [numberTrialDays, setNumberTrialDays] = useState();
  const [trialEndDay, setTrialEndDay] = useState("");
  const [country, setCountry] = useState(null);
  const [stripe_id, setStripe_id] = useState(null);
  const selectPlan = (e) => {
    setPlan(e.target.value);
  };

  const selectInterval = (e) => {
    setInterval(e.target.value);
  };

  useEffect(() => {
    const postOptions = {
      postRoute: "/get-plans",
      method: "GET",
      token: true,
    };
    PostData(postOptions).then((result) => {
      const products = result.products;
      const prices = result.prices;
      setProductId(products);
      setPriceArray(prices);
      let price = prices.find(
        (w) => w.product === plan && w.recurring.interval === interval
      ).unit_amount;
      let priceId = prices.find(
        (w) => w.product === plan && w.recurring.interval === interval
      ).id;
      setPriceShowUp(price);
      getPercent(products);
      getTrial(prices);
      setPriceId(priceId);
    });
  }, []);

  useEffect(() => {
    if (priceArray.length > 0) {
      let price = priceArray.find(
        (w) => w.product === plan && w.recurring.interval === interval
      ).unit_amount;
      let priceId = priceArray.find(
        (w) => w.product === plan && w.recurring.interval === interval
      ).id;
      setPriceShowUp(price);
      getPercent(products);
      getTrial(priceArray);
      setPriceId(priceId);
    }
  }, [plan, interval]);

  useEffect(() => {
    const postOptions = {
      postRoute: "/user",
      method: "GET",
      token: true,
    };
    PostData(postOptions).then((result) => {
      const stripe_id = result.data.stripe_id;
      if (stripe_id !== null) {
        setStripe_id(true);
      } else {
        setStripe_id(false);
      }
    });
  }, []);

  useEffect(() => {
    let li = LoggedInStatus();
    setLoggedIn(li);
  }, []);

  const getTrialEndDate = (trialDays) => {
    var date = new Date(Date.now() + trialDays * 24 * 60 * 60 * 1000);
    var TrialEndDate = format(new Date(date), "MMM d, yyyy");
    setTrialEndDay(TrialEndDate);
  };

  const getPercent = (products) => {
    let productName = products.find((w) => w.id === plan).name;
    if (interval === "year") {
      if (productName === "Starter package") {
        setPercent(10);
      } else if (productName === "Pro package") {
        setPercent(15);
      } else {
        setPercent(20);
      }
    }
  };

  const getTrial = (prices) => {
    let trial = prices.find((w) => w.product === plan).recurring
      .trial_period_days;
    if (trial === null) {
      setTrial(false);
      setNumberTrialDays(trial);
    } else {
      setTrial(true);
      getTrialEndDate(trial);
      setNumberTrialDays(trial);
    }
  };

  const getPriceForMonth = () => {
    var priceForMonth;
    if (interval === "year") {
      priceForMonth = (priceShowUp / 100 / 12).toFixed(2);
    } else {
      priceForMonth = (priceShowUp / 100).toFixed(2);
    }
    return priceForMonth;
  };

  //sub
  const setMessage = (message) => {
    _setMessages(`${messages}\n\n${message}`);
  };

  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    return "";
  }

  function signupChange(e) {
    setSignupValues({ ...signupValues, [e.target.name]: e.target.value });
    setDisableBtn(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisableBtn(true);
    // createPaymentMethod();
    let errors = false;
    const errList = {
      name: "",
      country: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
    };
    if (/^([a-zA-Z0-9 _-]+)$/.test(signupValues.name)) {
      errList.name = "";
    } else {
      errList.name = "This field is invalid";
      errors = true;
    }
    if (country !== "") {
      errList.country = "";
    } else {
      errList.country = "This field is invalid";
      errors = true;
    }
    if (signupValues.address1 !== "") {
      errList.address1 = "";
    } else {
      errList.address1 = "This field is invalid";
      errors = true;
    }
    setErrValues(errList);
    if (!errors) {
      if (clientSecret === null) {
        const userDetails = {
          name: signupValues.name,
          country: country,
          address1: signupValues.address1,
          address2: signupValues.address2,
          state: signupValues.state,
          city: signupValues.city,
          zip: signupValues.zip,
        };
        const postOptions = {
          postRoute: "/create-customer",
          method: "POST",
          token: true,
          formData: userDetails,
        };
        PostData(postOptions).then((result) => {
          const customerStripeId = result.data.stripe_id;
          createSubscription(customerStripeId);
          createPaymentMethod();
        });
      } else {
        confirmCard(clientSecret);
      }
    }
  };

  const createPaymentMethod = async () => {
    const cardElement = elements.getElement(CardElement);

    stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
      })
      .then(function (result) {
        const postOptions = {
          postRoute: "/attach-payment",
          method: "POST",
          token: true,
          formData: {
            payment_method: result.paymentMethod.id,
          },
        };
        PostData(postOptions).then((result) => {});
      });
  };

  const createSubscription = (customerStripeId) => {
    const postOptions = {
      postRoute: "/create-subscription",
      method: "POST",
      token: true,
      formData: {
        priceId: priceId,
        customerId: customerStripeId,
        name: plan,
        interval: interval,
        trialDays: numberTrialDays,
      },
    };
    PostData(postOptions).then((result) => {
      const clientSecretCode = result.clientSecret;
      setClientSecret(clientSecretCode);
      confirmCard(result.clientSecret);
    });
  };

  const confirmCard = async (clientSecret) => {
    const cardElement = elements.getElement(CardElement);

    let { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: signupValues.name,
          },
        },
      }
    );

    if (error) {
      setDisableBtn(false);
      setMessage(error.message);
      return;
    }
    setPaymentIntent(paymentIntent);
  };

  // const attachPaymentMethod = (payment_method) => {
  //   const postOptions = {
  //     postRoute: "/attach-payment",
  //     method: "POST",
  //     token: true,
  //     formData: {
  //       payment_method: payment_method,
  //     },
  //   };
  //   PostData(postOptions).then((result) => {});
  // };

  const updateLogin = () => {
    setLoggedIn(true);
  };

  const logout = () => {
    setLoggedIn(false);
  };

  // if (loggedIn === false || loggedIn === "exit" || loggedIn === undefined) {
  //   return <Navigate to={"/"} />;
  // }

  if (stripe_id === true) {
    return <Navigate to={"/welcome"} />;
  }

  if (paymentIntent && paymentIntent.status === "succeeded") {
    return (
      <Fragment>
        <Header updateLogin={updateLogin} logout={logout} loggedIn={loggedIn} />
        <section className="section home-content has-background-light-grey">
          <div className="container">
            <div className="box max-width-box">
              <h4 className="subtitle is-4 dropdown-seprate has-text-centered">
                Create your Account
              </h4>
              <div className="content">
                <div className="title is-3 has-text-weight-bold has-text-success has-text-centered mt-6 mb-6">
                  CONGRATULATIONS!
                </div>
                <div className="subtitle is-4 has-text-centered mb-6">
                  Your account has been created
                </div>
              </div>
              <div className="has-text-right">
                <Link to={"/welcome"}>Let's get started</Link>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
  return (
    <Fragment>
      <Header updateLogin={updateLogin} logout={logout} loggedIn={loggedIn} />
      <form onSubmit={handleSubmit}>
        <section className="section home-content has-background-light-grey">
          <div className="container">
            <div className="box max-width-box">
              <h4 className="subtitle is-4 has-text-centered">
                Payment Details
              </h4>
              <hr />
              <div className="content">
                <div className="field">
                  <div className="select width-plan-select ">
                    <select value={plan} onChange={selectPlan}>
                      {products.map((ws, i) => (
                        <option key={i} value={ws.id}>
                          {ws.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <hr />
                <div className="columns">
                  <div className="column">
                    <div className="field">
                      <div className="select">
                        <select defaultValue="year" onChange={selectInterval}>
                          <option value="month">Billed Monthly</option>
                          <option value="year">Billed Annually</option>
                        </select>
                      </div>
                    </div>
                    {interval === "year" && (
                      <div className="has-text-blue has-text-weight-semibold is-italic">
                        You are saving {percent}%!
                      </div>
                    )}
                  </div>
                  <div className="column">
                    <div className="has-text-right">
                      <span className="content is-large">
                        ${getPriceForMonth()}*
                      </span>
                      <span className="content is-small">/mo</span>
                    </div>
                  </div>
                </div>
                <hr />
                <Fragment>
                  <div className="field">
                    <label>Name on card</label>
                    <p className="control">
                      <input
                        className="input"
                        type="text"
                        name="name"
                        placeholder="John Doe"
                        onChange={signupChange}
                      />
                    </p>
                    <p className="help is-danger">{errValues.name}</p>
                  </div>
                  <div className="field">
                    <div className="mb-3">
                      <label>Card number</label>
                    </div>
                    <div className="control">
                      <CardElement
                        className="border-card"
                        options={{ hidePostalCode: true }}
                      />
                    </div>
                    <p className="help is-danger">{messages}</p>
                  </div>

                  <div className="field">
                    <div className="mb-3">
                      <label>Country</label>
                    </div>
                    <div className="control">
                      <CountryDropdown
                        className="border-country"
                        name="country"
                        value={country}
                        onChange={setCountry}
                      />
                    </div>
                    <p className="help is-danger">{errValues.country}</p>
                  </div>
                  <div className="field">
                    <label>Address 1</label>
                    <p className="control">
                      <input
                        className="input"
                        type="text"
                        name="address1"
                        onChange={signupChange}
                      />
                    </p>
                    <p className="help is-danger">{errValues.address1}</p>
                  </div>
                  <div className="field">
                    <label>Address 2</label>
                    <p className="control">
                      <input
                        className="input"
                        type="text"
                        name="address2"
                        onChange={signupChange}
                      />
                    </p>
                    <p className="help is-danger">{errValues.address2}</p>
                  </div>
                  <div className="field">
                    <label>City/Suburb</label>
                    <p className="control">
                      <input
                        className="input"
                        type="text"
                        name="city"
                        onChange={signupChange}
                      />
                    </p>
                    <p className="help is-danger">{errValues.city}</p>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <label>State</label>
                      <p className="control is-expanded">
                        <input
                          className="input"
                          type="text"
                          name="state"
                          onChange={signupChange}
                        />
                      </p>
                      <p className="help is-danger">{errValues.state}</p>
                    </div>
                    <div className="field">
                      <label>Zip/Postcode</label>
                      <p className="control is-expanded">
                        <input
                          className="input"
                          type="text"
                          name="zip"
                          onChange={signupChange}
                        />
                      </p>
                      <p className="help is-danger">{errValues.zip}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="columns">
                    <div className="column">
                      <div className="field">
                        <label>Total Billed</label>
                      </div>
                    </div>
                    <div className="column">
                      <div className="has-text-right">
                        <span className="content is-large">
                          ${(priceShowUp / 100).toFixed(2)}*
                        </span>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="is-size-7 terms-privacy mt-4">
                    *Plus applicable taxes
                  </div>
                  <div className="is-size-7 terms-privacy mt-4">
                    {trial ? (
                      <p>
                        You consent to get access to Datanoodle subscription
                        immediately, and if you don’t cancel before the trial
                        ends on {trialEndDay}, you will automatically be charged
                        the subscription fee every month or year until you
                        cancel. You will not be entitled to a refund on
                        cancellation. Full terms and conditions can be found{" "}
                        <a
                          href="https://datanoodle.com/policy/terms-of-service.html"
                          target="_blank"
                          rel="noreferrer"
                        >
                          here
                        </a>
                        .
                      </p>
                    ) : (
                      <p>
                        You consent to get access to Datanoodle subscription
                        immediately, and will automatically be charged the
                        subscription fee every month or year until you cancel.
                        You will not be entitled to a refund on cancellation.
                        Full terms and conditions can be found{" "}
                        <a
                          href="https://datanoodle.com/policy/terms-of-service.html"
                          target="_blank"
                          rel="noreferrer"
                        >
                          here
                        </a>
                        .
                      </p>
                    )}
                  </div>
                  <div className="buttons is-centered mt-5">
                    <button
                      className="button is-success is-rounded"
                      disabled={disableBtn}
                    >
                      {trial ? (
                        <span>
                          START TRIAL, PAY AFTER {numberTrialDays} DAYS
                        </span>
                      ) : (
                        <span>COMPLETE YOUR PURCHASE</span>
                      )}
                    </button>
                  </div>
                </Fragment>
              </div>
            </div>
          </div>
        </section>
      </form>
      <Footer />
    </Fragment>
  );
};

export default Payment;
