export function calculatePriceChange(oldPrice, newPrice) {
  if (oldPrice === 0) {
    return "0";
  }
  if (newPrice >= oldPrice) {
    return "+" + (((newPrice - oldPrice) / oldPrice) * 100).toFixed(2);
  } else if (newPrice < oldPrice) {
    return "-" + (((oldPrice - newPrice) / oldPrice) * 100).toFixed(2);
  }
}
