import Cookies from "universal-cookie";
import { PostData } from "./PostData";

export function LoggedInStatus(page = null) {
  // Get the saved session
  let sessInfo = sessionStorage.getItem("DataNoodleSession");
  if (sessInfo !== null) {
    sessInfo = JSON.parse(atob(sessInfo));
  }
  let refresh = false;

  if (sessInfo !== null) {
    // Check if it's time to refresh the token
    var exp = Date.parse(sessInfo.data.expires_at);
    var utcNow = new Date().getTime();
    utcNow -= 1800000; // Half an hour in case of incorrect time in browser or server
    var remaining = exp - utcNow;
    if (remaining < 3600000) {
      // Less than 1 hour
      refresh = true;
    }
    // console.log("time remaining in token: " + remaining);
    // console.log("session exp: " + exp);
  }
  if (page === "home") {
    refresh = false;
  }

  if (sessInfo !== null && refresh) {
    // console.log("we have the session from sessionStorage but it needs refreshing");
    const postOptions = {
      postRoute: "/oauth/refresh_token",
      method: "POST",
      token: true,
      postData: {
        refresh_token: sessInfo.data.refresh_token,
      },
    };
    PostData(postOptions).then((result) => {
      if (result.success === true) {
        const cookies = new Cookies();
        let udStr = JSON.stringify(result);
        udStr = btoa(udStr);
        cookies.set("DataNoodleSession", udStr, { path: "/" });
        sessionStorage.setItem("DataNoodleSession", udStr);
        // console.log("successfully reset the session using sessionStorage");
        return true;
      } else {
        // console.log("session expired 1");
        sessionStorage.clear();
        const cookies = new Cookies();
        cookies.remove("DataNoodleSession");
        return "exit";
      }
    });
  }

  if (sessInfo !== null && !refresh) {
    // console.log("we have the session from sessionStorage and it doesn't need refreshing");
    return true;
  } else if (page !== "home") {
    const cookies = new Cookies();
    let cookieSessStr = cookies.get("DataNoodleSession");
    // console.log('cookieSessStr ' + cookieSessStr);
    if (cookieSessStr !== null && cookieSessStr !== undefined) {
      // console.log("attempting to use the cookie to refresh the session");
      const css = JSON.parse(atob(cookieSessStr));
      // Check if the session has expired
      var exp2 = Date.parse(css.data.expires_at);
      var utcNow2 = new Date().getTime();
      utcNow2 -= 1800000; // Half an hour in case of incorrect time in browser or server
      var remaining2 = exp2 - utcNow2;
      if (remaining2 < 3600000) {
        // Less than 1 hour
        refresh = true;
      }
      // console.log("time remaining in token: " + remaining2);
      if (refresh) {
        const postOptions = {
          postRoute: "/oauth/refresh_token",
          method: "POST",
          token: true,
          postData: {
            refresh_token: css.data.refresh_token,
          },
        };
        PostData(postOptions).then((result) => {
          if (result.success === true) {
            const newCookie = new Cookies();
            let udStr = JSON.stringify(result);
            udStr = btoa(udStr);
            newCookie.set("DataNoodleSession", udStr, { path: "/" });
            sessionStorage.setItem("DataNoodleSession", udStr);
            // console.log("successfully reset the session using the cookie");
            return true;
          } else {
            // console.log("session expired 2");
            sessionStorage.clear();
            const cookies = new Cookies();
            cookies.remove("DataNoodleSession");
            return "exit";
          }
        });
      } else {
        sessionStorage.setItem("DataNoodleSession", cookieSessStr);
        // console.log("set the session cookie from sessionStorage");
        return true;
      }
    } else {
      // console.log("session expired 3");
      sessionStorage.clear();
      const cookies = new Cookies();
      cookies.remove("DataNoodleSession");
      // console.log('page: ' + page);
      if (page === "home") {
        // console.log('returning false');
        return false;
      } else {
        // console.log('redirecting');
        return "exit";
      }
    }
  }
}
