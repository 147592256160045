import { Button, Row, Space, Table, Col } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllProductsOfEcosystemQuery } from "../../../../utils/Queries/ProductQueries";
import { capitalizeFirstLetter } from "../../../../utils/StringUtil";
import {
  eraseBorderActiveRow,
  drawBorderActiveRow,
  eraseAllBorderActiveRow,
  getHeaderFontSizeBy,
} from "../../../../utils/TableUtil";
import "./TableContent.scss";
import { format } from "date-fns";
import { getFilterSession } from "../../../../utils/SessionUtil";
import { Link, useLocation } from "react-router-dom";
import {
  FILTER_SESSION_NAME,
  PRODUCT_FILTER_SESSION,
  PRODUCT_VISIBLE_COLUMN_SESSION,
  PRODUCT_FONT_SIZE_SESSION,
} from "../../../../constants/FilterSessionName";
import {
  changeDatePicker,
  checkWebsiteFilter,
  checkTagFilter,
} from "../../../../store/ProductTracking";
import {
  renderTags,
  renderProductChange,
  renderTooltip,
  formatTimeForDataTable,
} from "../../../../utils/TableUtil";
import { useGetEcosystemIdByUuidQuery } from "../../../../utils/Queries/EcosystemQueries";
import Media from "react-media";

const TableContent = ({
  ecosystemId: ecosystemUuid,
  filters,
  visibleColumns,
  fontSize,
}) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [sortField, setSortField] = useState(() => {
    const filterSession = getFilterSession(PRODUCT_FILTER_SESSION);
    if (filterSession !== null) {
      return filterSession.sort.sortField;
    }
    return [];
  });
  const [sortDirection, setSortDirection] = useState(() => {
    const filterSession = getFilterSession(PRODUCT_FILTER_SESSION);
    if (filterSession !== null) {
      return filterSession.sort.sortDirection;
    }
    return [];
  });
  const [isSorting, setIsSorting] = useState(false);

  const [expandedRows, setExpandedRows] = useState([]);
  const [revisionChangeList, setRevisionChangeList] = useState({});

  const location = useLocation();
  const dispatch = useDispatch();

  const { data: ecosystemId } = useGetEcosystemIdByUuidQuery(ecosystemUuid);

  const datePicker = useSelector((state) => state.productTracking.datePicker);
  const websiteFilter = useSelector(
    (state) => state.productTracking.websiteFilter,
  );
  const tagFilter = useSelector((state) => state.productTracking.tagFilter);


  useEffect(() => {
    const filterSession = JSON.parse(sessionStorage.getItem(PRODUCT_FILTER_SESSION));
    if (filterSession) {
      dispatch(changeDatePicker(filterSession.filter.dateFrom));
      dispatch(checkWebsiteFilter(filterSession.filter.website));
      dispatch(checkTagFilter(filterSession.filter.tag));
      setSortField(filterSession.sort.sortField);
      setSortDirection(filterSession.sort.sortDirection);
      setPage(filterSession.pagination.page);
      setPageSize(filterSession.pagination.pageSize);
    }
  }, [dispatch]);


  const tableRowClassName = useMemo(() => "table-row", []);
  const tableExpandRowClassName = useMemo(() => "table-expand-row", []);

  const { data, isLoading } = useGetAllProductsOfEcosystemQuery(
    ecosystemId,
    {
      dateFrom: datePicker,
      website: websiteFilter,
      tag: tagFilter,
    },
    {
      sortField: sortField,
      sortDirection: sortDirection[0] === "ascend" ? ["asc"] : ["desc"],
    },
    {
      page: page,
      pageSize: pageSize,
    },
    filters,
  );

  const handleOnChange = useCallback((pagination, filters, sorter, extra) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);

    if (sorter.order !== undefined && sorter.order !== null) {
      setSortField([sorter.columnKey]);
      setSortDirection(
        sorter.order.includes("ascend") ? ["ascend"] : ["descend"],
      );
      setIsSorting(true); // Update sorting state
    } else if (!isSorting) {
      setSortField(["product_updated_at"]);
      setSortDirection(["descend"]);
    }
  }, [isSorting]);

  const handleCloseOnClick = useCallback(
    (record) => {
      setExpandedRows(expandedRows.filter((row) => row !== record.key));
      eraseBorderActiveRow(
        tableRowClassName,
        tableExpandRowClassName,
        record.key,
      );
    },
    [expandedRows, tableExpandRowClassName, tableRowClassName],
  );

  const colorPalletHistory = useMemo(() => {
    return {
      price: "#2f4cbd",
      Availability: "#e82e8e",
      name: "#ff5f51",
      description: "#8744b8",
      SKU: "#ff3e70",
      currency: "#ffa602",
    };
  }, []);

  const handleViewPageOnClick = useCallback(() => {
    sessionStorage.setItem(
      PRODUCT_FILTER_SESSION,
      JSON.stringify({
        filter: {
          dateFrom: datePicker,
          website: websiteFilter,
          tag: tagFilter,
        },
        sort: {
          sortField: sortField,
          sortDirection: sortDirection,
        },
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      }),
    );
    sessionStorage.setItem(FILTER_SESSION_NAME, PRODUCT_FILTER_SESSION);
    sessionStorage.setItem(
      PRODUCT_VISIBLE_COLUMN_SESSION,
      JSON.stringify(visibleColumns),
    );
    sessionStorage.setItem(PRODUCT_FONT_SIZE_SESSION, fontSize);
  }, [
    datePicker,
    page,
    pageSize,
    sortDirection,
    sortField,
    tagFilter,
    visibleColumns,
    websiteFilter,
    fontSize,
  ]);

  const getSortDirection = useCallback(
    (key) => {
      return sortField[0] === key ? sortDirection[0] : null;
    },
    [sortDirection, sortField],
  );

  const headerFontSize = useMemo(() => {
    return getHeaderFontSizeBy(fontSize);
  }, [fontSize]);

  const columns = useMemo(
    () => [
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>
            Title & URL{" "}
            {renderTooltip(
              "This displays the title of the page along with the page address",
            )}
          </span>
        ),
        key: "website_page",
        className: `has-border-right ${fontSize}`,
        render: (product) => (
          <React.Fragment key={`${product.id}`}>
            <p>
              {product?.title?.length > 50
                ? product?.title?.substring(0, 50) + "..."
                : product?.title}
            </p>
            <a
              href={product?.url}
              className="cell-link"
              target="_blank"
              rel="noreferrer"
            >
              {product?.url?.length > 50
                ? product?.url?.substring(0, 50) + "..."
                : product?.url}
              <span className="icon is-align-items-middle">
                <i className="fa fa-external-link"></i>
              </span>
            </a>
          </React.Fragment>
        ),
        align: "left",
        fixed: true,
        width: 400,
        hideOnMd: true,
      },
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>
            Title & URL{" "}
            {renderTooltip(
              "This displays the title of the page along with the page address",
            )}
          </span>
        ),
        key: "website_page",
        className: `has-border-right ${fontSize}`,
        render: (product) => (
          <React.Fragment key={`${product.id}`}>
            <p>
              {product?.title?.length > 50
                ? product?.title?.substring(0, 50) + "..."
                : product?.title}
            </p>
            <a
              href={product?.url}
              className="cell-link"
              target="_blank"
              rel="noreferrer"
            >
              {product?.url?.length > 50
                ? product?.url?.substring(0, 50) + "..."
                : product?.url}
              <span className="icon is-align-items-middle">
                <i className="fa fa-external-link"></i>
              </span>
            </a>
          </React.Fragment>
        ),
        align: "left",
        width: 450,
        hideOnLg: true,
      },
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>
            Website
          </span>
        ),
        className: `${fontSize}`,
        dataIndex: "website_name",
        key: "website_name",
        sorter: true,
        showSorterTooltip: false,
        width: 250,
        align: "left",
        sortOrder: getSortDirection("website_name"),
      },
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>Edits</span>
        ),
        className: `${fontSize}`,
        dataIndex: "product_num_revisions",
        key: "product_num_revisions",
        align: "left",
        sorter: true,
        showSorterTooltip: false,
        width: 100,
        sortOrder: getSortDirection("product_num_revisions"),
      },
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>Price</span>
        ),
        className: `${fontSize}`,
        dataIndex: "product_revision_price",
        key: "product_revision_price",
        sorter: true,
        showSorterTooltip: false,
        width: 100,
        align: "left",
        sortOrder: getSortDirection("product_revision_price"),
      },
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>
            % change{" "}
            {renderTooltip(
              "Percentage change in price from the previous value",
            )}
          </span>
        ),
        className: `${fontSize}`,
        dataIndex: "product_revision_price_change",
        key: "product_revision_price_change",
        sorter: true,
        showSorterTooltip: false,
        width: 150,
        align: "left",
        render: (productRevisionPriceChange) => (
          <p
            className={
              productRevisionPriceChange > 0
                ? "change-up"
                : productRevisionPriceChange < 0
                  ? "change-down"
                  : ""
            }
          >
            {productRevisionPriceChange === "NA"
              ? productRevisionPriceChange
              : `${Number(productRevisionPriceChange)?.toFixed(2)} %`}
          </p>
        ),
        sortOrder: getSortDirection("product_revision_price_change"),
      },
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>
            Currency
          </span>
        ),
        className: `${fontSize}`,
        dataIndex: "product_revision_currency",
        key: "product_revision_currency",
        sorter: true,
        showSorterTooltip: false,
        width: 150,
        align: "left",
        sortOrder: getSortDirection("product_revision_currency"),
      },
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>
            SKU {renderTooltip("SKU value from the page structured data")}
          </span>
        ),
        className: `${fontSize}`,
        dataIndex: "product_revision_sku",
        key: "product_revision_sku",
        sorter: true,
        showSorterTooltip: false,
        width: 200,
        align: "left",
        sortOrder: getSortDirection("product_revision_sku"),
      },
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>
            Last edited
          </span>
        ),
        className: `${fontSize}`,
        dataIndex: "product_updated_at",
        key: "product_updated_at",
        align: "left",
        render: (text) => <>{formatTimeForDataTable(text)}</>,
        sorter: true,
        showSorterTooltip: false,
        width: 150,
        sortOrder: getSortDirection("product_updated_at"),
      },
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>
            Availability{" "}
            {renderTooltip("Availability value from the page structured data")}
          </span>
        ),
        className: `${fontSize}`,
        dataIndex: "product_revision_availability",
        key: "product_revision_availability",
        sorter: true,
        showSorterTooltip: false,
        width: 150,
        align: "left",
        sortOrder: getSortDirection("product_revision_availability"),
      },
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>
            Duration{" "}
            {renderTooltip(
              "The length of time the availability has been the same value",
            )}
          </span>
        ),
        className: `${fontSize}`,
        dataIndex: "product_last_availability_at",
        key: "product_last_availability_at",
        sorter: true,
        showSorterTooltip: false,
        width: 150,
        align: "left",
        render: (productLastAvailabilityAt) => (
          <>{renderProductChange(productLastAvailabilityAt)}</>
        ),
        sortOrder: getSortDirection("product_last_availability_at"),
      },
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>
            Discovered{" "}
            {renderTooltip("The date Data Noodle first crawled the page")}
          </span>
        ),
        className: `${fontSize}`,
        dataIndex: "product_created_at",
        key: "product_created_at",
        render: (text) => <>{formatTimeForDataTable(text)}</>,
        sorter: true,
        showSorterTooltip: false,
        align: "left",
        width: 150,
        sortOrder: getSortDirection("product_created_at"),
      },
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>
            Brand {renderTooltip("Brand value from the page structured data")}
          </span>
        ),
        className: `${fontSize}`,
        dataIndex: "product_revision_brand",
        key: "product_revision_brand",
        sorter: true,
        showSorterTooltip: false,
        width: 150,
        align: "left",
        sortOrder: getSortDirection("product_revision_brand"),
      },
      {
        title: (
          <span className={`table-header-title ${headerFontSize}`}>
            Vertical tag
          </span>
        ),
        class: `${fontSize}`,
        key: "web_page_tags",
        align: "left",
        showSorterTooltip: false,
        width: 200,
        render: (product) => <>{renderTags(product)}</>,
        sorter: true,
        sortOrder: getSortDirection("web_page_tags"),
      },
    ],
    [fontSize, getSortDirection, headerFontSize],
  );

  const getColumnsLgScreen = useCallback(
    () =>
      columns.filter(({ hideOnLg = false, key }) => {
        return !hideOnLg && visibleColumns[key];
      }),
    [columns, visibleColumns],
  );

  const getColumnsMdScreen = useCallback(
    () =>
      columns.filter(({ hideOnMd = false, key }) => {
        return !hideOnMd & visibleColumns[key];
      }),
    [columns, visibleColumns],
  );

  return (
    <>
      <Media query="(max-width: 992px)">
        <Table
          className="table"
          dataSource={data?.data}
          columns={getColumnsMdScreen()}
          onChange={handleOnChange}
          pagination={{
            pageSize: pageSize,
            position: ["bottomCenter"],
            showSizeChanger: true,
            current: page,
            total: data?.total,
          }}
          scroll={{ x: "max-content" }}
          loading={isLoading}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <Space>
                  <div
                    style={{
                      height: "120px",
                      width: "434px",
                      borderRight: "3px solid #f5f6fa",
                    }}
                  ></div>
                  <Space
                    direction="vertical"
                    size="small"
                    style={{ width: "850px" }}
                    className="expand-data"
                  >
                    {Object.keys(revisionChangeList[record.key]).length > 0 ? (
                      revisionChangeList[record.key].map((product) => (
                        <>
                          <Row
                            align="middle"
                            className="history-change-row"
                            justify="start"
                            wrap={false}
                          >
                            <Col span={3} key={product.created_at}>
                              {format(
                                new Date(product.created_at),
                                "MMM dd yyyy",
                              )}
                            </Col>
                            {Object.entries(product).map(
                              ([fieldName, changeValue]) =>
                                fieldName !== "created_at" && (
                                  <>
                                    <Col
                                      className="field-name"
                                      style={{
                                        backgroundColor:
                                          colorPalletHistory[fieldName],
                                        marginRight: "10px",
                                      }}
                                    >
                                      {fieldName !== "SKU"
                                        ? capitalizeFirstLetter(fieldName)
                                        : fieldName}
                                    </Col>
                                    {fieldName !== "description" && (
                                      <Col style={{ marginRight: "10px" }}>
                                        {changeValue}
                                      </Col>
                                    )}
                                  </>
                                ),
                            )}
                          </Row>

                          <div
                            style={{
                              height: "3px",
                              backgroundColor: "#f5f6fa",
                            }}
                          ></div>
                        </>
                      ))
                    ) : (
                      <p className="text-no-changes">No changes detected yet</p>
                    )}
                    <Space>
                      <Link
                        onClick={handleViewPageOnClick()}
                        to={{
                          pathname: `/ecosystem/${ecosystemUuid}/websites/${record.website_id}/products/${record.id}/revisions/`,
                          state: {
                            prevPath: location.pathname,
                            isSaveSession: true,
                            isFromDetail: true
                          },
                        }}
                      >
                        <Button className="view-button">View Page</Button>
                      </Link>
                      <Button
                        className="close-button"
                        onClick={() => handleCloseOnClick(record)}
                      >
                        Close
                        <i
                          style={{ marginLeft: "10px" }}
                          className="fa fa-times"
                          aria-hidden="true"
                        ></i>
                      </Button>
                    </Space>
                  </Space>
                </Space>
              );
            },
            expandRowByClick: true,
            expandIcon: () => <></>,
            columnWidth: 0,
            expandedRowKeys: expandedRows,
            expandedRowClassName: (record) =>
              `${tableExpandRowClassName}-${record.key}`,
            onExpand: (expanded, record) => {
              eraseAllBorderActiveRow();
              drawBorderActiveRow(
                tableRowClassName,
                tableExpandRowClassName,
                record.key,
                "#25bea0",
              );
            },
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                if (!expandedRows.includes(record.key)) {
                  setExpandedRows([...expandedRows, record.key]);
                }
                const currentRevisionChangeList = revisionChangeList;
                currentRevisionChangeList[record.key] = record?.history;
                setRevisionChangeList(currentRevisionChangeList);
              },
            };
          }}
          rowClassName={(record) => `${tableRowClassName}-${record.key}`}
        />
      </Media>
      <Media query="(min-width: 993px)">
        <Table
          className="table"
          dataSource={data?.data}
          columns={getColumnsLgScreen()}
          onChange={handleOnChange}
          pagination={{
            pageSize: pageSize,
            position: ["bottomCenter"],
            showSizeChanger: true,
            current: page,
            total: data?.total,
          }}
          scroll={{ x: "max-content" }}
          loading={isLoading}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <Space>
                  <div
                    style={{
                      height: "120px",
                      width: "385px",
                      borderRight: "3px solid #f5f6fa",
                    }}
                  ></div>
                  <Space
                    direction="vertical"
                    size="small"
                    style={{ width: "850px" }}
                    className="expand-data"
                  >
                    {Object.keys(revisionChangeList[record.key]).length > 0 ? (
                      revisionChangeList[record.key].map((product) => (
                        <>
                          <Row
                            align="middle"
                            className="history-change-row"
                            justify="start"
                            wrap={false}
                          >
                            <Col span={3} key={product.created_at}>
                              {format(
                                new Date(product.created_at),
                                "MMM dd yyyy",
                              )}
                            </Col>
                            {Object.entries(product).map(
                              ([fieldName, changeValue]) =>
                                fieldName !== "created_at" && (
                                  <>
                                    <Col
                                      className="field-name"
                                      style={{
                                        backgroundColor:
                                          colorPalletHistory[fieldName],
                                        marginRight: "10px",
                                      }}
                                    >
                                      {fieldName !== "SKU"
                                        ? capitalizeFirstLetter(fieldName)
                                        : fieldName}
                                    </Col>
                                    {fieldName !== "description" && (
                                      <Col style={{ marginRight: "10px" }}>
                                        {fieldName === "price"
                                          ? parseFloat(
                                            parseFloat(changeValue).toFixed(2),
                                          )
                                          : changeValue}
                                      </Col>
                                    )}
                                  </>
                                ),
                            )}
                          </Row>

                          <div
                            style={{
                              height: "3px",
                              backgroundColor: "#f5f6fa",
                            }}
                          ></div>
                        </>
                      ))
                    ) : (
                      <p className="text-no-changes">No changes detected yet</p>
                    )}
                    <Space>
                      <Link
                        onClick={handleViewPageOnClick()}
                        to={{
                          pathname: `/ecosystem/${ecosystemUuid}/websites/${record.website_id}/products/${record.id}/revisions/`,
                          state: {
                            prevPath: location.pathname,
                            isSaveSession: true,
                            isFromDetail: true
                          },
                        }}
                      >
                        <Button className="view-button">View Page</Button>
                      </Link>
                      <Button
                        className="close-button"
                        onClick={() => handleCloseOnClick(record)}
                      >
                        Close
                        <i
                          style={{ marginLeft: "10px" }}
                          className="fa fa-times"
                          aria-hidden="true"
                        ></i>
                      </Button>
                    </Space>
                  </Space>
                </Space>
              );
            },
            expandRowByClick: true,
            expandIcon: () => <></>,
            columnWidth: 0,
            expandedRowKeys: expandedRows,
            expandedRowClassName: (record) =>
              `${tableExpandRowClassName}-${record.key}`,
            onExpand: (expanded, record) => {
              eraseAllBorderActiveRow();
              drawBorderActiveRow(
                tableRowClassName,
                tableExpandRowClassName,
                record.key,
                "#25bea0",
              );
            },
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                if (!expandedRows.includes(record.key)) {
                  setExpandedRows([...expandedRows, record.key]);
                }
                const currentRevisionChangeList = revisionChangeList;
                currentRevisionChangeList[record.key] = record?.history;
                setRevisionChangeList(currentRevisionChangeList);
              },
            };
          }}
          rowClassName={(record) => `${tableRowClassName}-${record.key}`}
        />
      </Media>
    </>
  );
};

export default TableContent;
