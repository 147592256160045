import { useQuery } from "react-query";
import axiosClient, { setAccessToken } from "../../client/restApi";

export const useGetAllWebsitesBelongsToEcosystemQuery = (ecosystemId) => {
  return useQuery(
    ["websiteTracking", "websites", ecosystemId],
    fetchAllWebsitesBelongsToEcosystem,
    { enabled: ecosystemId !== undefined }
  );
};

const fetchAllWebsitesBelongsToEcosystem = async ({ queryKey }) => {
  await setAccessToken();
  const ecosystemIdInQueryKeyIndex = 2;
  const response = await axiosClient.get(
    `/ecosystem/${queryKey[ecosystemIdInQueryKeyIndex]}/settings`
  );
  return response.data || [];
};

export const useGetAllWebsitesOfEcosystemQuery = (ecosystemId) => {
  return useQuery(
    ["ecosystem", "websites", ecosystemId],
    async () => await fetchAllWebsitesOfEcosystem(ecosystemId),
    { enabled: ecosystemId !== undefined }
  );
};

const fetchAllWebsitesOfEcosystem = async (ecosystemId) => {
  await setAccessToken();
  const response = await axiosClient.get(`/ecosystems/${ecosystemId}/websites`);
  return response.data.data || [];
};
