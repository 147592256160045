import Cookies from "universal-cookie";
import { PostData } from "./PostData";

export async function LoggedInStatus(page = null) {
  // Get the saved session
  let sessInfo = sessionStorage.getItem("DataNoodleSession");
  if (sessInfo !== null) {
    sessInfo = JSON.parse(atob(sessInfo));
  }
  let refresh = false;
  let attempts = 0; // Counter for refresh token attempts

  if (sessInfo !== null) {
    // Check if it's time to refresh the token
    var exp = Date.parse(sessInfo.data.expires_at);
    var utcNow = new Date().getTime();
    utcNow -= 1800000; // Half an hour in case of incorrect time in browser or server
    var remaining = exp - utcNow;
    if (remaining < 3600000) {
      // Less than 1 hour
      refresh = true;
    }
  }
  if (page === "home") {
    refresh = false;
  }

  const refreshToken = async (postOptions) => {
    try {
      const result = await PostData(postOptions);
      if (result.success === true) {
        const cookies = new Cookies();
        let udStr = JSON.stringify(result);
        udStr = btoa(udStr);
        cookies.set("DataNoodleSession", udStr, { path: "/" });
        sessionStorage.setItem("DataNoodleSession", udStr);
        return true;
      } else {
        attempts++;
        if (attempts >= 2) {
          sessionStorage.clear();
          localStorage.clear();
          const cookies = new Cookies();
          cookies.remove("DataNoodleSession");
          return "exit";
        }
      }
    } catch (error) {
      attempts++;
      if (attempts >= 2) {
        sessionStorage.clear();
        localStorage.clear();
        const cookies = new Cookies();
        cookies.remove("DataNoodleSession");
        return "exit";
      }
    }
  };

  if (sessInfo !== null && refresh) {
    const postOptions = {
      postRoute: "/oauth/refresh_token",
      method: "POST",
      token: true,
      postData: {
        refresh_token: sessInfo.data.refresh_token,
      },
    };
    return await refreshToken(postOptions);
  }

  if (sessInfo !== null && !refresh) {
    return true;
  } else if (page !== "home") {
    const cookies = new Cookies();
    let cookieSessStr = cookies.get("DataNoodleSession");
    if (cookieSessStr !== null && cookieSessStr !== undefined) {
      const css = JSON.parse(atob(cookieSessStr));
      var exp2 = Date.parse(css.data.expires_at);
      var utcNow2 = new Date().getTime();
      utcNow2 -= 1800000;
      var remaining2 = exp2 - utcNow2;
      if (remaining2 < 3600000) {
        refresh = true;
      }
      if (refresh) {
        const postOptions = {
          postRoute: "/oauth/refresh_token",
          method: "POST",
          token: true,
          postData: {
            refresh_token: css.data.refresh_token,
          },
        };
        return await refreshToken(postOptions);
      } else {
        sessionStorage.setItem("DataNoodleSession", cookieSessStr);
        return true;
      }
    } else {
      sessionStorage.clear();
      localStorage.clear()
      const cookies = new Cookies();
      cookies.remove("DataNoodleSession");
      if (page === "home") {
        return false;
      } else {
        return "exit";
      }
    }
  }
}
