import { Modal, Spin } from "antd";
import React, { useRef, useState } from "react";
import "./MessageBoxModal.scss";

const MessageBoxModal = ({ message, videoSrc, title = "title" }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const iframeRef = useRef(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    const iframeRefSrc = iframeRef.current.src;
    iframeRef.current.src = iframeRefSrc;
    setIsModalOpen(false);
  };

  return (
    <>
      <span className="link" onClick={showModal}>
        {message}
      </span>
      <Modal
        wrapClassName="message-box-modal"
        title={title}
        open={isModalOpen}
        onCancel={handleCancel}
        cancelText="Close"
        okButtonProps={{ style: { display: "none" } }}
        closable={false}
      >
        <div style={{ padding: "55.68% 0 0 0", position: "relative" }}>
          <Spin
            className="spinner"
            style={{ position: "absolute", top: "50%", left: "45%" }}
            size="large"
          />
          <iframe
            ref={iframeRef}
            title={title}
            src={videoSrc}
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            onLoad={() => {
              document.querySelector(".spinner").style.display = "none";
            }}
          ></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </Modal>
    </>
  );
};
export default MessageBoxModal;
