import { Input, Modal, Select } from "antd";
import React, { useCallback, useState } from "react";
import { useEditEcosystem } from "../../../../utils/Mutations/EcosystemMutation";
import { industries } from "../../../../constants/EcosystemIndustries";
import { useSelector } from "react-redux";

const { Option } = Select;

const EcosystemEditModal = ({
    isVisible,
    setIsVisible,
    currentEcosystemName,
    ecosystemId,
    ecosystemIndustry,
    ecosystemCurrencyId,
  }) => {
  const [ecosystemName, setEcosystemName] = useState(currentEcosystemName);
  const [selectedCurrency, setSelectedCurrency] = useState(ecosystemCurrencyId);
  const [selectedIndustry, setSelectedIndustry] = useState(ecosystemIndustry);

  const { currencies, loading: CurrencyLoading } = useSelector((state) => state.currencies);

  const mutation = useEditEcosystem();

  const hideModal = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  const handleNameChange = useCallback((e) => {
    setEcosystemName(e.target.value);
  }, []);

  const handleCurrencyChange = useCallback((value) => {
    setSelectedCurrency(value);
  }, []);

  const handleIndustryChange = useCallback((value) => {
    setSelectedIndustry(value);
  }, []);

  const handleOk = useCallback(() => {
    if (ecosystemName.trim() !== "") {
      mutation.mutate({
        ecosystemId: ecosystemId,
        ecosystemName: ecosystemName.trim(),
        ecosystemIndustry: selectedIndustry,
        ecosystemCurrencyId: selectedCurrency,
      });
      hideModal();
    }
  }, [ecosystemName, ecosystemId, hideModal, mutation, selectedIndustry, selectedCurrency]);

  const handleCancel = useCallback(() => {
    hideModal();
  }, [hideModal]);

  return (
    <Modal
      title={<p>Edit Ecosystem</p>}
      open={isVisible}
      onOk={handleOk}
      okText="Save"
      onCancel={handleCancel}
      wrapClassName="ecosystem-edit-modal"
      centered
    >
      <p className="ecosystem-edit-modal-name">Ecosystem Name</p>
      <Input
        className={`ecosystem-edit-modal-input ${
          ecosystemName.trim() === "" ? "invalid-input" : ""
        }`}
        onChange={handleNameChange}
        value={ecosystemName}
      />
      <p className="ecosystem-edit-modal-industry">Select Industry</p>
      <Select
        defaultValue={ecosystemIndustry}
        onChange={handleIndustryChange}
        className="ecosystem-edit-modal-industry-select"
      >
        {Object.entries(industries).map(([label, value]) => (
          <Option key={value} value={value}>
            {label}
          </Option>
        ))}
      </Select>
      <p className="ecosystem-edit-modal-currency">Select Currency</p>
      {CurrencyLoading ? (
        <div>Loading currencies...</div>
      ) : (
        <Select
          defaultValue={ecosystemCurrencyId}
          onChange={handleCurrencyChange}
          className="ecosystem-edit-modal-currency-select"
        >
          {currencies.map((currency) => (
            <Option key={currency.id} value={currency.id}>
              {currency.name}
            </Option>
          ))}
        </Select>
      )}
    </Modal>
  );
};

export default EcosystemEditModal;
