import { useQuery } from "react-query";
import axiosClient, { setAccessToken } from "../../client/restApi";

export const useGetAllWebsiteBusinessType = () => {
  return useQuery(["website-business-types"], fetchAllWebsiteBusinessTypes);
};

const fetchAllWebsiteBusinessTypes = async () => {
  await setAccessToken();
  const response = await axiosClient.get("/website-business-types");
  return response.data || [];
};
