import { Checkbox, Col, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { getFilterSession } from "../../utils/SessionUtil";
import "./MainFilterContent.scss";

const MainFilterContent = ({
  filterValues,
  filterTitle,
  filterDefaultOptionTitle,
  onChange,
}) => {
  const [checkedValues, setCheckedValues] = useState(() => {
    const filterSession = getFilterSession();
    if (filterSession !== null) {
      if (filterTitle === "Websites") {
        return filterSession.filter.website;
      } else if (filterTitle === "Tags") {
        return filterSession.filter.tag;
      }
    }
    return [];
  });
  const [isAllChecked, setIsAllChecked] = useState(() => {
    return checkedValues.length === 0;
  });

  const handleOnCheckAll = useCallback((e) => {
    setCheckedValues([]);
    if (e.target.checked) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }
  }, []);

  const handleOnCheck = useCallback(
    (e) => {
      setIsAllChecked(false);
      if (e.target.checked) {
        setCheckedValues([...checkedValues, e.target.value]);
      } else {
        setCheckedValues(
          [...checkedValues].filter(
            (checkedValue) => checkedValue !== e.target.value
          )
        );
      }
    },
    [checkedValues]
  );

  useEffect(() => {
    onChange(checkedValues);
  }, [checkedValues, filterValues.length, onChange]);

  return (
    <>
      <p className="main-filter-content-title">{filterTitle}</p>
      <div style={{ width: "100%" }}>
        <Row>
          <Col span={24} md={12} xl={8} key={0}>
            <Checkbox
              className="main-filter-content-value"
              value={0}
              onChange={handleOnCheckAll}
              checked={isAllChecked}
            >
              {filterDefaultOptionTitle}
            </Checkbox>
          </Col>
          {filterValues.map((filterValue) => {
            return (
              <Col span={24} md={12} xl={8} key={filterValue.id}>
                <Checkbox
                  value={filterValue.id}
                  className="main-filter-content-value"
                  onChange={handleOnCheck}
                  checked={checkedValues.includes(filterValue.id)}
                >
                  {filterValue.name}
                </Checkbox>
              </Col>
            );
          })}
        </Row>
      </div>
    </>
  );
};

export default MainFilterContent;
