import { useQuery } from "react-query";
import axiosClient, { setAccessToken } from "../../client/restApi";

export const useGetNewPagesQuery = (ecosystemId) => {
  return useQuery(
    ["tracking", "new-pages", ecosystemId],
    () => fetchNewPages(ecosystemId),
    { enabled: ecosystemId !== undefined }
  );
};

const fetchNewPages = async (ecosystemId) => {
  await setAccessToken();
  const response = await axiosClient.get(
    `/ecosystems/${ecosystemId}/dashboard/newpages/chart`
  );
  return response.data.data || [];
};

export const useGetNewProductsQuery = (ecosystemId) => {
  return useQuery(
    ["tracking", "new-products", ecosystemId],
    () => fetchNewProducts(ecosystemId),
    { enabled: ecosystemId !== undefined }
  );
};

const fetchNewProducts = async (ecosystemId) => {
  await setAccessToken();
  const response = await axiosClient.get(
    `/ecosystems/${ecosystemId}/dashboard/newproducts/chart`
  );
  return response.data.data || [];
};

export const useGetNewPagesTableQuery = (ecosystemId, page) => {
  return useQuery(
    ["tracking", "new-pages-table", ecosystemId, page],
    () => fetchNewPagesTable(ecosystemId, page),
    { enabled: ecosystemId !== undefined }
  );
};

const fetchNewPagesTable = async (ecosystemId, page) => {
  await setAccessToken();
  const response = await axiosClient.get(
    `/ecosystems/${ecosystemId}/dashboard/newpages/data?page=${page}&pageSize=10`
  );
  return response.data.data || [];
};

export const useGetNewProductsTableQuery = (ecosystemId, page) => {
  return useQuery(
    ["tracking", "new-products-table", ecosystemId, page],
    () => fetchNewProductsTable(ecosystemId, page),
    { enabled: ecosystemId !== undefined }
  );
};

const fetchNewProductsTable = async (ecosystemId, page) => {
  await setAccessToken();
  const response = await axiosClient.get(
    `/ecosystems/${ecosystemId}/dashboard/newproducts/data?page=${page}&pageSize=10`
  );
  return response.data.data || [];
};

export const useGetPriceChangeChartQuery = (ecosystemId) => {
  return useQuery(
    ["tracking", "new-price-change-chart", ecosystemId],
    () => fetchPriceChangeChart(ecosystemId),
    { enabled: ecosystemId !== undefined }
  );
};

const fetchPriceChangeChart = async (ecosystemId) => {
  await setAccessToken();
  const response = await axiosClient.get(
    `ecosystems/${ecosystemId}/dashboard/pricechanges/chart`
  );

  return response.data.data || [];
};

export const useGetPriceChangeTableQuery = (ecosystemId, page) => {
  return useQuery(
    ["tracking", "new-price-change-table", ecosystemId, page],
    () => fetchPriceChangeTable(ecosystemId, page),
    { enabled: ecosystemId !== undefined }
  );
};

const fetchPriceChangeTable = async (ecosystemId, page) => {
  await setAccessToken();
  const response = await axiosClient.get(
    `ecosystems/${ecosystemId}/dashboard/pricechanges/data?page=${page}&pageSize=10`
  );

  return response.data.data || [];
};

export const useGetAvailabilityChangeChartQuery = (ecosystemId) => {
  return useQuery(
    ["tracking", "new-availability-change-chart", ecosystemId],
    () => fetchAvailabilityChangeChart(ecosystemId),
    { enabled: ecosystemId !== undefined }
  );
};

const fetchAvailabilityChangeChart = async (ecosystemId) => {
  await setAccessToken();
  const response = await axiosClient.get(
    `ecosystems/${ecosystemId}/dashboard/priceavailability/chart`
  );

  return response.data.data || [];
};

export const useGetAvailabilityChangeTableQuery = (ecosystemId, page) => {
  return useQuery(
    ["tracking", "new-availability-change-table", ecosystemId, page],
    () => fetchAvailabilityChangeTable(ecosystemId, page),
    { enabled: ecosystemId !== undefined }
  );
};

const fetchAvailabilityChangeTable = async (ecosystemId, page) => {
  await setAccessToken();
  const response = await axiosClient.get(
    `ecosystems/${ecosystemId}/dashboard/priceavailability/data?page=${page}&pageSize=10`
  );

  return response.data.data || [];
};

export const useGetTotalsQuery = (ecosystemId) => {
  return useQuery(
    ["tracking", "totals", ecosystemId],
    () => fetchTotals(ecosystemId),
    { enabled: ecosystemId !== undefined }
  );
};

const fetchTotals = async (ecosystemId) => {
  await setAccessToken();
  const response = await axiosClient.get(`ecosystems/${ecosystemId}/totals`);

  return response.data.data || [];
};
