import React, { useEffect, useState } from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import { Input, Modal } from "antd";
import { useResetPassword } from "../utils/Mutations/UserMutation";
import { useParams } from "react-router-dom";
import "../css/customs/PasswordResetPage.scss";

const PasswordResetPage = () => {
  const [isModalOpen] = useState(true);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const { token, email } = useParams();

  const { mutate, isError, isSuccess, isLoading } = useResetPassword(
    token,
    email,
    password
  );

  useEffect(() => {
    if (passwordConfirmation !== "") {
      setIsPasswordMatch(password === passwordConfirmation);
    }
  }, [password, passwordConfirmation]);

  const handleOk = () => {
    mutate(token, email, password);
  };

  const handleOnChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleOnChangePasswordConfirm = (e) => {
    setPasswordConfirmation(e.target.value);
  };

  return (
    <>
      <Header pageName={"Home"} />
      <div style={{ flexGrow: 1 }}></div>
      <Modal
        wrapClassName="reset-password-modal"
        title={<p>Reset Password</p>}
        open={isModalOpen}
        onOk={handleOk}
        okText={
          isSuccess ? (
            <a href="/login" style={{ textDecoration: "none" }}>
              Login
            </a>
          ) : (
            "Save password"
          )
        }
        closable={false}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        confirmLoading={isLoading}
        okButtonProps={{
          disabled:
            !isPasswordMatch || password === "" || passwordConfirmation === "",
          style: {
            opacity:
              !isPasswordMatch || password === "" || passwordConfirmation === ""
                ? 0.5
                : 1,
            display: isError ? "none" : "block",
          },
        }}
      >
        {isError ? (
          <>
            <p>Failed!</p>
            <p>Token is expired </p>
          </>
        ) : isSuccess ? (
          <>
            <p>Success!</p>
            <p>Login with your new password</p>
          </>
        ) : (
          <>
            <label className="label" htmlFor="new-password">
              New Password
            </label>
            <div className="control">
              <Input
                id="new-password"
                className={`input mb-3 ${!isPasswordMatch ? "is-danger" : ""}`}
                type="password"
                value={password}
                onChange={handleOnChangePassword}
              />
            </div>
            <label className="label" htmlFor="confirm-password">
              Type Password Again
            </label>
            <div className="control">
              <Input
                id="confirm-password"
                className={`input mb-3 ${!isPasswordMatch ? "is-danger" : ""}`}
                type="password"
                value={passwordConfirmation}
                onChange={handleOnChangePasswordConfirm}
              />
            </div>
          </>
        )}
      </Modal>
      <Footer />
    </>
  );
};
export default PasswordResetPage;
