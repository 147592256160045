import React, { useCallback } from "react";
import { Col, Row } from "antd";
import "./index.scss";

const CustomLegendContent = ({
  colorPallette,
  chartTotal,
  hideLegends,
  setHideLegends,
}) => {
  const handleOnClick = useCallback(
    (index) => {
      if (!hideLegends?.includes(index)) {
        setHideLegends([index, ...hideLegends]);
      } else {
        setHideLegends(
          hideLegends?.filter((legendIndex) => legendIndex !== index)
        );
      }
    },
    [hideLegends, setHideLegends]
  );

  const isHideLegend = useCallback(
    (index) => hideLegends?.includes(index),
    [hideLegends]
  );

  return (
    <>
      <Row align="top" wrap={true}>
        {chartTotal &&
          chartTotal.map((text, index) => (
            <React.Fragment key={index}>
              <Col xs={12} md={12} lg={4} onClick={() => handleOnClick(index)}>
                <Row align="middle">
                  <svg
                    className="recharts-surface"
                    width="14"
                    height="14"
                    viewBox="0 0 32 32"
                    version="1.1"
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                      marginRight: "4px",
                      marginTop: "0px",
                    }}
                  >
                    <path
                      fill={
                        isHideLegend(index) ? "#a5acb4" : colorPallette[index]
                      }
                      cx="16"
                      cy="16"
                      type="circle"
                      className="recharts-symbols"
                      transform="translate(16, 16)"
                      d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"
                    ></path>
                  </svg>
                  <span
                    className={`main-chart-total-number ${isHideLegend(index) ? "is-hide" : ""
                      }`}
                  >
                    {new Intl.NumberFormat("en-US").format(text.total)}
                  </span>
                </Row>
                <Row>
                  <React.Fragment key={text}>
                    <Col span={6}>
                      <div
                        className={`main-chart-total-text ${isHideLegend(index) ? "is-hide" : ""
                          }`}
                      >
                        {text?.label.split(/(?=[A-Z])/).join(" ")}
                      </div>
                    </Col>
                  </React.Fragment>
                </Row>
              </Col>
            </React.Fragment>
          ))}
      </Row>
    </>
  );
};

export default CustomLegendContent;
