import React from "react";
import "./index.scss";
import CreateWebsiteButton from "../../../Button/CreateWebsiteButton";

const EcosystemBoardContentNoWebsite = ({ ecosystems }) => {
  return (
    <div
      className="ecosystem-board-content-no-website"
      style={{ textAlign: "center" }}
    >
      <img
        src="images/no-website-icon.png"
        style={{ marginBottom: "32px" }}
        alt="No ecosystem icon"
      />
      <p className="no-website-message">
        No websites have been added to this ecosystem
      </p>
      <CreateWebsiteButton ecosystems={ecosystems} />
    </div>
  );
};

export default EcosystemBoardContentNoWebsite;
