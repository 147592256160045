import React, { Component, Fragment } from 'react';
import { Navigate } from 'react-router-dom';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import { LoggedInStatus } from '../services/LoggedInStatus';
import Loading from './Animations/Loading';
import { GetEcosystems } from '../services/GetEcosystems';

class NotFound extends Component {

    constructor(props) {
        super(props);
        const eid = parseInt(sessionStorage.getItem('ecosystemId')) ?? null;
        this.state = {
            loggedIn: null,
            loading: true,
            ecosystems: null,
            ecosystemId: eid
        }
    }

    componentDidMount() {
        let li = LoggedInStatus();
        this.setState({
            loggedIn: li
        }, function() {
            if (li !== false && li !== 'exit') {
                this.getAllEcosystems();
            }
        });
    }

    async getAllEcosystems() {
        const response = await GetEcosystems();
        if (response) {
            this.setState({
                ecosystems: response,
                loading: false
            });
        }
    }

    logout = () => {
        this.setState({ loggedIn: false });
    }

    render() {
        if (this.state.loggedIn === false || this.state.loggedIn === 'exit' || this.state.loggedIn === undefined) {
            return (<Navigate to={'/'} />);
        } else if (this.state.loading || this.state.loggedIn === null) {
            return (<Loading pad={200} />);
        } else {
            return (
                <Fragment>
                    <Header
                        updateLogin={this.updateLogin}
                        logout={this.logout}
                        loggedIn={this.state.loggedIn}
                        ecosystems={this.state.ecosystems}
                        ecosystemId={this.state.ecosystemId}
                        pageName={'404 Not Found'}
                    />
                        <section className="section">
                            <div className="container">
                                <h1 className="title">404</h1>
                                <h2 className="subtitle">The page you were looking for was not found :(</h2>
                            </div>
                        </section>
                    <Footer />
                </Fragment>
            );
        }
    }
}

export default NotFound;