import React, { useState } from "react";
import { PostData } from "../../services/PostData";
import Cookies from "universal-cookie";
import "./UserProfileModal.scss";
import { useNavigate } from "react-router-dom";

export function UserProfileModal(props) {
  // State
  let u = JSON.parse(sessionStorage.getItem("user")) ?? null;
  const [user, setUser] = useState(u);

  const history = useNavigate();

  const routeChange = () => {
    let path = "/user/" + user.data.id + "/profile";
    history.push(path);
  };

  function logout() {
    const cookies = new Cookies();
    cookies.remove("DataNoodleSession");
    sessionStorage.clear();
    window.location = "/";
  }

  if (user === null) {
    const postOptions = {
      postRoute: "/user",
      method: "GET",
      token: true,
    };
    PostData(postOptions).then((result) => {
      let res = JSON.stringify(result);
      sessionStorage.setItem("user", res);
      setUser(result);
    });
  }

  return (
    <div className="navbar-item has-dropdown is-hoverable">
      <div className="navbar-link">
        {user !== null && user.data.first_name}{" "}
        {user !== null && user.data.last_name}
      </div>
      <div className="navbar-dropdown is-right">
        <div className="navbar-item login-modal">
          <div className="login-box">
            <div className="tile is-ancestor">
              <div className="tile is-child is-4 p-4">
                <figure className="image">
                  <img
                    src="/images/dn-profile-placeholder.png"
                    className="profile-pic"
                    alt="Profile Pic"
                  />
                </figure>
              </div>
              <div className="tile is-child is-8 pt-4 pr-4 pb-4">
                <p className="is-size-4">
                  {user && user.data.first_name} {user && user.data.last_name}
                </p>
                <div className="has-text-grey">{user && user.data.email}</div>
              </div>
            </div>
            <hr />
            <div className="select-container">
              <button className="button is-primary" onClick={routeChange}>
                View/Edit Profile
              </button>
              <button
                className="button"
                onClick={(e) => {
                  e.target.className = "button is-loading";
                  logout();
                }}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
