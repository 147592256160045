import { useMutation, useQueryClient } from "react-query";
import axiosClient, { setAccessToken } from "../../client/restApi";

export const useCreateEcosystem = () => {
  const queryClient = useQueryClient();
  return useMutation(createEcosystem, {
    onSuccess: () => {
      queryClient.invalidateQueries(["ecosystems"]);
    },
  });
};

const createEcosystem = async ({
  ecosystemName,
  teamId,
  industryName,
  currencyId,
}) => {
  await setAccessToken();
  await axiosClient.post(`ecosystem`, {
    name: ecosystemName,
    industry: industryName,
    team_id: teamId,
    currency_id: currencyId,
  });
};

export const useEditEcosystem = () => {
  const queryClient = useQueryClient();
  return useMutation(editEcosystem, {
    onSuccess: () => {
      queryClient.invalidateQueries(["ecosystems"]);
    },
  });
};

const editEcosystem = async ({ ecosystemId, ecosystemName, industryName, currencyId }) => {
  await setAccessToken();
  await axiosClient.put(`ecosystem/${ecosystemId}`, {
    name: ecosystemName,
    industry: industryName,
    currency_id: currencyId,
  });
};

export const useDeleteEcosystem = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteEcosystem, {
    onSuccess: () => {
      queryClient.invalidateQueries(["ecosystems"]);
    },
  });
};

const deleteEcosystem = async ({ ecosystemId }) => {
  await setAccessToken();
  await axiosClient.delete(`ecosystem/${ecosystemId}`);
};

export const useRefreshEcosystem = (ecosystemId) => {
  const queryClient = useQueryClient();
  return useMutation(() => { }, {
    onSuccess: () => {
      queryClient.invalidateQueries(["ecosystem", "websites", ecosystemId]);
    },
  });
};
