export function NumberTooltip({ active, payload }) {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="quantity">
          {new Intl.NumberFormat("en-US").format(payload[0].value)}
        </p>
      </div>
    );
  }

  return null;
}

export function LabelAndNumberTooltip({ active, payload, label }) {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="quantity">{`${payload[0].value}`}</p>
        <p className="label">{`${label}`}</p>
      </div>
    );
  }

  return null;
}
