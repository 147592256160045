import { Col, Row } from "antd";
import React, { useMemo } from "react";
import ScoreBoard from "./ScoreBoard";
import CustomSpin from "../CustomLoading/CustomSpin";

const ScoreBoardContainer = ({ statisticData }) => {
  const antdGridWidth = useMemo(() => 24, []);

  return (
    <Row
      className="scoreboard-container"
      gutter={[16, { xs: 10, sm: 10 }]}
      align="center"
    >
      {statisticData &&
        Object.entries(statisticData).map(([key, value]) => {
          return (
            <React.Fragment key={key.trim()}>
              <Col
                md={antdGridWidth / Object.entries(statisticData).length}
                sm={24}
                xs={24}
                className="gutter-row scoreboard"
              >
                <ScoreBoard
                  totalNumberText={key}
                  totalNumber={!isNaN(value) ? value : <CustomSpin />}
                  graphData={[]}
                />
              </Col>
            </React.Fragment>
          );
        })}
    </Row>
  );
};

export default ScoreBoardContainer;
