const MainDatePickerValuesConstant = [
  {
    text: "Last 24 hours",
    value: 1,
  },
  {
    text: "Last 7 days",
    value: 7,
  },
  {
    text: "Last 30 days",
    value: 30,
  },
  {
    text: "Last 90 days",
    value: 90,
  },
  {
    text: "Last 6 months",
    value: 180,
  },
  {
    text: "Last 12 months",
    value: 365,
  },
  {
    text: "All-time",
    value: "",
  },
];

export default MainDatePickerValuesConstant;
