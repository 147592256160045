import axios from "axios";
import Cookies from "universal-cookie";

const REQUEST_TIMEOUT = 30000;

const baseURL =
  process.env.REACT_APP_API_SERVER_ENDPOINT || "https://api.dev.datanoodle.com";

export const setAccessToken = async (t) => {
  const cookies = new Cookies();
  let dnSess = await cookies.get("DataNoodleSession");
  if (dnSess !== null && dnSess !== undefined) {
    dnSess = JSON.parse(atob(dnSess));
    dnSess = dnSess.data.access_token;
    axiosClient.defaults.headers.common.Authorization = `Bearer ${dnSess}`;
    return true;
  } else {
    axiosClient.defaults.headers.common.Authorization = `Bearer`;
    return false;
  }
};

const axiosClient = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: REQUEST_TIMEOUT,
});

export default axiosClient;
