import { format } from "date-fns";
import React, { Fragment } from "react";

export const ScoreBoard = (props) => {
  return (
    <Fragment>
      <nav className="level">
        <div className="level-item has-text-centered">
          <div>
            <p className="title">{props.change}</p>
            <p>Total Changes</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="title">{props.statusCode}</p>
            <p>Page Status</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="title is-uppercase">
              {format(new Date(props.last_crawled), "d MMM")}
            </p>
            <p>Last crawled</p>
          </div>
        </div>
      </nav>
    </Fragment>
  );
};
