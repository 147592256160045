import { useQuery } from "react-query";
import axiosClient, { setAccessToken } from "../../client/restApi";

export const useGetAllWebsiteOwners = () => {
  return useQuery(["website-owners"], fetchAllWebsiteOwners);
};

const fetchAllWebsiteOwners = async () => {
  await setAccessToken();
  const response = await axiosClient.get("/website-owners");
  return response.data || [];
};
