import { useMutation } from "react-query";
import axiosClient from "../../client/restApi";

export const useSendResetPasswordMail = (email) => {
  return useMutation(() => sendResetPasswordMail(email));
};

const sendResetPasswordMail = async (email) => {
  const response = await axiosClient.post(`user/forgot-password`, {
    email: email,
  });
  return response.data;
};

export const useResetPassword = (token, email, password) => {
  return useMutation(() => resetPassword(token, email, password));
};

const resetPassword = async (token, email, password) => {
  const response = await axiosClient.post(`user/reset-password`, {
    token,
    email,
    password,
  });
  return response.data;
};

export const useFinishLearn = () => {
  return useMutation((finishLearnData) => finishLearn(finishLearnData));
};

const finishLearn = async (finishLearnData) => {
  let temp = {};
  temp[finishLearnData.learnName] = finishLearnData.value;
  const response = await axiosClient.patch(
    `users/${finishLearnData.id}/finish-learn`,
    temp
  );
  return response.data;
};
