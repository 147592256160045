import React, { useMemo, useCallback } from "react";
import {
  XAxis,
  YAxis,
  Legend,
  Line,
  ResponsiveContainer,
  CartesianGrid,
  LineChart,
  Tooltip,
} from "recharts";
import { Row } from "antd";
import CustomRevisionLegend from "../../CustomChartComponents/CustomRevisionLegend";
import "./TrafficChart.scss";

const TrafficChart = ({ data, tickConfig, legendData, max }) => {
  const colorPallette = useMemo(
    () => [
      "#2e4cbd",
      "#8843b8",
      "#c035a7",
      "#e92f8e",
      "#ff3f70",
      "#ff5f51",
      "#ff8230",
      "#ffa600",
    ],
    []
  );

  const legendContents = useMemo(
    () => [
      {
        id: "organic",
        value: legendData?.total_organic_views,
        color: colorPallette[0],
        label: "Organic",
      },
      {
        id: "paid",
        value: legendData?.total_paid_views,
        color: colorPallette[1],
        label: "Paid",
      },
      {
        id: "direct",
        value: legendData?.total_direct_views,
        color: colorPallette[2],
        label: "Direct",
      },
      {
        id: "email",
        value: legendData?.total_email_views,
        color: colorPallette[3],
        label: "Email",
      },
      {
        id: "referral",
        value: legendData?.total_referral_views,
        color: colorPallette[4],
        label: "Referral",
      },
      {
        id: "social",
        value: legendData?.total_social_views,
        color: colorPallette[5],
        label: "social",
      },
      {
        id: "other",
        value: legendData?.total_unassigned_views,
        color: colorPallette[6],
        label: "Other",
      },
    ],
    [
      colorPallette,
      legendData?.total_direct_views,
      legendData?.total_email_views,
      legendData?.total_organic_views,
      legendData?.total_paid_views,
      legendData?.total_referral_views,
      legendData?.total_unassigned_views,
      legendData?.total_social_views,
    ]
  );

  const renderLegend = useCallback(() => {
    return (
      <div className="legend">
        <Row justify="start">
          {legendContents.map((legendContent) => (
            <CustomRevisionLegend
              key={legendContent.id}
              color={legendContent.color}
              id={legendContent.id}
              value={legendContent.value}
              label={legendContent.label}
            />
          ))}
        </Row>
      </div>
    );
  }, [legendContents]);

  return (
    <ResponsiveContainer height={400} debounce={1}>
      <LineChart data={data} className="traffic-chart">
        <CartesianGrid strokeDasharray="0" vertical={false} stroke="#e6e6e6" />
        <XAxis
          dataKey="date"
          axisLine={false}
          tickLine={false}
          tickMargin={20}
          tick={tickConfig}
          padding={{ left: 10, right: 10 }}
        />
        <YAxis
          width={100}
          axisLine={false}
          tickMargin={20}
          tickLine={false}
          tickCount={5}
          tick={tickConfig}
          domain={[0, max + 4]}
        />
        <Legend align="left" content={renderLegend} />
        <Line
          dataKey="a"
          stroke={colorPallette[0]}
          strokeWidth={3}
          dot={<CustomizedDot />}
        />
        <Line
          dataKey="b"
          stroke={colorPallette[1]}
          strokeWidth={3}
          dot={<CustomizedDot />}
        />
        <Line
          dataKey="c"
          stroke={colorPallette[2]}
          strokeWidth={3}
          dot={<CustomizedDot />}
        />
        <Line
          dataKey="d"
          stroke={colorPallette[3]}
          strokeWidth={3}
          dot={<CustomizedDot />}
        />
        <Line
          dataKey="e"
          stroke={colorPallette[4]}
          strokeWidth={3}
          dot={<CustomizedDot />}
        />
        <Tooltip content={<CustomTooltip />} payload={data} />
      </LineChart>
    </ResponsiveContainer>
  );
};

const CustomizedDot = (props) => {
  const { cx, cy, payload } = props;

  return payload.changes ? (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14.000000 18.000000"
      preserveAspectRatio="xMidYMid meet"
      x={cx - 10}
      y={cy - 20}
      width={20}
      height={20}
      fill="green"
      style={{ zIndex: 9999 }}
    >
      <g
        transform="translate(0.000000,18.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M26 148 c-9 -12 -16 -30 -16 -38 0 -17 50 -100 60 -100 10 0 60 83
60 100 0 30 -30 60 -60 60 -18 0 -34 -8 -44 -22z m64 -24 c11 -12 10 -18 -3
-32 -16 -15 -18 -15 -34 0 -13 14 -14 20 -3 32 16 20 24 20 40 0z"
        />
      </g>
    </svg>
  ) : (
    <></>
  );
};

const CustomTooltip = ({ active, payload }) => {
  // if (active) {
  //   console.log(payload[0].payload["changes"]);
  // }
  if (active && payload && payload.length && payload[0].payload["changes"]) {
    return (
      <div className="traffic-chart-tooltip">
        {payload[0].payload["changes"].split(",").map((item) => (
          <>
            <p>{item}</p>
          </>
        ))}
      </div>
    );
  }
  return <></>;
};

export default TrafficChart;
