import { useMutation, useQueryClient } from "react-query";
import axiosClient, { setAccessToken } from "../../client/restApi";

export const useCreateWebsite = () => {
  const queryClient = useQueryClient();
  return useMutation(createWebsite, {
    onSuccess: () => {
      queryClient.invalidateQueries(["ecosystem", "websites"]);
      queryClient.invalidateQueries(["ecosystems"]);
    },
  });
};

const createWebsite = async ({
  ecosystemId,
  websiteName,
  websiteUrl,
  ownerId,
  businessTypeId,
}) => {
  await setAccessToken();
  await axiosClient.post(`/ecosystem/${ecosystemId}/module`, {
    module_id: 1,
    website: websiteUrl,
    name: websiteName,
    owner_id: ownerId,
    business_type_id: businessTypeId,
  });
};

export const useEditWebsite = (ecosystemId, changeEcosystemId) => {
  const queryClient = useQueryClient();
  return useMutation(editWebsite, {
    onSuccess: () => {
      queryClient.invalidateQueries(["ecosystem", "websites", ecosystemId]);
      queryClient.invalidateQueries([
        "ecosystem",
        "websites",
        changeEcosystemId,
      ]);
    },
  });
};

const editWebsite = async ({
  websiteId,
  websiteName,
  ecosystemId,
  ownerId,
  businessTypeId,
  changeEcosystemId,
}) => {
  await setAccessToken();
  await axiosClient.put(`/ecosystem/${ecosystemId}/website/${websiteId}`, {
    name: websiteName,
    owner_id: ownerId,
    business_type_id: businessTypeId,
    change_ecosystem_id: changeEcosystemId,
  });
};

export const useDeleteWebsite = (ecosystemId) => {
  const queryClient = useQueryClient();
  return useMutation(deleteWebsite, {
    onSuccess: () => {
      queryClient.invalidateQueries(["ecosystem", "websites", ecosystemId]);
      queryClient.invalidateQueries(["ecosystems"]);
    },
  });
};

const deleteWebsite = async ({ websiteId, ecosystemId }) => {
  await setAccessToken();
  await axiosClient.delete(`/ecosystem/${ecosystemId}/website/${websiteId}`);
};
