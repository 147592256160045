import React from "react";
import DataTableItemContainer from "./DataTableItemContainer";
import DataTableTitle from "./Title";
import "./index.scss";
import TableWrapper from "./TableWrapper";

const WebsiteDataTable = ({ title, tags, ecosystemId }) => {
  return (
    <>
      <DataTableItemContainer>
        <DataTableTitle title={title} />
        <TableWrapper tags={tags} ecosystemId={ecosystemId} />
      </DataTableItemContainer>
    </>
  );
};

export default WebsiteDataTable;
