import React, { Fragment } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./SubHeader.scss";

export default function SubHeader(props) {
  let pathname = useLocation().pathname;
  let websitePagesActive = pathname.startsWith(
    "/ecosystem/" + props.ecosystemId + "/webpages"
  )
    ? true
    : false;
  let productsActive = pathname.startsWith(
    "/ecosystem/" + props.ecosystemId + "/products"
  )
    ? true
    : false;
  let settingsActive = pathname.startsWith(
    "/ecosystem/" + props.ecosystemId + "/settings"
  )
    ? true
    : false;

  const breadcrumbs = [];

  // if(props.ecosystemId) {
  //     breadcrumbs.push({
  //         link: '/ecosystem/' + props.ecosystemId + '/settings/',
  //         name: props.ecosystemName
  //     });
  // }
  // if (props.websiteId) {
  //     breadcrumbs.push({
  //         link: '/website-pages/' + props.websiteId,
  //         name: props.websiteName
  //     });
  // }

  if (props.revisionId) {
    breadcrumbs.push({
      link:
        "/website-pages/" + props.websiteId + "/revisions/" + props.revisionId,
      name: props.revisionName,
    });
  }
  const history = useNavigate();

  // const goBack = () => {
  //   history.goBack();
  // };

  return (
    <Fragment>
      <section
        className={
          "section sub-header pt-5" +
          (websitePagesActive || productsActive || settingsActive
            ? " pb-0"
            : " pb-5")
        }
      >
        <div className="container">
          {props.ecosystemName !== null && props.ecosystemName !== undefined && (
            <Fragment>
              <h1 className="has-text-white is-size-3">
                {props.ecosystemName}
              </h1>
              {(websitePagesActive || productsActive || settingsActive) && (
                <div className="nav-subheader pt-4">
                  <Link
                    to={"/ecosystem/" + props.ecosystemId + "/webpages"}
                    className={
                      "nav-subheader-link" +
                      (websitePagesActive ? " is-active" : "")
                    }
                  >
                    Website Changes
                  </Link>
                  <Link
                    to={"/ecosystem/" + props.ecosystemId + "/products"}
                    className={
                      "nav-subheader-link" +
                      (productsActive ? " is-active" : "")
                    }
                  >
                    Products
                  </Link>
                  <Link
                    to={"/ecosystem/" + props.ecosystemId + "/settings"}
                    className={
                      "nav-subheader-link" +
                      (settingsActive ? " is-active" : "")
                    }
                  >
                    Settings
                  </Link>
                </div>
              )}
            </Fragment>
          )}
        </div>
      </section>
      {breadcrumbs.length > 0 && (
        <section className="section breadcrumbs py-1">
          <div className="container">
            <nav className="breadcrumb" aria-label="breadcrumbs">
              <ul>
                {breadcrumbs.map((bc, i) => (
                  <Link
                    to={{
                      pathname: history.location.state.prevPath,
                      state: { prevPath: pathname, isSaveSession: true },
                    }}
                    aria-current={i === breadcrumbs.length - 1 ? "page" : ""}
                    style={{ color: "#000" }}
                  >
                    <li
                      className={
                        i === breadcrumbs.length - 1 ? "is-active" : ""
                      }
                      key={i}
                    >
                      <i
                        className="fa fa-chevron-circle-left cursor-pointer"
                        aria-hidden="true"
                      ></i>
                      &nbsp;&nbsp;
                      {bc.name}
                    </li>
                  </Link>
                ))}
              </ul>
            </nav>
          </div>
        </section>
      )}
    </Fragment>
  );
}
