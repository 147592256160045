import { useMutation } from "react-query";
import axiosClient, { setAccessToken } from "../../client/restApi";

export const useAddGGAnalytic = () => {
  return useMutation(addGGAnalytic);
};

const addGGAnalytic = async ({ ecosystemId, websiteId, analyticId }) => {
  await setAccessToken();
  await axiosClient.post(
    `ecosystems/${ecosystemId}/website/${websiteId}/addAnalytics`,
    {
      analytics_id: analyticId,
    }
  );
};

export const useDeleteGGAnalytic = () => {
  return useMutation(deleteGGAnalytic);
};

const deleteGGAnalytic = async ({ ecosystemId, websiteId }) => {
  await setAccessToken();
  await axiosClient.delete(
    `ecosystems/${ecosystemId}/website/${websiteId}/addAnalytics`
  );
};