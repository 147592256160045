import qs from "qs";
import { useMutation, useQuery } from "react-query";
import axiosClient, { setAccessToken } from "../../client/restApi";
import { isEmptyDate } from "../DateTimeUtil";

export const useGetAllWebpagesStatisticBelongsToEcosystemQuery = (
  ecosystemId,
  filterParams
) => {
  return useQuery(
    ["websiteTracking", "webpages-statistic", ecosystemId, filterParams],
    () =>
      fetchAllWebpagesStatisticBelongsToEcosystem(ecosystemId, filterParams),
    {
      enabled: filterParams.dateFrom.length > 0 && ecosystemId !== undefined,
    }
  );
};

const fetchAllWebpagesStatisticBelongsToEcosystem = async (
  ecosystemId,
  filterParams
) => {
  await setAccessToken();
  const response = await axiosClient.get(
    `/ecosystems/${ecosystemId}/webpages-statistic`,
    {
      params: filterParams,
    }
  );
  return response.data.data || {};
};

export const useGetAllWebpagesOfEcosystemQuery = (
  ecosystemId,
  filterParams,
  sortParams,
  paginationParams,
  dataTableFilterParams,
) => {
  return useQuery(
    [
      "websiteTracking",
      "webpages-data-table",
      ecosystemId,
      filterParams,
      sortParams,
      paginationParams,
      dataTableFilterParams,
    ],
    () =>
      fetchAllWebpagesOfEcosystem(
        ecosystemId,
        filterParams,
        sortParams,
        paginationParams,
        dataTableFilterParams
      ),
    {
      enabled:
        !isEmptyDate(filterParams?.dateFrom) && ecosystemId !== undefined,
    }
  );
};

const fetchAllWebpagesOfEcosystem = async (
  ecosystemId,
  filterParams,
  sortParams,
  paginationParams,
  dataTableFilterParams
) => {
  await setAccessToken();
  const response = await axiosClient.get(
    `/ecosystems/${ecosystemId}/webpages`,
    {
      params: {
        ...filterParams,
        ...sortParams,
        ...paginationParams,
        filters: {
          ...dataTableFilterParams,
        },
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false });
      },
    }
  );
  return response.data.data || [];
};

export const useMutationAddTagToWebpage = () => {
  return useMutation((requestBody) => addTagToWebpage(requestBody));
};

const addTagToWebpage = async (requestBody) => {
  await setAccessToken();
  const response = await axiosClient.post(`/addwebpage-tag`, requestBody);
  return response.data;
};

export const useGetAllStatusCode = (
  ecosystemId,
  filterParams,
  dataTableFilterParams
) => {
  return useQuery(
    [
      "websiteTracking",
      "all-status-codes",
      ecosystemId,
      filterParams,
      dataTableFilterParams,
    ],
    () => fetchAllStatusCodes(ecosystemId, filterParams, dataTableFilterParams),
    {
      enabled:
        !isEmptyDate(filterParams?.dateFrom) && ecosystemId !== undefined,
    }
  );
};

const fetchAllStatusCodes = async (
  ecosystemId,
  filterParams,
  dataTableFilterParams
) => {
  await setAccessToken();
  const response = await axiosClient.get(
    `/ecosystems/${ecosystemId}/webpages/status-codes`,
    {
      params: {
        ...filterParams,
        filters: {
          ...dataTableFilterParams,
        },
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: false });
      },
    }
  );
  return response.data.data || [];
};
