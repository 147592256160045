import { Checkbox, Space, Spin } from "antd";
import React, { useCallback, useState } from "react";
import {
  removeAllWhitespace,
  splitByCapitalLetter,
  toCapitalize,
} from "../../../../utils/StringUtil";
import "./index.scss";

const PopoverContent = ({
  setIsSelect,
  setFilters,
  filters,
  type,
  choices,
  isLoading,
  filterBtn,
}) => {
  const [, setValue] = useState("");

  const handleOnChange = useCallback(
    (checkedValues) => {
      setValue(checkedValues);
      setIsSelect(checkedValues.length !== 0);
      if (checkedValues.length === 0) {
        filterBtn.current.style.border = "";
      }
      const changedFilters = { ...filters };

      changedFilters[removeAllWhitespace(toCapitalize(type))] = {
        value: checkedValues.toString(),
        operator: "isAnyOf",
      };
      if (checkedValues.length === 0) {
        delete changedFilters[removeAllWhitespace(toCapitalize(type))];
      }
      setFilters(changedFilters);
    },
    [filterBtn, filters, setFilters, setIsSelect, type]
  );

  return (
    <Checkbox.Group onChange={handleOnChange}>
      <Space direction="vertical">
        {isLoading ? (
          <Spin />
        ) : (
          <>
            {choices?.length === 0 ? (
              <span className="multiple-filter-default-message">
                Has no filter yet
              </span>
            ) : (
              choices?.map(
                (choice) =>
                  choice !== null &&
                  choice !== undefined && (
                    <Checkbox
                      key={choice}
                      value={choice}
                      className="multiple-filter-checkbox"
                    >
                      <span className="text-filter-title">
                        {splitByCapitalLetter(choice).join(" ")}
                      </span>
                    </Checkbox>
                  )
              )
            )}
          </>
        )}
      </Space>
    </Checkbox.Group>
  );
};

export default PopoverContent;
