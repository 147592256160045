import { Button, Card, Input, Modal, Space } from "antd";
import React, { useCallback, useState } from "react";
import "./index.scss";
import { useCreateTag, useGetAllTagsBelongsToEcosystemQuery } from "../../utils/Queries/TagQueries";
import TagCardExtra from "./TagCardExtra";

const VerticalTagContent = ({ ecosystemId }) => {
  const [tagName, setTagName] = useState("");
  const [allTitleWords, setAllTitleWords] = useState("");
  const [anyTitleWords, setAnyTitleWords] = useState("");
  const [noTitleWords, setNoTitleWords] = useState("");
  const [allURLWords, setAllURLWords] = useState("");
  const [anyURLWords, setAnyURLWords] = useState("");
  const [noURLWords, setNoURLWords] = useState("");
  const [pagePath, setPagePath] = useState("");
  const [isModalCreateTagVisible, setIsModalCreateTagVisible] = useState(false);
  const { mutate: createTag } = useCreateTag();
  const [errors, setErrors] = useState({});

  const { data: allTagsOfCurrentEcosystem, isLoading } =
    useGetAllTagsBelongsToEcosystemQuery(ecosystemId);

  const handleCancelEditTag = useCallback(() => {
    setIsModalCreateTagVisible(false);
    setTagName("");
    setAllTitleWords("");
    setAnyTitleWords("");
    setNoTitleWords("");
    setAllURLWords("");
    setAnyURLWords("");
    setNoURLWords("");
    setPagePath("");
    setErrors({});
  }, []);

  function splitAndPreserveQuotes(input) {
    let parts = [];
    let currentPart = "";
    let quoted = false;

    for (let i = 0; i < input.length; i++) {
      const char = input[i];
      const nextChar = input[i + 1];

      if (char === "\"") {
        quoted = !quoted;
        if (!quoted && nextChar && /\w/.test(nextChar)) {
          parts.push(currentPart + char + nextChar);
          currentPart = "";
          i++;
        } else {
          currentPart += char;
        }
      } else if ((char === "," || char === " ") && !quoted) {
        parts.push(currentPart.trim());
        currentPart = "";
        if (nextChar === " ") {
          i++;
        }
      } else {
        currentPart += char;
      }
    }

    parts.push(currentPart.trim());

    return parts.filter(Boolean);
  }

  const handleCreateTagOnClick = useCallback(() => {
    if (!tagName) {
      setErrors({ name: ["The name field is required."] });
      return;
    }

    setIsModalCreateTagVisible(false);
    const tagData = {
      ecosystemId: ecosystemId,
      newTagName: tagName,
      newAllTitleKeyWords: splitAndPreserveQuotes(allTitleWords),
      newAnyTitleKeyWords: splitAndPreserveQuotes(anyTitleWords),
      newNoTitleKeyWords: splitAndPreserveQuotes(noTitleWords),
      newAllURLKeyWords: splitAndPreserveQuotes(allURLWords),
      newAnyURLKeyWords: splitAndPreserveQuotes(anyURLWords),
      newNoURLKeyWords: splitAndPreserveQuotes(noURLWords),
    };

    if (pagePath && pagePath.length > 0) {
      tagData.newURLStructure = pagePath;
    }

    createTag(tagData);

    setTagName("");
    setAllTitleWords("");
    setAnyTitleWords("");
    setNoTitleWords("");
    setAllURLWords("");
    setAnyURLWords("");
    setNoURLWords("");
    setPagePath("");
    setErrors({});
  }, [createTag, ecosystemId, tagName, allTitleWords, anyTitleWords, noTitleWords, allURLWords, anyURLWords, noURLWords, pagePath]);

  const handleOnChange = useCallback((e, setState) => {
    setState(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, [e.target.name]: [] }));
  }, []);

  const showModalCreateTag = useCallback(() => {
    setIsModalCreateTagVisible(true);
  }, []);

  return (
    <>
      <Space direction="vertical">
        <Button
          className="btn-add-tag"
          size="large"
          onClick={showModalCreateTag}
        >
          + New tag
        </Button>
        <Modal
          title={
            <div className="modal-title">
              <h2 className="is-size-4 has-text-weight-normal">Add a Vertical Tag</h2>
              <img src="/images/vertical-tag.jpeg" alt="Vertical Tag Icon" className="modal-icon" />
            </div>
          }
          open={isModalCreateTagVisible}
          onOk={handleCreateTagOnClick}
          okText="Save"
          onCancel={handleCancelEditTag}
          closable={false}
          width={500}
          className="edit-tag-modal"
          centered
        >
          <label className="label">Add a vertical</label>
          <div className="control">
            <Input
              className={`input mb-3 ${errors.name ? "input-error" : ""}`}
              type="text"
              name="name"
              value={tagName}
              onChange={(e) => handleOnChange(e, setTagName)}
              placeholder="My new vertical tag"
            />
            {errors.name && (
              <div className="error-message">{errors.name[0]}</div>
            )}
          </div>
          <div style={{ marginTop: "10px" }}>
            <span style={{ color: "#093159", fontSize: 18, fontWeight: "bold", paddingTop: "5px" }}>
             Use keywords in the page title
            </span>
          </div>
          <div className="form-group">
            <label className="label" style={{ paddingRight: "30px" }}>All of these words</label>
            <div className="control">
              <Input
                className="input"
                type="text"
                value={allTitleWords}
                onChange={(e) => handleOnChange(e, setAllTitleWords)}
                placeholder="separated by commas"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="label" style={{ paddingRight: "20px" }}>Any of these words</label>
            <div className="control">
              <Input
                className="input"
                type="text"
                value={anyTitleWords}
                onChange={(e) => handleOnChange(e, setAnyTitleWords)}
                placeholder="separated by commas"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="label">None of these words</label>
            <div className="control">
              <Input
                className="input"
                type="text"
                value={noTitleWords}
                onChange={(e) => handleOnChange(e, setNoTitleWords)}
                placeholder="separated by commas"
              />
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            <span style={{ color: "#093159", fontSize: 18, fontWeight: "bold", paddingTop: "5px" }}>
              Use keywords in the page URL
            </span>
          </div>
          <div className="form-group">
            <label className="label" style={{ paddingRight: "30px" }}>All of these words</label>
            <div className="control">
              <Input
                className="input"
                type="text"
                value={allURLWords}
                onChange={(e) => handleOnChange(e, setAllURLWords)}
                placeholder="separated by commas"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="label" style={{ paddingRight: "20px" }}>Any of these words</label>
            <div className="control">
              <Input
                className="input"
                type="text"
                value={anyURLWords}
                onChange={(e) => handleOnChange(e, setAnyURLWords)}
                placeholder="separated by commas"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="label">None of these words</label>
            <div className="control">
              <Input
                className="input"
                type="text"
                value={noURLWords}
                onChange={(e) => handleOnChange(e, setNoURLWords)}
                placeholder="separated by commas"
              />
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            <span style={{ color: "#093159", fontSize: 18, fontWeight: "bold", paddingTop: "5px" }}>
              Set by URL Structure
            </span>
          </div>
          <div className="form-group">
            <label className="label">Page Path</label>
            <div className="control">
              <Input
                className="input"
                type="text"
                value={pagePath}
                onChange={(e) => handleOnChange(e, setPagePath)}
                placeholder="/"
              />
            </div>
          </div>
        </Modal>

        <Space size="large" wrap={true}>
          {allTagsOfCurrentEcosystem?.data?.tags?.sort((a, b) => {
            if (a.name.toLowerCase() === "[AUTO] Unassigned".toLowerCase()) return -1;
            if (b.name.toLowerCase() === "[AUTO] Unassigned".toLowerCase()) return 1;
            return 0;
          }).map((tag) => (
            <Card
              key={tag?.id}
              className="tag-card"
              title={tag?.name}
              extra={tag?.name.toLowerCase() === "[AUTO] Unassigned".toLowerCase() ? null : <TagCardExtra tag={tag} />}
              loading={isLoading}
              style={{ width: "300px", height: "200px" }}
            >
              <p className="tag-card-content">
                <span className="tag-page-count">{tag?.webpage_count}</span>{" "}
                Page
                {tag?.webpage_count <= 1 ? "" : "s"} Added
              </p>
            </Card>
          ))}
        </Space>
      </Space>
    </>
  );
};

export default VerticalTagContent;
