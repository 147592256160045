import React, { useState, useEffect, Fragment, useCallback } from "react";
import { Navigate, useParams } from "react-router-dom";
import Header from "../components/Layout/Header";
import SubHeader from "../components/Layout/SubHeader";
import Footer from "../components/Layout/Footer";
import Loading from "../components/Animations/Loading";
import { PostData } from "../services/PostData";
import { GetEcosystems } from "../services/GetEcosystems";
import MessageModal from "../components/Modals/MessageModal";
import "react-datepicker/dist/react-datepicker.css";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import { format } from "date-fns";
import { ScoreBoard } from "../components/Other/ScoreBoard";
import WebsitePageDetailChart from "../components/Chart/WebsitePageDetailChart";
import { LoggedInStatus } from "../services/LoggedInStatus";

const WebpageRevisionPage = () => {
  const { websiteId, webpageId } = useParams();
  const [loggedIn, setLoggedIn] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ecosystems, setEcosystems] = useState(null);
  const [ecosystemId, setEcosystemId] = useState(null);
  const [webpageContentSelected, setWebpageContentSelected] = useState(null);
  const [webpageCurrent, setWebpageCurrent] = useState(null);
  const [webpageContentPrevious, setWebpageContentPrevious] = useState(null);
  const [webpageScreenshot, setWebpageScreenshot] = useState(null);
  const [revisionHistory, setRevisionHistory] = useState([]);
  const [noOfElement, setNoOfElement] = useState(5);
  const [dateRevision, setDateRevision] = useState(null);
  const [ScreenshotSize, setScreenshotSize] = useState(0);
  const [loading1, setLoading1] = useState(false);
  const [tab, setTab] = useState(1);
  const [error, setError] = useState(false);
  const [hasAnalytics, setHasAnalytics] = useState(false);
  const [slice, setSlice] = useState([]);
  const [leftTitle, setLeftTitle] = useState("");

  const newStyles = {
    variables: {
      dark: {
        diffViewerBackground: "#2e303c",
        diffViewerColor: "#FFF",
        addedBackground: "#002200",
        addedColor: "#00e600",
        removedBackground: "#330000",
        removedColor: "#ff0000",
      },
      light: {
        diffViewerBackground: "#FFF",
        diffViewerColor: "#000",
        addedBackground: "#e6ffe6",
        addedColor: "#007700",
        removedBackground: "#ffe6e6",
        removedColor: "#cc0000",
      },
    },
  };

  const getPageContent = useCallback((id) => {
    setLoading1(true);
    const postOptions = {
      postRoute: "/revisions/" + id + "/diff",
      method: "GET",
      token: true,
    };
    PostData(postOptions).then((result) => {
    let current_revision = atob(result.data[0].current_revision);
    let previous_revision = atob(result.data[1].previous_revision);

      let title = leftTitle;
      if (previous_revision !== "") {
        title =
          "Revision: " +
          format(new Date(result.data[0].created_at), "d MMM yyyy");
      } else {
        title =
          "Revision: " +
          format(new Date(result.data[0].created_at), "d MMM yyyy") +
          " (No previous revision to show)";
      }

      setWebpageContentSelected(current_revision);
      setWebpageContentPrevious(previous_revision);
      setDateRevision(result.data[0].created_at);
      setLeftTitle(title);
      setLoading1(false);
    });
  }, [leftTitle]);

  const sliceElements = useCallback((noOfElement) => {
    setSlice(revisionHistory.slice(0, noOfElement));
  }, [revisionHistory]);

  const getLatestRevision = useCallback(() => {
    const postOptions = {
      postRoute: "/webpage/" + webpageId + "/revisions",
      method: "GET",
      token: true,
    };
    PostData(postOptions).then((result) => {
      result.data.map(r => new Date(r.created_at));
      setWebpageCurrent(result);

      getPageScreenshot(result.data[0].id);
      getPageContent(result.data[0].id);
    });
  }, [webpageId, getPageContent]);

  const getChangeHistory = useCallback(() => {
    const postOptions = {
      postRoute: "/webpage/" + webpageId + "/revisions/history",
      method: "GET",
      token: true,
    };
    PostData(postOptions).then((result) => {
      setRevisionHistory(result.data);
      sliceElements(noOfElement);
    });
  }, [webpageId, noOfElement, sliceElements]);

  useEffect(() => {
    const getAllEcosystems = async () => {
      const allEcosystems = await GetEcosystems();
      const ce = parseInt(sessionStorage.getItem("ecosystemId"));
      setEcosystemId(ce);
      setEcosystems(allEcosystems);

      getLatestRevision();
      getChangeHistory();

      allEcosystems.data.forEach((ecosystem) => {
        ecosystem.websites.forEach((website) => {
          if (website.id === parseInt(websiteId)) {
            if (website.analytics_id !== "" && website.analytics_id !== null) {
              setHasAnalytics(true);
            }
          }
        });
      });
    };

    const li = LoggedInStatus();
    setLoggedIn(li);
    if (li !== false && li !== "exit") {
      getAllEcosystems();
    }
  }, [websiteId, getChangeHistory, getLatestRevision]);

  const getPageScreenshot = (id) => {
    const postOptions = {
      postRoute: "/revisions/" + id + "/screenshot",
      method: "GET",
      token: true,
      image: true,
    };
    PostData(postOptions).then((result) => {
      setWebpageScreenshot(URL.createObjectURL(result));
      setLoading(false);
      setScreenshotSize(result.size);
    });
  };

  const seeMore = () => {
    setNoOfElement(noOfElement + 5);
    sliceElements(noOfElement + 5);
  };

  const detailData = (e, id) => {
    getPageScreenshot(id);
    getPageContent(id);
  };

  const logout = () => {
    setLoggedIn(false);
  };

  const errorModalState = (ms = false) => {
    setError(ms);
  };

  const fieldColor = (field) => {
    const monitorTags = {
      "<p>": "#307be4",
      "<ul>": "#9d96eb",
      "<ol>": "#9d96eb",
      "<h1>": "#7488e8",
      "<h2>": "#7488e8",
      "<h3>": "#7488e8",
      "<h4>": "#7488e8",
      "<h5>": "#7488e8",
      "<h6>": "#7488e8",
      "<meta>": "#f0799f",
      "<title>": "#e95e7e",
      "<a>": "#de425b",
      "<img>": "#ff66cc",
      "<table>": "#ffa600",
      "<form>": "#ff873c",
      "<footer>": "#ec6456",
      "<nav>": "#ec6456",
      "<video>": "#99d2ff",
      "<iframe>": "#69cef9",
      "<script>": "#1ac9e9",
    };

    return (
      <span
        style={{
          backgroundColor: `${monitorTags[field]}`,
          padding: "5px 20px",
          color: "#fff",
          borderRadius: "5px",
        }}
      >
        {field}
      </span>
    );
  };

  if (loggedIn === false || loggedIn === "exit" || loggedIn === undefined) {
    return <Navigate to={"/"} />;
  } else if (loading || loggedIn === null) {
    return <Loading pad={200} />;
  } else if (error !== false) {
    return (
      <Fragment>
        <MessageModal
          show={error}
          message={"An error occurred. Please try again later."}
          setState={() => errorModalState(false)}
        />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Header page={"webpageRevision"} loggedIn={loggedIn} logout={logout} />
        <SubHeader ecosystems={ecosystems} loggedIn={loggedIn} revisionId={websiteId} revisionName={webpageCurrent?.data[0]?.title}/>
        <section id="content">
          <div className="container">
            {hasAnalytics && (
              <WebsitePageDetailChart
                data={revisionHistory}
                ecosystemId={ecosystemId}
                websiteId={websiteId}
                webpageId={webpageId}
              />
            )}
            <div className="card my-5">
              <div className="columns">
                <div className="column is-5 ml-3">
                  <div className="box">
                    <ScoreBoard
                      change={webpageCurrent?.data[0]?.webpage?.num_revisions}
                      statusCode={webpageCurrent?.data[0]?.status_code}
                      last_crawled={webpageCurrent?.data[0]?.webpage?.last_crawled_at}
                    />
                  </div>
                  <div className="box">
                    <Fragment>
                      <div className="content">
                        <table className="changeHistory table is-hoverable">
                          <thead>
                            <tr>
                              <th colSpan="2">Changes History</th>
                            </tr>
                          </thead>
                          <tbody>
                            {revisionHistory.length > 0 ? (
                              slice.map((key, i) => (
                                <tr
                                  key={i}
                                  className="productChangeList cursor-pointer"
                                  onClick={(e) => detailData(e, revisionHistory[i].id)}
                                >
                                  <td className="is-italic width-column border-0">
                                    {format(new Date(key.date), "MMM d yyyy")}
                                  </td>
                                  <td className="tdbreak">
                                    {key?.change &&
                                      key?.change?.map((change) => (
                                        <React.Fragment key={change}>
                                          <span className="width-column border-0">
                                            {fieldColor(change)}
                                          </span>
                                        </React.Fragment>
                                      ))}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="2">
                                  <div className="noChange">
                                    <p>There are no changes.</p>
                                  </div>
                                </td>
                              </tr>
                            )}
                            {noOfElement < revisionHistory.length ? (
                              <tr>
                                <td colSpan={2}>
                                  <button className="seemore" onClick={() => seeMore()}>
                                    See More
                                  </button>
                                </td>
                              </tr>
                            ) : null}
                          </tbody>
                        </table>
                      </div>
                    </Fragment>
                  </div>
                </div>

                <div className="column mr-3">
                  <Fragment>
                    <div className="tabs is-small toggle toggle-rounded">
                      <span className="m-2 is-italic">
                        {format(new Date(dateRevision), "MMM d yyyy")}
                      </span>
                      <ul>
                        <li className={tab === 1 ? "is-active" : ""}>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setTab(1);
                            }}
                          >
                            Screenshot
                          </button>
                        </li>
                        <li className={tab === 2 ? "is-active" : ""}>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setTab(2);
                            }}
                          >
                            HTML
                          </button>
                        </li>
                      </ul>
                    </div>
                    {tab === 1 && (
                      webpageScreenshot !== null ? (
                        <div>
                          <img
                            src={
                              ScreenshotSize !== 0
                                ? webpageScreenshot
                                : "/images/placeholder.jpg"
                            }
                            width="100%"
                            alt={
                              webpageScreenshot
                                ? "Screenshot"
                                : "Placeholder"
                            }
                          />
                        </div>
                      ) : (
                        <Loading />
                      )
                    )}
                    {tab === 2 && (
                      <div id="diffView">
                        {!loading1 ? (
                          <ReactDiffViewer
                            oldValue={webpageContentPrevious}
                            newValue={webpageContentSelected}
                            leftTitle={leftTitle}
                            compareMethod={DiffMethod.WORDS}
                            useDarkTheme={true}
                            splitView={false}
                            styles={newStyles}
                          />
                        ) : (
                          <Loading />
                        )}
                      </div>
                    )}
                  </Fragment>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
};

export default WebpageRevisionPage;
