import { useMutation, useQuery } from "react-query";
import axiosClient, { setAccessToken } from "../../client/restApi";
import { useQueryClient } from "react-query";

export const useGetAllTagsBelongsToEcosystemQuery = (ecosystemId) => {
  return useQuery(
    ["websiteTracking", "tags", ecosystemId],
    fetchAllTagsBelongsToEcosystem,
    { enabled: ecosystemId !== undefined },
  );
};

const fetchAllTagsBelongsToEcosystem = async ({ queryKey }) => {
  await setAccessToken();
  const ecosystemIdInQueryKeyIndex = 2;
  const response = await axiosClient.get(
    `/ecosystems/${queryKey[ecosystemIdInQueryKeyIndex]}/tags`,
  );
  return response.data || [];
};

export const useUpdateTag = () => {
  const queryClient = useQueryClient();
  return useMutation(updateTag, {
    onSuccess: () => {
      queryClient.invalidateQueries(["websiteTracking", "tags"]);
    },
  });
};

const updateTag = async ({
                           tagId,
                           newTagName,
                           newAllTitleKeyWords,
                           newAnyTitleKeyWords,
                           newNoTitleKeyWords,
                           newAllURLKeyWords,
                           newAnyURLKeyWords,
                           newNoURLKeyWords,
                           newURLStructure,
                         }) => {
  await setAccessToken();
  const requestData = {
    name: newTagName,
    allTitleKeyWords: newAllTitleKeyWords,
    anyTitleKeyWords: newAnyTitleKeyWords,
    noTitleKeyWords: newNoTitleKeyWords,
    allURLKeyWords: newAllURLKeyWords,
    anyURLKeyWords: newAnyURLKeyWords,
    noURLKeyWords: newNoURLKeyWords,
    urlStructure: newURLStructure,
  };

  Object.keys(requestData).forEach((key) => {
    if (requestData[key] === undefined || (Array.isArray(requestData[key]) && requestData[key].length === 0) || requestData[key] === "") {
      delete requestData[key];
    }
  });
  await axiosClient.put(`ecosystem-tags/${tagId}`, requestData);
};

export const useDeleteTag = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteTag, {
    onSuccess: () => {
      queryClient.invalidateQueries(["websiteTracking", "tags"]);
    },
  });
};

const deleteTag = async (tagId) => {
  await setAccessToken();
  await axiosClient.delete(`ecosystem-tags/${tagId}`);
};

export const useCreateTag = () => {
  const queryClient = useQueryClient();
  return useMutation(createTag, {
    onSuccess: () => {
      queryClient.invalidateQueries(["websiteTracking", "tags"]);
    },
  });
};

const createTag = async ({
                           ecosystemId,
                           newTagName,
                           newAllTitleKeyWords,
                           newAnyTitleKeyWords,
                           newNoTitleKeyWords,
                           newAllURLKeyWords,
                           newAnyURLKeyWords,
                           newNoURLKeyWords,
                           newURLStructure,
                         }) => {
  await setAccessToken();

  const requestData = {
    name: newTagName,
    allTitleKeyWords: newAllTitleKeyWords,
    anyTitleKeyWords: newAnyTitleKeyWords,
    noTitleKeyWords: newNoTitleKeyWords,
    allURLKeyWords: newAllURLKeyWords,
    anyURLKeyWords: newAnyURLKeyWords,
    noURLKeyWords: newNoURLKeyWords,
    urlStructure: newURLStructure,
  };

  Object.keys(requestData).forEach((key) => {
  if (requestData[key] === undefined || (Array.isArray(requestData[key]) && requestData[key].length === 0)) {
    delete requestData[key];
  }
});

  await axiosClient.post(`ecosystems/${ecosystemId}/tags`, requestData);
};
