import { useQuery } from "react-query";
import axiosClient, { setAccessToken } from "../../client/restApi";
import { isEmptyDate } from "../DateTimeUtil";

const ECOSYSTEM_ID_INDEX = 2;
const DATE_FROM_INDEX = 3;
const WEBSITE_FILTER_INDEX = 4;
const TAG_FILTER_INDEX = 5;

export const useGetAllProductRevisionChangesBelongsToEcosystem = (
  ecosystemId,
  dateFrom,
  websiteFilter,
  tagFilter
) => {
  return useQuery(
    [
      "productTracking",
      "product-revision-changes-chart",
      ecosystemId,
      dateFrom,
      websiteFilter,
      tagFilter,
    ],
    fetchAllProductRevisionChangesBelongsToEcosystem,
    {
      enabled: !isEmptyDate(dateFrom),
    }
  );
};

const fetchAllProductRevisionChangesBelongsToEcosystem = async ({
  queryKey,
}) => {
  await setAccessToken();
  const response = await axiosClient.get(
    `/ecosystems/${queryKey[ECOSYSTEM_ID_INDEX]}/products-changes-chart`,
    {
      params: {
        dateFrom: queryKey[DATE_FROM_INDEX],
        website: queryKey[WEBSITE_FILTER_INDEX],
        tag: queryKey[TAG_FILTER_INDEX],
      },
    }
  );
  return response.data.data || {};
};

export const useGetAllProductAvailabilityBelongsToEcosystem = (
  ecosystemId,
  dateFrom,
  websiteFilter,
  tagFilter,
  chartType
) => {
  return useQuery(
    [
      "productTracking",
      "product-prevision-availability-chart",
      ecosystemId,
      dateFrom,
      websiteFilter,
      tagFilter,
    ],
    fetchAllProductAvailabilityBelongsToEcosystem,
    {
      enabled: !isEmptyDate(dateFrom),
    }
  );
};

const fetchAllProductAvailabilityBelongsToEcosystem = async ({ queryKey }) => {
  await setAccessToken();
  const response = await axiosClient.get(
    `/ecosystems/${queryKey[ECOSYSTEM_ID_INDEX]}/products-availability-chart`,
    {
      params: {
        dateFrom: queryKey[DATE_FROM_INDEX],
        website: queryKey[WEBSITE_FILTER_INDEX],
        tag: queryKey[TAG_FILTER_INDEX],
      },
    }
  );
  return response.data.data || {};
};
