import { Button, Col, Input, Radio, Row, Space } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./index.scss";

const PopoverContent = ({
  setIsSelect,
  setFilters,
  filters,
  type,
  filterBtn,
}) => {
  const [operator, setOperator] = useState("");
  const [value, setValue] = useState("");
  const [choice, setChoice] = useState("");

  useEffect(() => {
    setIsSelect(operator !== "");
  }, [setIsSelect, operator]);

  const onChange = useCallback(
    (e) => {
      setOperator(e.target.approach);
      setIsSelect(operator !== "");
      setValue("");
      setChoice(e.target.value);
    },
    [operator, setIsSelect]
  );

  const handleApplyOnClick = useCallback(() => {
    const changedFilters = { ...filters };
    changedFilters[type] = {
      value: value,
      operator: operator,
    };
    setFilters(changedFilters);
  }, [filters, operator, setFilters, type, value]);

  const handleRefreshOnClick = useCallback(() => {
    setOperator("");
    setValue("");
    setChoice("");
    filterBtn.current.style.border = "";

    const changedFilters = { ...filters };
    if (type in changedFilters) {
      delete changedFilters[type];
    }
    setFilters(changedFilters);
  }, [filterBtn, filters, setFilters, type]);

  const handleInputOnChange = useCallback(
    (e) => {
      if (operator === "between") {
        setValue(
          `${document.querySelector("#bottom").value}/${document.querySelector("#top").value
          }`
        );
      } else {
        setValue(e.target.value);
      }
    },
    [operator]
  );

  const keys = useMemo(() => {
    return {
      over: "Over",
      under: "Under",
      between: "Between",
    };
  }, []);

  return (
    <Radio.Group
      onChange={onChange}
      value={choice}
      className="range-filter-popover-content"
    >
      <Space direction="vertical">
        {Object.entries(keys).map(([key, value]) => (
          <Radio key={key} value={value} approach={key}>
            {key !== "between" ? (
              <Space direction="vertical">
                <span className="text-filter-title">{value}</span>
                {operator === key ? (
                  <Input
                    className="text-filter-input"
                    onChange={handleInputOnChange}
                  />
                ) : null}
              </Space>
            ) : (
              <Space direction="vertical">
                <span className="text-filter-title">{value}</span>
                {operator === key ? (
                  <>
                    <Input
                      className="text-filter-input"
                      onChange={handleInputOnChange}
                      id="bottom"
                    />
                    <span className="text-filter-title">and</span>
                    <Input
                      className="text-filter-input"
                      onChange={handleInputOnChange}
                      id="top"
                    />
                  </>
                ) : null}
              </Space>
            )}
          </Radio>
        ))}
        <Row align="middle" justify="space-between">
          <Col>
            <Button className="text-filter-button" onClick={handleApplyOnClick}>
              Apply
            </Button>
          </Col>
          <Col>
            <img
              style={{ cursor: "pointer" }}
              src="/images/icon-refresh.png"
              alt="Icon Refresh"
              onClick={handleRefreshOnClick}
            />
          </Col>
        </Row>
      </Space>
    </Radio.Group>
  );
};

export default PopoverContent;
