import React, { useState, useEffect, Fragment, useCallback } from "react";
import { useParams } from "react-router-dom";
import { LoggedInStatus } from "../services/LoggedInStatus";
import { PostData } from "../services/PostData";
import { GetEcosystems } from "../services/GetEcosystems";
import SubHeaderUser from "../components/Layout/SubHeaderUser";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";

const UserBillingPage = () => {
  const { userId } = useParams();
  sessionStorage.setItem("userId", userId);

  const [loggedIn, setLoggedIn] = useState(null);
  const [ecosystems, setEcosystems] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [product, setProduct] = useState(null);
  const [ecosystemId, setEcosystemId] = useState(parseInt(userId));

  const getBilling = useCallback(() => {
    const postOptions = {
      postRoute: `/user/${userId}/billing`,
      method: "GET",
      token: true,
    };
    PostData(postOptions).then((result) => {
      setProduct(result.data.product);
    });
  }, [userId]);

  const getAllEcosystems = useCallback(async () => {
    const allEcosystems = await GetEcosystems();
    setEcosystemId(parseInt(sessionStorage.getItem("userId")));
    setEcosystems(allEcosystems.data);
    getBilling();
  }, [getBilling]);

  const getThisUser = useCallback(() => {
    const postOptions = {
      postRoute: `/user/${userId}/profile`,
      method: "GET",
      token: true,
    };
    PostData(postOptions).then((result) => {
      setCurrentUser(result);
    });
  }, [userId]);


  useEffect(() => {
    let li = LoggedInStatus();
    setLoggedIn(li);
    if (li !== false && li !== "exit") {
      getAllEcosystems();
      getThisUser();
    }
  }, [getAllEcosystems, getThisUser]);

  const updateLogin = (val) => {
    setLoggedIn(val);
  };

  const logout = () => {
    setLoggedIn(false);
  };

  return (
    <Fragment>
      <Header
        updateLogin={updateLogin}
        logout={logout}
        loggedIn={loggedIn}
        ecosystems={ecosystems}
        ecosystemId={ecosystemId}
      />
      <SubHeaderUser userId={ecosystemId} firstName={currentUser?.data?.first_name} />
      <Fragment>
        <div style={{ backgroundColor: "#f7fbfa", flexGrow: 2 }}>
          <div className="card-profile center-screen">
            <div className="card-content">
              <div className="field boder-line-bottom">
                <label>Your plan</label>
                <div className="control">
                  <span>{product}</span>
                </div>
              </div>
              <div className="field boder-line-bottom">
                <label>
                  You will now be redirected to our payments partner: Stripe
                </label>
                <div className="control">
                  <span></span>
                </div>
                <div className="control">
                  <a
                    href={`https://billing.stripe.com/p/login/test_bIY00c4XmgX0fIs6op?prefilled_email=${currentUser?.data.email}`}
                    className="button lightGreenButton"
                  >
                    View your Billing Plan
                  </a>
                </div>
                <p className="help is-danger"></p>
              </div>
            </div>
          </div>
        </div>
      </Fragment>

      <Footer />
    </Fragment>
  );
};

export default UserBillingPage;
