import React, { useState } from "react";
import "./TableWrapper.scss";
import TableContent from "./TableContent";
import TableHeader from "./TableHeader";
import {
  DEFAULT_WEBPAGE_VISIBLE_COLUMNS,
  DEFAULT_WEBPAGE_FONT_SIZE,
} from "../../../constants/TableColumnConstant";
import {
  WEBPAGE_VISIBLE_COLUMN_SESSION,
  WEBPAGE_FONT_SIZE_SESSION,
} from "../../../constants/FilterSessionName";

const TableWrapper = ({ tags, ecosystemId }) => {
  const [selectedWebsitePageIds, setSelectedWebsitePageIds] = useState([]);
  const [filters, setFilters] = useState({});
  const [fontSize, setFontSize] = useState(() => {
    if (sessionStorage.getItem(WEBPAGE_FONT_SIZE_SESSION)) {
      return sessionStorage.getItem(WEBPAGE_FONT_SIZE_SESSION);
    }
    return DEFAULT_WEBPAGE_FONT_SIZE;
  });

  const [visibleColumns, setVisibleColumns] = useState(() => {
    if (sessionStorage.getItem(WEBPAGE_VISIBLE_COLUMN_SESSION) !== null) {
      return JSON.parse(sessionStorage.getItem(WEBPAGE_VISIBLE_COLUMN_SESSION));
    }
    return DEFAULT_WEBPAGE_VISIBLE_COLUMNS;
  });

  return (
    <div className="website-table-wrapper">
      <TableHeader
        selectedWebsitePageIds={selectedWebsitePageIds}
        setSelectedWebsitePageIds={setSelectedWebsitePageIds}
        tags={tags}
        ecosystemId={ecosystemId}
        setFilters={setFilters}
        filters={filters}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        fontSize={fontSize}
        setFontSize={setFontSize}
      />
      <TableContent
        ecosystemId={ecosystemId}
        selectedWebsitePageIds={selectedWebsitePageIds}
        setSelectedWebsitePageIds={setSelectedWebsitePageIds}
        filters={filters}
        visibleColumns={visibleColumns}
        fontSize={fontSize}
      />
    </div>
  );
};

export default TableWrapper;
