import React from "react";
import ScoreBoardContentContainer from "./ScoreBoardContentContainer";

const ScoreBoard = ({ totalNumber, totalNumberText, graphData }) => {
  return (
    <ScoreBoardContentContainer
      totalNumber={totalNumber}
      totalNumberText={totalNumberText}
      graphData={graphData}
    />
  );
};

export default ScoreBoard;
