import React, { useState } from "react";
import TableContent from "./TableContent";
import TableHeader from "./TableHeader";
import {
  DEFAULT_PRODUCT_VISIBLE_COLUMNS,
  DEFAULT_PRODUCT_FONT_SIZE,
} from "../../../constants/TableColumnConstant";
import {
  PRODUCT_VISIBLE_COLUMN_SESSION,
  PRODUCT_FONT_SIZE_SESSION,
} from "../../../constants/FilterSessionName";
import "./TableWrapper.scss";

const TableWrapper = ({ ecosystemId }) => {
  const [filters, setFilters] = useState({});
  const [visibleColumns, setVisibleColumns] = useState(() => {
    if (sessionStorage.getItem(PRODUCT_VISIBLE_COLUMN_SESSION) !== null) {
      return JSON.parse(sessionStorage.getItem(PRODUCT_VISIBLE_COLUMN_SESSION));
    }
    return DEFAULT_PRODUCT_VISIBLE_COLUMNS;
  });
  const [fontSize, setFontSize] = useState(() => {
    if (sessionStorage.getItem(PRODUCT_FONT_SIZE_SESSION)) {
      return sessionStorage.getItem(PRODUCT_FONT_SIZE_SESSION);
    }
    return DEFAULT_PRODUCT_FONT_SIZE;
  });

  return (
    <div className="product-table-wrapper">
      <TableHeader
        setFilters={setFilters}
        filters={filters}
        ecosystemId={ecosystemId}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        fontSize={fontSize}
        setFontSize={setFontSize}
      />
      <TableContent
        ecosystemId={ecosystemId}
        filters={filters}
        visibleColumns={visibleColumns}
        fontSize={fontSize}
      />
    </div>
  );
};

export default TableWrapper;
