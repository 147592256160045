import React, { useCallback, useMemo } from "react";
import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Legend, Text } from "recharts/lib";
import CustomLegendContent from "../CustomChartComponents/CustomLegendContent";
import "./index.scss";

const SinglePointChartContainer = ({
  chartTotal,
  colorPallette,
  hideLegends,
  setHideLegends,
}) => {
  const renderYTick = useCallback((value, index) => {
    return index % 2 !== 1 ? value : "";
  }, []);

  const tickConfig = useMemo(() => {
    return {
      fontFamily: "'Inter', sans-serif",
      fontSize: "12px",
      color: "#7e8e9f",
    };
  }, []);

  const placeholderData = useMemo(() => [{ x: 48, y: 45, z: 300 }], []);

  return (
    <ResponsiveContainer width="100%" height="90%">
      <ScatterChart
        width={730}
        height={250}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="0" vertical={false} stroke="#e6e6e6" />
        <XAxis
          type="number"
          dataKey="x"
          padding={{ left: 30, right: 30 }}
          axisLine={false}
          tickLine={false}
          tickMargin={20}
          tick={tickConfig}
          domain={[0, 100]}
        />
        <YAxis
          type="number"
          dataKey="y"
          width={100}
          axisLine={false}
          tickMargin={20}
          tickLine={false}
          tickFormatter={renderYTick}
          tick={tickConfig}
          domain={[0, 100]}
        />
        <Tooltip />
        <Legend
          iconType="circle"
          iconColor="#2e4cbd"
          align="left"
          content={
            <CustomLegendContent
              colorPallette={colorPallette}
              chartTotal={chartTotal}
              hideLegends={hideLegends}
              setHideLegends={setHideLegends}
            />
          }
        />
        <Scatter
          data={placeholderData}
          shape={
            <Text
              className="no-data-text"
              style={{ fontFamily: "Inter, sans-serif" }}
            >
              No Data
            </Text>
          }
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export default SinglePointChartContainer;
