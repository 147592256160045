import React, { useCallback } from "react";
import {
  WEBSITE_FILTER,
  TAG_FILTER,
} from "../../constants/MainFilterTypeConstant";
import MainFilterContent from "../MainFilterContent/MainFilterContent";
import "./MainFilterContentContainer.scss";

const MainFilterContentContainer = ({ filterValues, onChange }) => {
  const onWebsiteTrackingWebChange = useCallback(
    (checkValues) => {
      onChange(WEBSITE_FILTER, checkValues);
    },
    [onChange]
  );

  const onWebsiteTrackingTagChange = useCallback(
    (checkValues) => {
      onChange(TAG_FILTER, checkValues);
    },
    [onChange]
  );

  return (
    <div
      className="content-container"
    >
      <MainFilterContent
        filterValues={filterValues.websites.data[1]}
        filterTitle="Websites"
        filterDefaultOptionTitle="All websites"
        onChange={onWebsiteTrackingWebChange}
      />
      <div style={{ height: "20px" }} />
      <MainFilterContent
        filterValues={filterValues.tags.data.tags}
        filterTitle="Tags"
        filterDefaultOptionTitle="All tags"
        onChange={onWebsiteTrackingTagChange}
      />
      <div style={{ height: "20px" }} />
    </div>
  );
};

export default MainFilterContentContainer;
