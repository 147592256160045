import React, { useState } from "react";
import WebsiteEditModal from "../WebsiteEditModal";

const WebsitePopoverTitle = ({
  handlePopoverVisible,
  websiteId,
  currentWebsiteName,
  ecosystemId,
  website,
}) => {
  const [isVisible, setIsVisible] = useState("");

  const handleOnClick = () => {
    handlePopoverVisible(false);
    setIsVisible(true);
  };

  return (
    <>
      <span onClick={handleOnClick}>Edit Website</span>
      <WebsiteEditModal
        setIsVisible={setIsVisible}
        isVisible={isVisible}
        websiteId={websiteId}
        currentWebsiteName={currentWebsiteName}
        ecosystemId={ecosystemId}
        website={website}
      />
    </>
  );
};

export default WebsitePopoverTitle;
