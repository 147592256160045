import { createSlice } from "@reduxjs/toolkit";

export const ProductTrackingSlice = createSlice({
  name: "productTracking",
  initialState: {
    datePicker: "",
    websiteFilter: [],
    tagFilter: [],
  },
  reducers: {
    changeDatePicker: (state, action) => {
      return {
        ...state,
        datePicker: action.payload,
      };
    },
    checkWebsiteFilter: (state, action) => {
      return {
        ...state,
        websiteFilter: action.payload,
      };
    },
    checkTagFilter: (state, action) => {
      return {
        ...state,
        tagFilter: action.payload,
      };
    },
  },
});

export const { changeDatePicker, checkWebsiteFilter, checkTagFilter } =
  ProductTrackingSlice.actions;
export default ProductTrackingSlice.reducer;
