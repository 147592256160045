import React from "react";
import { Col, Row } from "antd";
import "./index.scss";

const DataTableTitle = ({ title }) => {
  return (
    <Row
      className="data-table-product__header"
      align="middle"
      justify="space-between"
    >
      <Col span={4}>
        <span className="data-table-product__header__text">{title}</span>
      </Col>
    </Row>
  );
};

export default DataTableTitle;
