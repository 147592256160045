import React from "react";

export default function SignupFailureModal(props) {
  function closeModal() {
    props.closeSignupFailureModal();
  }

  return (
    <div className={props.signupModal ? "modal is-active" : "modal"}>
      <div className="modal-background" onClick={closeModal}></div>
      <div className="modal-content small-modal modal-padding has-background-white">
        <div className="modal-heading">
          <h2 className="title is-size-4">Something went wrong!</h2>
          <p>
            Unable to create account because email '{props.takenEmail}' is
            already taken.{" "}
          </p>
        </div>
        <div className="buttons is-right">
          <button className="button" aria-label="close" onClick={closeModal}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
