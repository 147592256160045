import React from "react";
import CreateEcosystemButton from "../../../Button/CreateEcosystemButton";
import "./index.scss";

const EcosystemBoardContentEmpty = ({ teamId }) => {
  return (
    <div
      className="ecosystem-board-content-empty"
      style={{ textAlign: "center" }}
    >
      <p className="empty-ecosystem-message">
        You need to setup an ecosystem first
      </p>
      <CreateEcosystemButton backgroundColor="#f0f3f6" teamId={teamId} />
    </div>
  );
};

export default EcosystemBoardContentEmpty;
