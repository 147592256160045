import { Table, Switch } from "antd";
import React, { useMemo, useState, useCallback } from "react";
import EcosystemBoardContentEmpty from "./EcosystemBoardContentEmpty";
import EcosystemBoardContentNoWebsite from "./EcosystemBoardContentNoWebsite";
import "./index.scss";
import WebsitePopover from "../../../components/WebsitePopover";
import GGIntegrationModal from "../../Modals/GGIntegrationModal";
import { useLocation } from "react-router-dom";

const EcosystemBoardContent = ({ ecosystem, ecosystems, teamId }) => {
  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  const generateIsIntegrationModal = () => {
    const temp = {};
    ecosystems?.forEach((ecosystem) => {
      temp[ecosystem.id] = {};
      ecosystem?.websites?.forEach((website) => {
        temp[ecosystem.id][website.id] = false;
      });
    });
    if (query.get("ecoSystem") && query.get("website")) {
      temp[query.get("ecoSystem")] = {};
      temp[query.get("ecoSystem")][query.get("website")] = true;
    }
    return temp;
  };

  const [isIntegrationModalOpen, setIsIntegrationModalOpen] = useState(() =>
    generateIsIntegrationModal()
  );

  const showIntegrationModal = useCallback(
    (websiteId) => {
      console.log("ive been called, show integration modal", websiteId);
      const temp = { ...isIntegrationModalOpen };
      temp[ecosystem.id][websiteId] = true;
      setIsIntegrationModalOpen(temp);
    },
    [ecosystem?.id, isIntegrationModalOpen]
  );

  const handleIntegrationCancel = useCallback(
    (websiteId) => {
      const temp = { ...isIntegrationModalOpen };
      temp[ecosystem.id][websiteId] = false;
      setIsIntegrationModalOpen(temp);
    },
    [ecosystem?.id, isIntegrationModalOpen]
  );

  const ecosystemTableColumns = useMemo(
    () => [
      {
        title: "Website",
        dataIndex: "name",
        key: "name",
        align: "left",
        render: (websiteName) => {
          return <span>{websiteName}</span>;
        },
      },
      {
        title: "URL",
        dataIndex: "homepage",
        key: "url",
        align: "left",
        render: (homepage) => {
          return (
            <a href={homepage} className="cell-link">
              {homepage}
            </a>
          );
        },
      },
      {
        title: "Tracking",
        dataIndex: "homepage",
        key: "tracking",
        align: "center",
        render: (tracking) => {
          return <Switch defaultChecked className="tracking-switch" />;
        },
      },
      {
        title: "URLs",
        dataIndex: "numberUrl",
        key: "numberUrl",
        align: "center",
      },
      {
        title: "Products",
        dataIndex: "numberProduct",
        key: "numberProduct",
        align: "center",
        render: (nbOfProduct) => {
          return nbOfProduct === 0 ? (
            <a
              style={{ color: "#5a8efa" }}
              href="https://datanoodle.com/structured-data.html"
            >
              Not Found
            </a>
          ) : (
            nbOfProduct
          );
        },
      },
      {
        title: "Integration",
        key: "integration",
        align: "center",
        render: (item) => {
          return item.hasAnalytics ? (
            <div className="analytic-success-display">
              <span className="analytic-display-text" onClick={() => showIntegrationModal(item.id)}>
                <img
                  width="20"
                  height="20"
                  src="/images/google-analytics.svg"
                  alt="Google Analytic Icon"
                />

                Analytics
              </span>
              <GGIntegrationModal
                isOpen={isIntegrationModalOpen[ecosystem?.id][item?.id]}
                handleCancel={() => handleIntegrationCancel(item?.id)}
                ecosystemId={ecosystem?.id}
                websiteId={item?.id}
                websiteName={item?.name}
                hasAnalytics={item.hasAnalytics}
              />
            </div>
          ) : (
            <div>
              <span
                onClick={() => showIntegrationModal(item.id)}
                className="integration cell-link"
              >
                + Google Analytics
              </span>
              <GGIntegrationModal
                isOpen={isIntegrationModalOpen[ecosystem?.id][item?.id]}
                handleCancel={() => handleIntegrationCancel(item?.id)}
                ecosystemId={ecosystem?.id}
                websiteId={item?.id}
                websiteName={item?.name}
                hasAnalytics={item.hasAnalytics}
              />
            </div>
          );
        },
      },
      {
        title: "Ownership",
        dataIndex: "owner",
        key: "owner",
        align: "left",
        render: (owner) => {
          return <p>{owner}</p>;
        },
      },
      {
        title: "Industry",
        dataIndex: "industry",
        key: "industry",
        align: "left",
        render: (_, website) => {
          return <p>{ecosystem?.industry}</p>;
        },
      },
      {
        title: "Type",
        dataIndex: "businessType",
        key: "businessType",
        align: "left",
        render: (businessType) => {
          return <p>{businessType}</p>;
        },
      },
      {
        title: "",
        key: "id",
        align: "left",
        render: (website) => (
          <>
            <WebsitePopover
              key={website.id}
              websiteId={website?.id}
              websiteName={website?.name}
              ecosystemId={ecosystem?.id}
              website={website}
            />
          </>
        ),
      },
    ],
    [
      ecosystem?.id,
      ecosystem?.industry,
      handleIntegrationCancel,
      isIntegrationModalOpen,
      showIntegrationModal,
    ]
  );

  return ecosystem ? (
    ecosystem?.websites?.length > 0 ? (
      <Table
        dataSource={ecosystem.websites}
        columns={ecosystemTableColumns}
        pagination={false}
        className="website-table-of-ecosystem"
      ></Table>
    ) : (
      <EcosystemBoardContentNoWebsite ecosystems={ecosystems} />
    )
  ) : (
    <EcosystemBoardContentEmpty teamId={teamId} />
  );
};

export default EcosystemBoardContent;
