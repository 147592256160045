export const toCapitalize = (str) => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 1; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};

export const removeAllWhitespace = (str) => {
  return str.replace(/\s/g, "");
};

export const splitByCapitalLetter = (str) => {
  if (typeof str !== "string") {
    str = str.toString();
  }

  return str.split(/(?=[A-Z])/);
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const isEmpty = (str) => {
  return str === "";
};

export const shortenText = (str) => {
  return str.length >= 25 ? str.substring(0, 25) + "..." : str;
};
