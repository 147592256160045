import React, { useState } from "react";
import { PostData } from "../../services/PostData";
import Cookies from "universal-cookie";

export function UserLogin(props) {
  // State
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [buttonClass, setButtonClass] = useState("button is-success");

  function login() {
    if (username !== "" && password !== "") {
      const loginDetails = {
        email: username,
        password: password,
      };
      const postOptions = {
        postRoute: "/oauth/access_token",
        method: "POST",
        token: false,
        formData: loginDetails,
      };
      PostData(postOptions)
        .then((result) => {
          const responseJSON = result;
          if (responseJSON.success === true) {
            let udStr = JSON.stringify(responseJSON);
            udStr = btoa(udStr);
            sessionStorage.setItem("DataNoodleSession", udStr);
            const cookies = new Cookies();
            cookies.set("DataNoodleSession", udStr, { path: "/" });
            // setSess(responseJSON);
            props.updateLogin();
          } else {
            // Email and password not matched
            setButtonClass("button is-success");
            props.loginError("Login failed. Please try again");
            console.log("error: " + responseJSON.status_code);
            return false;
          }
        })
        .catch((error) => {
          props.loginError(error);
          return false;
        });
    } else {
      // Email and password fields not filled in
      setButtonClass("button is-success");
      props.loginError("Please enter your email and password");
      return false;
    }
  }

  function loginMenuActive() {
    props.loginMenuActive();
  }

  return (
    <div
      className={
        props.loginMenuIsActive
          ? "navbar-item has-dropdown is-active"
          : "navbar-item has-dropdown"
      }
    >
      <div className="navbar-link" onClick={loginMenuActive}>
        Login
      </div>
      <div className="navbar-dropdown is-right">
        <div className="navbar-item login-modal">
          <div className="login-box">
            <h4 className="is-size-5">Login to DataNoodle</h4>
            <hr />
            <div className="field">
              <label className="label">Email address</label>
              <div className="control">
                <input
                  className="input"
                  type="email"
                  name="username"
                  placeholder="name@example.com"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">
                Password{" "}
                <a href="/forgot-password" className="forgot-password-link">
                  Forgot your password?
                </a>
              </label>
              <div className="control">
                <input
                  className="input"
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <label className="checkbox">
                  <input type="checkbox" /> Remember me
                </label>
              </div>
            </div>
            <div className="buttons is-right">
              <button
                className={buttonClass}
                onClick={async (e) => {
                  setButtonClass("button is-success is-loading");
                  const allGood = await login();
                  if (allGood) {
                    props.updateLogin(true);
                  }
                }}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
