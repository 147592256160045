import React, { Fragment, useState } from "react";
import { PostData } from "../../services/PostData";
import FormModal from "../Modals/FormModal";
import DeleteFormModal from "../Modals/DeleteFormModal";

export const TagBox = (props) => {
  const [editTagModalState, setEditTagModalState] = useState(false);
  const [dd, setDd] = useState("dropdown is-right is-pulled-right");

  const [deleteTagModalState, setDeleteTagModalState] = useState(false);

  function editTag(fields) {
    let tagName = fields[0];
    let tagDescription = fields[1];
    if (tagName !== null && tagName.length > 0) {
      const postOptions = {
        postRoute: "/ecosystem-tags/" + props.tag.id,
        method: "PUT",
        type: "x-www-form-urlencoded",
        token: true,
        formData: {
          name: tagName,
          description: tagDescription,
        },
      };
      PostData(postOptions).then((result) => {
        if (result.success) {
          setEditTagModalState(false);
          props.updateEcosystem(true);
        }
      });
    } else {
      // TODO: Handle for validation...
      console.log("Please enter the tag name");
    }
  }

  function deleteTag() {
    const postOptions = {
      postRoute: "/ecosystem-tags/" + props.tag.id,
      method: "DELETE",
      token: true,
    };
    PostData(postOptions).then((result) => {
      if (result.success) {
        setDeleteTagModalState(false);
        props.updateEcosystem(true);
      }
    });
  }

  function changeEditTagModalState() {
    setEditTagModalState(!editTagModalState);
  }

  function changeDeleteTagModalState() {
    setDeleteTagModalState(!deleteTagModalState);
  }
  function ecoDropdown() {
    if (dd === "dropdown is-right is-pulled-right") {
      setDd("dropdown is-right is-pulled-right is-active");
    } else {
      setDd("dropdown is-right is-pulled-right");
    }
  }
  return (
    <Fragment>
      <FormModal
        title="Edit a Vertical Tag"
        modalState={editTagModalState}
        changeModalState={changeEditTagModalState}
        fields={[
          {
            type: "text",
            label: "Tag name",
            defaultValue: props.tag.name,
          },
          {
            type: "text",
            label: "Tag description",
            defaultValue: props.tag.description,
          },
        ]}
        buttonTextCancel="Cancel"
        buttonText="Save"
        response={editTag}
      />
      <DeleteFormModal
        title="Are you sure you want to delete?"
        modalState={deleteTagModalState}
        changeModalState={changeDeleteTagModalState}
        fields={[
          {
            type: "text",
            label: "Your webpages may now be unassigned if you delete the tag",
          },
        ]}
        buttonTextCancel="No, don't delete"
        buttonText="Yes"
        response={deleteTag}
      />
      <div className="column is-3-desktop is-4-tablet">
        <div className="card">
          <div className="card-content">
            <div className={dd} onClick={ecoDropdown}>
              <div className="dropdown-trigger is-clickable">
                <span className="icon">
                  <i className="fa fa-ellipsis-v"></i>
                </span>
              </div>
              <div className="dropdown-menu">
                <div className="dropdown-content">
                  <div className="dropdown-item dropdown-seprate is-clickable">
                    <span onClick={changeEditTagModalState}>Edit Tag</span>
                  </div>
                  <div className="dropdown-item is-clickable has-text-red">
                    <span onClick={changeDeleteTagModalState}>Delete Tag</span>
                  </div>
                </div>
              </div>
            </div>
            <p className="title is-size-5">{props.tag.name}</p>
            <p className="website-name is-small">{props.tag.description}</p>
            <p>&nbsp;</p>
            <p className="is-size-5">
              {props.tag.webpage_count.toString()} page
              {props.tag.webpage_count === 1 ? "" : "s"} added
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
