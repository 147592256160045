import { Col, Row } from "antd";
import React from "react";
// import ScoreBoardGraph from "./ScoreBoardGraph";
import ScoreBoardTitle from "./ScoreBoardTitle";
import "./ScoreboardContentContainer.scss";

const ScoreBoardContentContainer = ({ totalNumber, totalNumberText, graphData }) => {
  return (
    <Row
      className="scoreboard-content-container"
      justify="space-evenly"
      align="middle"
    >
      <Col span={24}>
        <ScoreBoardTitle
          totalNumber={totalNumber}
          totalNumberText={totalNumberText}
        />
      </Col>
      {/* <Col span={12}>
        <ScoreBoardGraph data={graphData} />
      </Col> */}
    </Row>
  );
};

export default ScoreBoardContentContainer;
