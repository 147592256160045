import React from "react";
import DataTableItemContainer from "./DataTableItemContainer";
import DataTableTitle from "./Title";
import "./index.scss";
import TableWrapper from "./TableWrapper";

const ProductDataTable = ({ title, ecosystemId }) => {
  return (
    <>
      <DataTableItemContainer>
        <DataTableTitle title={title} />
        <TableWrapper ecosystemId={ecosystemId} />
      </DataTableItemContainer>
    </>
  );
};

export default ProductDataTable;
