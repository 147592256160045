import { PostData } from "./PostData";

export function GetTeams() {
  return new Promise((resolve, reject) => {
    // Get all teams
    let allTeams = sessionStorage.getItem("teams");
    if (allTeams !== null) {
      const teams = JSON.parse(allTeams);
      resolve(teams);
    } else {
      const postOptions = {
        postRoute: "/teams",
        method: "GET",
        token: true,
      };
      !PostData(postOptions).error &&
        PostData(postOptions)?.then((result) => {
          const res = result;
          if (res.data !== undefined) {
            sessionStorage.setItem("teams", JSON.stringify(res));
            resolve(res);
          } else {
            reject(res);
          }
        });
    }
  });
}
