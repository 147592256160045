import { subHours, format } from "date-fns";

const getExpectDate = (dayNumber) => {
  if (dayNumber === "") {
    return "";
  }
  var date = new Date(Date.now() - dayNumber * 24 * 60 * 60 * 1000);
  var expectDate =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  return expectDate;
};

const getCurrentDate = () => {
  var today = new Date();
  var currentDate =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  return currentDate;
};

const isEmptyDate = (date) => {
  return date === "";
};

const isLast24Hours = (dateStr, formatDate = "yyyy-M-d") => {
  return format(subHours(new Date(), 24), formatDate) === dateStr;
};

export { getExpectDate, getCurrentDate, isEmptyDate, isLast24Hours };
