import React, { useMemo, useCallback } from "react";
import {
  XAxis,
  YAxis,
  Legend,
  Line,
  ResponsiveContainer,
  CartesianGrid,
  LineChart,
  Tooltip,
} from "recharts";
import { Row } from "antd";
import CustomRevisionLegend from "../../CustomChartComponents/CustomRevisionLegend";
import "./ConversionRateChart.scss";

const ConversionRateChart = ({ data, tickConfig, max, total }) => {
  const legendContents = useMemo(
    () => [
      {
        id: "conversionRate",
        value: total,
        color: "#00f9b2",
        label: "Conversion rate",
        postfix: "%",
      },
    ],
    [total]
  );

  const renderLegend = useCallback(() => {
    return (
      <div className="legend">
        <Row justify="start">
          {legendContents.map((legendContent) => (
            <CustomRevisionLegend
              postfix={legendContent.postfix}
              key={legendContent.id}
              color={legendContent.color}
              id={legendContent.id}
              value={legendContent.value}
              label={legendContent.label}
            />
          ))}
        </Row>
      </div>
    );
  }, [legendContents]);

  return (
    <ResponsiveContainer height={400}>
      <LineChart data={data} className="conversion-rate-chart">
        <CartesianGrid strokeDasharray="0" vertical={false} stroke="#e6e6e6" />
        <XAxis
          dataKey="date"
          axisLine={false}
          tickLine={false}
          tickMargin={20}
          tick={tickConfig}
          padding={{ left: 10, right: 10 }}
        />
        <YAxis
          width={100}
          axisLine={false}
          tickMargin={20}
          tickLine={false}
          tickCount={5}
          tick={tickConfig}
          padding={{ top: 20 }}
          domain={[0, max]}
        />
        <Legend align="left" content={renderLegend} />
        <Line
          dataKey="x"
          stroke="#00fcb4"
          strokeWidth={3}
          dot={<CustomizedDot />}
        />
        <Tooltip content={<CustomTooltip />} payload={data} />
      </LineChart>
    </ResponsiveContainer>
  );
};

const CustomizedDot = (props) => {
  const { cx, cy, payload } = props;

  return payload.changes && payload.changes !== "null" ? (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14.000000 18.000000"
      preserveAspectRatio="xMidYMid meet"
      x={cx - 10}
      y={cy - 20}
      width={20}
      height={20}
      fill="green"
      style={{ zIndex: 9999 }}
    >
      <g
        transform="translate(0.000000,18.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M26 148 c-9 -12 -16 -30 -16 -38 0 -17 50 -100 60 -100 10 0 60 83
60 100 0 30 -30 60 -60 60 -18 0 -34 -8 -44 -22z m64 -24 c11 -12 10 -18 -3
-32 -16 -15 -18 -15 -34 0 -13 14 -14 20 -3 32 16 20 24 20 40 0z"
        />
      </g>
    </svg>
  ) : (
    <></>
  );
};

const CustomTooltip = ({ active, payload }) => {
  if (
    active &&
    payload &&
    payload.length &&
    payload[0].payload["changes"] &&
    payload[0].payload["changes"] !== "null"
  ) {
    return (
      <div className="conversion-rate-chart-tooltip">
        {payload[0].payload["changes"].split(",").map((item) => (
          <>
            <p>{item}</p>
          </>
        ))}
      </div>
    );
  }
  return <></>;
};

export default ConversionRateChart;
