import { Button, Modal, Select } from "antd";
import React, { useState } from "react";
import { useMutationAddTagToWebpage } from "../../utils/Queries/WebpageQueries";
import { useQueryClient } from "react-query";
import "./index.scss";

const { Option } = Select;

const AssignVertical = ({
  selectedWebsitePageIds,
  setSelectedWebsitePageIds,
  tags,
  ecosystemId,
  invalidatedQueryKeys,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTagId, setSelectedTagId] = useState();
  const queryClient = useQueryClient();

  const mutation = useMutationAddTagToWebpage();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    mutation.mutate(
      {
        ecosystem_id: ecosystemId,
        tag_id: selectedTagId,
        web_page_id: selectedWebsitePageIds,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(invalidatedQueryKeys);
        },
      }
    );
    setSelectedWebsitePageIds([]);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button size="medium" className="assign-vertical" onClick={showModal}>
        Assign vertical
      </Button>
      <Modal
        title="Assign a vertical"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Save"
        closable={false}
        wrapClassName="assign-vertical-modal"
        centered
      >
        <p>Select a vertical tag</p>
        <Select
          style={{ width: 200 }}
          defaultValue="Please select"
          onChange={(tagId) => setSelectedTagId(tagId)}
        >
          {tags?.map((tag) => (
            <Option key={tag.id} value={tag.id}>
              {tag.name}
            </Option>
          ))}
        </Select>
      </Modal>
    </>
  );
};

export default AssignVertical;
