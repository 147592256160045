import React, { useCallback, useMemo, useState } from "react";
import { Select, Space } from "antd";
import "./WebsitePageDetailChart.scss";
import TrafficChart from "./TrafficChart";
import { useSelector } from "react-redux";
import { useGetWebsitePageStatistics } from "../../../utils/Queries/GoogleAnalyticsStatisticsQueries";
import { format, sub } from "date-fns";

let max = 0;

const WebsitePageDetailChart = ({
  ecosystemId,
  websiteId,
  webpageId,
}) => {
  const TRAFFIC = useMemo(() => "traffic", []);

  const [selectedChart, setSelectedChart] = useState(TRAFFIC);

  const datePicker = useSelector((state) => state.websiteTracking.datePicker);

  const { data: statisticsData } = useGetWebsitePageStatistics(
    datePicker,
    ecosystemId,
    websiteId,
    webpageId,
    selectedChart === TRAFFIC
  );

  const trafficData = useMemo(
    () =>
      statisticsData?.data?.map((item) => {
        max = Math.max(max, item.organic_views, item.paid_views, item.direct_views, item.email_views, item.referral_views);
        return {
          date: item.date,
          a: item.organic_views,
          b: item.paid_views,
          c: item.direct_views,
          d: item.email_views,
          e: item.referral_views,
        };
      }),
    [statisticsData]
  );

  const legendData = useMemo(
    () => statisticsData?.totals,
    [statisticsData?.totals]
  );

  const tickConfig = useMemo(() => {
    return {
      fontFamily: "'Inter', sans-serif",
      fontSize: "12px",
      color: "#7e8e9f",
    };
  }, []);

  const handleChange = (value) => {
    setSelectedChart(value);
  };

  const renderChart = useCallback(() => {
    switch (selectedChart) {
      case TRAFFIC:
        return (
          <TrafficChart
            data={trafficData}
            tickConfig={tickConfig}
            legendData={legendData}
            max={max}
          />
        );
      default:
        return <p>Something wrong</p>;
    }
  }, [selectedChart, TRAFFIC, trafficData, tickConfig, legendData]);

  return (
    <div className="website-page-detail-chart card">
      <Space align="start">
        <Select
          className="website-page-detail-chart-select"
          variant="borderless"
          defaultValue={TRAFFIC}
          onChange={handleChange}
          options={[
            {
              value: TRAFFIC,
              label: "Traffic",
            },
          ]}
        />
        <p className="website-page-detail-chart-date">
          {datePicker !== ""
            ? format(new Date(datePicker), "dd/MM/yyyy")
            : format(sub(new Date(), { days: 7 }), "dd/MM/yyyy")}{" "}
          - {format(new Date(), "dd/MM/yyyy")}
        </p>
      </Space>
      {renderChart()}
    </div>
  );
};

export default WebsitePageDetailChart;
