import Cookies from "universal-cookie";

export function PostData(options) {
  let postRoute = options.postRoute;
  let method = options.method;
  //let type = options.type;
  let token = "";
  if (options.token === true) {
    const cookies = new Cookies();
    let dnSess = cookies.get("DataNoodleSession");
    if (dnSess !== null && dnSess !== undefined) {
      dnSess = JSON.parse(atob(dnSess));
      token = dnSess.data.access_token;
    } else {
      return { error: "Session expired" };
    }
  }
  let formData = options.formData ?? null;
  let image = options.image ?? null;

  let BaseUrl = process.env.REACT_APP_API_SERVER_ENDPOINT;

  return new Promise((resolve, reject) => {
    /*
        var formBody = new FormData();
        if(formData !== null) {
            for (var property in formData) {
                formBody.append(property, formData[property]);
            }
        }
        */
    switch (method) {
      case "POST":
      case "GET":
        const headers = {};
        const fetchAttr = {};
        if (options.token === true) {
          headers["Authorization"] = "Bearer " + token;
          headers["Content-Type"] = "application/json";
          if (method === "POST") {
            fetchAttr["method"] = method;
          }
          fetchAttr["headers"] = headers;
          if (formData !== null) {
            fetchAttr["body"] = JSON.stringify(formData);
          }
        } else {
          headers["Accept"] = "application/vnd.datanoodle.v1+json";
          headers["Content-Type"] = "application/json";
          fetchAttr["method"] = method;
          fetchAttr["headers"] = headers;
          if (formData !== null) {
            fetchAttr["body"] = JSON.stringify(formData);
          }
        }
        fetch(BaseUrl + postRoute, fetchAttr)
          .then((response) => {
            if (
              response.status === 401 &&
              response.url === BaseUrl + "/oauth/refresh_token"
            ) {
              // Refresh token failed, log them out
              sessionStorage.clear();
              const cookies = new Cookies();
              cookies.remove("DataNoodleSession");
              window.location = "/";
            } else {
              if (image !== null) {
                return response.blob();
              } else {
                return response.json();
              }
            }
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
        break;
      case "PUT":
        var formBody = [];
        for (var property in formData) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(formData[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        let formBodyString = formBody.join("&");

        fetch(BaseUrl + postRoute, {
          method: method,
          headers: {
            Authorization: "Bearer " + token,
            //'Accept': 'application/vnd.datanoodle.v1+json',
            "Content-type": "application/x-www-form-urlencoded",
          },
          body: formBodyString,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            resolve(responseJson);
          })
          .catch((error) => {
            reject(error);
          });
        break;
      case "DELETE":
        fetch(BaseUrl + postRoute, {
          method: method,
          headers: {
            Authorization: "Bearer " + token,
            //'Accept': 'application/vnd.datanoodle.v1+json',
            "Content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
            resolve(responseJson);
          })
          .catch((error) => {
            reject(error);
          });
        break;
      default:
        reject("Form method not specified");
    }
  });
}
