import React, { Component } from "react";
import Paths from "./paths";
import "./v2/css/style.scss";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

class App extends Component {
  render() {
    return (
      <React.StrictMode>
        <Elements stripe={stripePromise}>
          <div
            className="App"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100vh",
            }}
          >
            <Paths />
          </div>
        </Elements>
      </React.StrictMode>
    );
  }
}

export default App;
