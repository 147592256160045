import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { LoggedInStatus } from "../../services/LoggedInStatus";
import { UserLogin } from "../Other/UserLogin";
import { UserProfileModal } from "../Other/UserProfileModal";
import "./Header.scss";
import { Link } from "react-router-dom";
import { Spin } from "antd";
import {
  FILTER_SESSION_NAME,
  PRODUCT_FILTER_SESSION,
  PRODUCT_VISIBLE_COLUMN_SESSION,
  WEBPAGE_FILTER_SESSION,
  WEBPAGE_VISIBLE_COLUMN_SESSION,
} from "../../constants/FilterSessionName";
import {
  changeDatePicker as changeDatePickerProduct,
  checkTagFilter as checkTagFilterProduct,
  checkWebsiteFilter as checkWebsiteFilterProduct,
} from "../../store/ProductTracking";
import {
  changeDatePicker as changeDatePickerWebpage,
  checkTagFilter as checkTagFilterWebpage,
  checkWebsiteFilter as checkWebsiteFilterWebpage,
} from "../../store/WebsiteTracking/WebsiteTracking";
import { useDispatch } from "react-redux";
import { getExpectDate } from "../../utils/DateTimeUtil";

export default function Header(props) {
  const [loggedInStatus, setLoggedInStatus] = useState(false);
  const [menuIsActive, setMenuIsActive] = useState(false);
  const [loginMenuIsActive, setLoginMenuIsActive] = useState(false);
  const ecosystemMenuMobileElement = useRef();
  const { ecosystemUuid } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (
      location.state === undefined ||
      !location.state?.hasOwnProperty("isSaveSession")
    ) {
      sessionStorage.removeItem(FILTER_SESSION_NAME);
      sessionStorage.removeItem(WEBPAGE_FILTER_SESSION);
      sessionStorage.removeItem(PRODUCT_FILTER_SESSION);
      sessionStorage.removeItem(WEBPAGE_VISIBLE_COLUMN_SESSION);
      sessionStorage.removeItem(PRODUCT_VISIBLE_COLUMN_SESSION);

      dispatch(checkWebsiteFilterWebpage([]));
      dispatch(checkTagFilterWebpage([]));
      dispatch(changeDatePickerWebpage(getExpectDate(30)));

      dispatch(checkWebsiteFilterProduct([]));
      dispatch(checkTagFilterProduct([]));
      dispatch(changeDatePickerProduct(getExpectDate(30)));
    }
  }, [dispatch, location]);

  useEffect(() => {
    const page = location.pathname === "/" ? "home" : "";
    const lis = LoggedInStatus(page);
    setLoggedInStatus(lis);
  }, [location]);

  function menuActive() {
    setMenuIsActive(!menuIsActive);
  }

  function loginMenuActive() {
    setLoginMenuIsActive(!loginMenuIsActive);
  }

  function isShowAllEcosystems() {
    return location.pathname === "/welcome";
  }

  return (
    <header>
      <nav className="navbar is-link">
        <div className="container">
          <div className="navbar-brand">
            <div className="navbar-item">
              <Link to="/welcome">
                <img
                  src="/images/Logo-slight.png"
                  id="brand-icon"
                  alt="DataNoodle"
                />
              </Link>
              <span className="navbar-brand-text">beta</span>
            </div>
            {props.loggedIn && (
              <div
                className="navbar-item has-dropdown is-hoverable ecosystem-menu-mobile"
                onClick={() =>
                  ecosystemMenuMobileElement.current.classList.toggle(
                    "navbar-dropdown-show",
                  )
                }
              >
                <div className="navbar-link not-hover-color">
                  <div className="has-text-white">Ecosystems</div>
                </div>
                <div
                  className="navbar-dropdown"
                  ref={ecosystemMenuMobileElement}
                >
                  <Link
                    to={"/welcome"}
                    key={"all-ecosystems-navigation"}
                    className="navbar-item"
                  >
                    {isShowAllEcosystems() && (
                      <span className="icon has-text-success pr-2">
                        <i className="fa fa-check"></i>
                      </span>
                    )}
                    All Ecosystems
                  </Link>
                  {props.ecosystems ? (
                    props.ecosystems.map((eco) => (
                      <Link
                        to={"/ecosystem/" + eco.uuid + "/webpages"}
                        key={eco.uuid}
                        className="navbar-item"
                      >
                        {ecosystemUuid === eco.uuid && (
                          <span className="icon has-text-success pr-2">
                            <i className="fa fa-check"></i>
                          </span>
                        )}
                        {eco.name}
                      </Link>
                    ))
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <Spin wrapperClassName="navbar-item" />
                    </div>
                  )}
                </div>
              </div>
            )}
            <span
              role="button"
              className={
                menuIsActive ? "navbar-burger is-active" : "navbar-burger"
              }
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
              onClick={menuActive}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </span>
          </div>
          <div
            className={menuIsActive ? "navbar-menu is-active" : "navbar-menu"}
          >
            <div className="navbar-start">
              <div className="navbar-item">
                <a
                  href="https://datanoodle.com/get-started.html"
                  target="_blank"
                  rel="noreferrer"
                  className="has-text-white"
                >
                  Guide
                </a>
              </div>
              <div className="navbar-item">
                <a
                  href="https://datanoodle.com/faq.html"
                  target="_blank"
                  rel="noreferrer"
                  className="has-text-white"
                >
                  FAQ
                </a>
              </div>
              {props.loggedIn && (
                <div className="navbar-item has-dropdown is-hoverable ecosystem-menu">
                  <div className="navbar-link not-hover-color">Ecosystems</div>
                  <div className="navbar-dropdown">
                    <Link
                      to={"/welcome"}
                      key={"all-ecosystems-navigation"}
                      className="navbar-item"
                    >
                      {isShowAllEcosystems() && (
                        <span className="icon has-text-success pr-2">
                          <i className="fa fa-check"></i>
                        </span>
                      )}
                      All Ecosystems
                    </Link>
                    {props.ecosystems ? (
                      props.ecosystems.map((eco) => (
                        <Link
                          to={"/ecosystem/" + eco.uuid + "/tracking-dashboard"}
                          key={eco.uuid}
                          className="navbar-item"
                        >
                          {ecosystemUuid === eco.uuid && (
                            <span className="icon has-text-success pr-2">
                              <i className="fa fa-check"></i>
                            </span>
                          )}
                          {eco.name}
                        </Link>
                      ))
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <Spin wrapperClassName="navbar-item" />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="navbar-end">
              {!loggedInStatus || props.pageName === "Home" ? (
                <UserLogin
                  updateLogin={props.updateLogin}
                  loginError={props.loginError}
                  loginMenuIsActive={loginMenuIsActive}
                  loginMenuActive={loginMenuActive}
                />
              ) : (
                props.pageName !== "Home" && <UserProfileModal />
              )}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
