import React from "react";
import { Route, Routes } from "react-router-dom";
import Ecosystem from "./components/Ecosystem";
import NotFound from "./components/NotFound";
import WebpageRevisionPage from "./v2/pages/WebpageRevisionPage";
import ProductRevisionPage from "./v2/pages/ProductRevisionPage";
import UserProfilePage from "./v2/pages/UserProfilePage";
import LoginPage from "./v2/pages/LoginPage";
import Payment from "./components/Payment";
import UpdatePayment from "./components/UpdatePayment";
import WebsitePage from "./v2/pages/WebsitePage";
import ProductPage from "./v2/pages/ProductPage";
import VerticalTagPage from "./v2/pages/VerticalTagPage";
import WelcomePage from "./v2/pages/WelcomePage";
import HomePage from "./v2/pages/HomePage";
import PasswordResetPage from "./v2/pages/PasswordResetPage";
import TrackingDashboard from "./v2/pages/TrackingDashboard";
import UserPasswordPage from "./v2/pages/UserPasswordPage";
import UserBillingPage from "./v2/pages/UserBillingPage";

function Paths() {
  return (
    <Routes>
      <Route exact path="/" Component={HomePage} />
      <Route exact path="/login" Component={LoginPage} />
      <Route exact path="/payment" Component={Payment} />
      <Route exact path="/reset-password" Component={PasswordResetPage} />
      <Route exact path={`/welcome`} Component={WelcomePage} />
      <Route exact path="/user/:userId/profile" Component={UserProfilePage} />
      <Route exact path="/user/:userId/password" Component={UserPasswordPage} />
      <Route exact path="/user/:userId/billing" Component={UserBillingPage} />
      <Route
        exact
        path="/user/:userId/updatePayment"
        Component={UpdatePayment}
      />
      <Route
        exact
        path="/ecosystem/:ecosystemId/settings"
        Component={Ecosystem}
      />
      <Route
        exact
        path={`/ecosystem/:ecosystemUuid/vertical-tags`}
        Component={VerticalTagPage}
      />
      <Route
        exact
        path={`/ecosystem/:ecosystemUuid/tracking-dashboard`}
        Component={TrackingDashboard}
      />
      <Route
        exact
        path={`/ecosystem/:ecosystemUuid/webpages/:websiteId?`}
        Component={WebsitePage}
      />
      <Route
        exact
        path={`/ecosystem/:ecosystemUuid/products/:websiteId?`}
        Component={ProductPage}
      />
      <Route
        exact
        path="/ecosystem/:ecosystemUuid/webpages/:websiteId/revisions/:webpageId"
        Component={WebpageRevisionPage}
      />
      <Route
        exact
        path="/ecosystem/:ecosystemUuid/websites/:websiteId/products/:productId/revisions"
        Component={ProductRevisionPage}
      />
      <Route path="*" Component={NotFound} />
    </Routes>
  );
}

export default Paths;
