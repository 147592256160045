import React, { useCallback, useEffect, useState } from "react";
import { Button, Popover } from "antd";
import "./MainFilter.scss";
import MainFilterContentContainer from "../MainFilterContentContainer/MainFilterContentContainer";
import { useSelector } from "react-redux";

const MainFilter = ({ filterValues, filterType, onChange }) => {
  const [isChecked, setIsChecked] = useState(false);
  const filter = useSelector((state) => state);

  const handleFilterIsChecked = useCallback(() => {
    if (
      filter[filterType].websiteFilter.length > 0 ||
      filter[filterType].tagFilter.length > 0
    ) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [filter, filterType]);

  useEffect(() => {
    handleFilterIsChecked();
  }, [handleFilterIsChecked]);

  return (
    <div className='main-filter-wrapper'>
      <Popover
        placement="bottomLeft"
        content={
          <MainFilterContentContainer
            filterValues={filterValues}
            onChange={onChange}
          />
        }
        trigger="click"
        overlayClassName="main-filter-popover"
      >
        <Button className={`main-filter ${isChecked ? "is-checked" : ""}`}>
          + Add filter
        </Button>
      </Popover>
    </div>
  );
};

export default MainFilter;
