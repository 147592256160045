import { Modal } from "antd";
import React, { useCallback } from "react";
import { useDeleteEcosystem } from "../../../../utils/Mutations/EcosystemMutation";
import "../EcosystemBoardHeader.scss";

const EcosystemDeleteModal = ({ isVisible, setIsVisible, ecosystemId }) => {
  const mutation = useDeleteEcosystem();

  const hideModal = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  const handleOk = useCallback(() => {
    mutation.mutate({ ecosystemId: ecosystemId });
    hideModal();
  }, [mutation, hideModal, ecosystemId]);

  const handleCancel = useCallback(() => {
    hideModal();
  }, [hideModal]);

  return (
    <Modal
      title={<p>Are you sure you want to delete</p>}
      open={isVisible}
      onOk={handleOk}
      okText="Save"
      onCancel={handleCancel}
      wrapClassName="ecosystem-delete-modal"
      centered
    >
      <p className="warning-message">
        If deleted all of your website data will be lost
      </p>
    </Modal>
  );
};

export default EcosystemDeleteModal;
