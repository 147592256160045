import { Button, Popover } from "antd";
import PopoverContent from "./PopoverContent";
import React, { useState } from "react";

const AdvancedSearchFilter = ({ setFilters, filters }) => {
  const [visible, setVisible] = useState(false);
  const [applied, setApplied] = useState(false);

  const handleApply = () => {
    setVisible(false);
    setApplied(true);
  };

  return (
    <Popover
      placement="bottomLeft"
      content={
        <PopoverContent
          setFilters={setFilters}
          filters={filters}
          handleApply={handleApply}
          setVisible={setVisible}
          setApplied={setApplied}
        />
      }
      trigger="click"
      open={visible}
      onOpenChange={(newVisible) => setVisible(newVisible)}
      overlayStyle={{ width: "400px" }}
    >
      <div className="data-table-filter">
        <Button
          className={`data-table-filter ${applied ? "data-table-filter-is-selected" : ""
          }`}
        >
          <span>Advanced Search</span>
        </Button>
      </div>
    </Popover>
  );
};

export default AdvancedSearchFilter;
