import React, { useEffect, useState } from "react";
import { PostData } from "../../services/PostData";
import Cookies from "universal-cookie";
import "./UserProfileModal.scss";
import { useNavigate } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";

export function UserProfileModal() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = sessionStorage.getItem("user");

    if (storedUser) {
      try {
        setUser(JSON.parse(storedUser));
      } catch (error) {
        sessionStorage.removeItem("user");
      }
    } else {
      const fetchUserData = async () => {
        const postOptions = {
          postRoute: "/user",
          method: "GET",
          token: true,
        };
        try {
          const result = await PostData(postOptions);
          sessionStorage.setItem("user", JSON.stringify(result));
          setUser(result);
        } catch (error) {
        }
      };
      fetchUserData();
    }
  }, []);

  const routeChange = () => {
    if (user) {
      const path = `/user/${user.data.id}/profile`;
      navigate(path);
    }
  };

  const logout = () => {
    const cookies = new Cookies();
    cookies.remove("DataNoodleSession");
    sessionStorage.clear();
    localStorage.clear();
    window.location = "/";
  };

  const menuItems = [
    {
      key: "1",
      label: (
        <div style={{ textAlign: "center" }}>
          <div className="profile-menu-text">
            {user && user.data.first_name} {user && user.data.last_name}
          </div>
          <div className="has-text-grey">{user && user.data.email}</div>
        </div>
      ),
    },
    {
      key: "2",
      label: <span onClick={routeChange}>View Profile</span>,
      icon: <img src="/images/person.png" alt="profile icon" />,
    },
    {
      key: "5",
      label: <span onClick={logout}>Logout</span>,
      icon: <img src="/images/logout.png" alt="logout icon" />,
    },
  ];

  return (
    <Dropdown
      menu={{
        items: menuItems,
      }}
      className="navbar-item"
      overlayClassName="profile-dropdown"
    >
      <div>
        <Space>
          {user ? `${user.data.first_name} ${user.data.last_name}` : "Loading..."}
          <DownOutlined />
        </Space>
      </div>
    </Dropdown>
  );
}
