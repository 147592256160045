import { Alert } from "antd";
import React from "react";
import "./MessageBox.scss";

const MessageBox = ({ message, handleOnClose, style }) => {
  return (
    <Alert
      className="message-box"
      message={message}
      type="info"
      closable
      closeIcon={<i className="fa fa-close" style={{ "fontSize": "14px" }}></i>}
      onClose={handleOnClose}
      style={style}
    />
  );
};

export default MessageBox;
