import React, { useState, useEffect, Fragment } from "react";
import { useParams, Navigate } from "react-router-dom";
import { LoggedInStatus } from "../services/LoggedInStatus";
import { PostData } from "../services/PostData";
import { GetEcosystems } from "../services/GetEcosystems";
import Loading from "../components/Animations/Loading";
import MessageModal from "../components/Modals/MessageModal";
import Header from "../components/Layout/Header";
import SubHeaderUser from "../components/Layout/SubHeaderUser";
import UpdatePasswordForm from "../../components/UpdatePasswordForm";
import Footer from "../components/Layout/Footer";

const UserPassword = () => {
  const { userId } = useParams();
  const [loggedIn, setLoggedIn] = useState(null);
  const [ecosystems, setEcosystems] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      sessionStorage.setItem("userId", userId);
      const li = LoggedInStatus();
      setLoggedIn(li);
      if (li !== false && li !== "exit") {
        try {
          const allEcosystems = await GetEcosystems();
          setEcosystems(allEcosystems.data);
          const postOptions = {
            postRoute: `/user/${userId}/profile`,
            method: "GET",
            token: true,
          };
          const result = await PostData(postOptions);
          setCurrentUser(result);
          setLoading(false);
        } catch (error) {
          setError(true);
        }
      }
    };

    fetchData();
  }, [userId]);

  const updateLogin = (val) => {
    setLoggedIn(val);
  };

  const logout = () => {
    setLoggedIn(false);
  };

  const errorModalState = (ms = false) => {
    setError(ms);
  };

  if (loggedIn === false || loggedIn === "exit" || loggedIn === undefined) {
    return <Navigate to={"/"} />;
  } else if (loading || loggedIn === null) {
    return <Loading pad={200} />;
  } else if (error !== false) {
    return <MessageModal modalState={errorModalState} message={error} />;
  } else {
    return (
      <Fragment>
        <Header
          updateLogin={updateLogin}
          logout={logout}
          loggedIn={loggedIn}
          ecosystems={ecosystems}
          ecosystemId={ecosystems?.ecosystemId}
        />
        <SubHeaderUser
          firstName={currentUser?.data.first_name}
          userId={userId}
        />
        <UpdatePasswordForm userId={userId} />
        <Footer />
      </Fragment>
    );
  }
};

export default UserPassword;