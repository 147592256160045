import React from "react";
import Loading from "../../../../components/Animations/Loading";
import EcosystemBoard from "../../EcosystemBoard";

const WelcomeContent = ({ ecosystems, teamId, isLoadingGetAllEcosystems }) => {
  return isLoadingGetAllEcosystems ? (
    <Loading />
  ) : ecosystems?.length === 0 ? (
    <EcosystemBoard teamId={teamId} />
  ) : (
    ecosystems?.map((ecosystem) => (
      <EcosystemBoard
        key={ecosystem.id}
        ecosystem={ecosystem}
        ecosystems={ecosystems}
      />
    ))
  );
};

export default WelcomeContent;
