import React, { useCallback, useState } from "react";
import EcosystemDeleteModal from "../EcosystemDeleteModal";

const EcosystemPopoverContent = ({ handlePopoverVisible, ecosystemId }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleOnClick = useCallback(() => {
    setIsVisible(true);
    handlePopoverVisible(false);
  }, [setIsVisible, handlePopoverVisible]);

  return (
    <>
      <span onClick={handleOnClick}>Delete Ecosystem</span>
      <EcosystemDeleteModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        ecosystemId={ecosystemId}
      />
    </>
  );
};

export default EcosystemPopoverContent;
