import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { LoggedInStatus } from "../services/LoggedInStatus";
import Header from "../components/Layout/Header";
import SubHeader from "../components/Layout/SubHeader";
import Footer from "../components/Layout/Footer";
import Loading from "../components/Animations/Loading";
import MessageModal from "../../components/Modals/MessageModal";
import { useParams } from "react-router-dom";
import MainSection from "../components/MainSection/MainSection";
import VerticalTagContent from "../components/VerticalTagContent";
import MainSectionTitle from "../components/MainSectionTitle/MainSectionTitle";
import {
  useGetAllEcosystemsOfCurrentUserQuery,
  useGetEcosystemIdByUuidQuery,
} from "../utils/Queries/EcosystemQueries";
import MessageBox from "../components/MessageBox";
import MessageBoxModal from "../components/Modals/MessageBoxModal";
import { useFinishLearn } from "../utils/Mutations/UserMutation";

const VerticalTagPage = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [ecosystems, setEcosystems] = useState([]);
  const [websites] = useState(null);
  const [error] = useState(false);
  const [ecosystemName, setEcosystemName] = useState("");
  const [user] = useState(() => JSON.parse(sessionStorage.getItem("user")));

  const { ecosystemUuid, websiteId } = useParams();

  const { data: ecosystemId } = useGetEcosystemIdByUuidQuery(ecosystemUuid);

  const { data: allEcosystemOfUser, isSuccess: isSuccessAllEcosystemOfUser } =
    useGetAllEcosystemsOfCurrentUserQuery();

  useEffect(() => {
    setIsAuthenticated(LoggedInStatus());
    if (isSuccessAllEcosystemOfUser) {
      setEcosystems(allEcosystemOfUser);
    }
    sessionStorage.setItem("ecosystemId", ecosystemId);
    isSuccessAllEcosystemOfUser &&
      allEcosystemOfUser.forEach((ecosystem) => {
        if (ecosystem?.id === parseInt(ecosystemId)) {
          setEcosystemName(ecosystem?.name);
        }
      });
  }, [
    setIsAuthenticated,
    isSuccessAllEcosystemOfUser,
    ecosystemId,
    allEcosystemOfUser,
  ]);

  const subHeader = (websiteId) => {
    if (websiteId > 0) {
      let _webisteId = parseInt(websiteId);
      return websites.data[0].find((w) => w.id === _webisteId).name;
    }
  };

  const updateLogin = (val) => {
    setIsAuthenticated(val);
  };

  const logout = () => {
    setIsAuthenticated(false);
  };

  const { mutate: finishLearnEcosystem } = useFinishLearn();

  const handleVerticalTagMessageBoxOnClose = () => {
    finishLearnEcosystem({
      id: user.data.id,
      learnName: "finish_learn_vertical_tag",
      value: 1,
    });
    user.data.finish_learn_vertical_tag = 1;
    sessionStorage.setItem("user", JSON.stringify(user));
  };

  return (
    <>
      {isAuthenticated === false ||
        isAuthenticated === "exit" ||
        isAuthenticated === undefined ? (
        <Navigate to="/" />
      ) : isAuthenticated === null ? ( // need revisit
        <Loading pad={200} />
      ) : error !== false ? (
        <MessageModal modalState={this.errorModalState} message={error} />
      ) : (
        <>
          <div>
            <Header
              updateLogin={updateLogin}
              logout={logout}
              loggedIn={isAuthenticated}
              ecosystems={isSuccessAllEcosystemOfUser && ecosystems}
              ecosystemId={ecosystemId}
            />
            <SubHeader
              ecosystemName={ecosystemName}
              ecosystemId={ecosystemUuid}
              websiteId={websiteId}
              websiteName={subHeader(websiteId)}
            />
          </div>

          <MainSection>
            {user?.data && !user?.data?.finish_learn_vertical_tag && (
              <MessageBox
                handleOnClose={handleVerticalTagMessageBoxOnClose}
                message={
                  <>
                    Vertical tags are a great way to segment your data.{" "}
                    <MessageBoxModal
                      message="Click here to watch a video"
                      title="Vertical Tags"
                      videoSrc="https://player.vimeo.com/video/798159067?h=f9ee46b4c9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    />{" "}
                    on how to use this feature. You can find more information on
                    our{" "}
                    <a href="https://datanoodle.com/get-started.html">
                      Get started guide
                    </a>
                    .
                  </>
                }
                style={{ marginBottom: "10px" }}
              />
            )}

            <MainSectionTitle title="Vertical Tags" />
            <VerticalTagContent ecosystemId={ecosystemId} />
          </MainSection>
          <Footer />
        </>
      )}
    </>
  );
};

export default VerticalTagPage;
