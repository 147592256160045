import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import SubHeader from "../components/Layout/SubHeader";
import Footer from "../components/Layout/Footer";
import Loading from "../components/Animations/Loading";
import MessageModal from "../../components/Modals/MessageModal";
import { useParams } from "react-router-dom";
import MainSection from "../components/MainSection/MainSection";
import MainSectionTitle from "../components/MainSectionTitle/MainSectionTitle";
import MainDatePicker from "../components/MainDatePicker/MainDatePicker";
import MainFilterContainer from "../components/MainFilterContainer/MainFilterContainer";
import MainFilter from "../components/MainFilter/MainFilter";
import { useGetAllWebsitesBelongsToEcosystemQuery } from "../utils/Queries/WebsiteQueries";
import { useGetAllTagsBelongsToEcosystemQuery } from "../utils/Queries/TagQueries";
import {
  PRODUCT_TRACKING,
  TAG_FILTER,
  WEBSITE_FILTER,
} from "../constants/MainFilterTypeConstant";
import {
  changeDatePicker,
  checkTagFilter,
  checkWebsiteFilter,
} from "../store/ProductTracking";
import { useDispatch, useSelector } from "react-redux";
import ScoreBoardContainer from "../components/ScoreBoardContainer/ScoreBoardContainer";
import { useGetProductStatisticOfEcosystem } from "../utils/Queries/ProductStatisticQueries";
import ProductDataTable from "../components/ProductDataTable";
import {
  CHART_TYPE_CHANGES,
  CHART_TYPE_AVAILABILITY,
} from "../constants/ChartType";
import ProductRevisionChangesChart from "../components/Chart/ProductRevisionChangesChart";
import ProductRevisionAvailabilityChart from "../components/Chart/ProductRevisionAvailabilityChart";
import {
  useGetAllEcosystemsOfCurrentUserQuery,
  useGetEcosystemIdByUuidQuery,
} from "../utils/Queries/EcosystemQueries";
import Header from "../components/Layout/Header";
import MessageBox from "../components/MessageBox";
import MessageBoxModal from "../components/Modals/MessageBoxModal";
import { useFinishLearn } from "../utils/Mutations/UserMutation";
import { LoggedInStatus } from "../services/LoggedInStatus";
import { PRODUCT_FILTER_SESSION } from "../constants/FilterSessionName";

const ProductPage = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [ecosystems, setEcosystems] = useState([]);
  const [websites] = useState(null);
  const [error] = useState(false);
  const [ecosystemName, setEcosystemName] = useState("");
  const [chartType, setChartType] = useState("Changes");
  const [hideLegends, setHideLegends] = useState([]);
  const [user] = useState(() => JSON.parse(sessionStorage.getItem("user")));

  const dispatch = useDispatch();
  const datePicker = useSelector((state) => state.productTracking.datePicker);
  const websiteFilter = useSelector(
    (state) => state.productTracking.websiteFilter,
  );
  const tagFilter = useSelector((state) => state.productTracking.tagFilter);

  const { ecosystemUuid, websiteId } = useParams();

  const location = useLocation();

  const { data: ecosystemId } = useGetEcosystemIdByUuidQuery(ecosystemUuid);

  const {
    data: allWebsitesOfCurrentEcosystemData,
    isLoading: isAllWebsitesOfCurrentEcosystemLoading,
  } = useGetAllWebsitesBelongsToEcosystemQuery(ecosystemId);
  const {
    data: allTagsOfCurrentEcosystemData,
    isLoading: isAllTagsOfCurrentEcosystemLoading,
  } = useGetAllTagsBelongsToEcosystemQuery(ecosystemId);

  const { data: allEcosystemOfUser, isSuccess: isSuccessAllEcosystemOfUser } =
    useGetAllEcosystemsOfCurrentUserQuery();

  const { data: productStatisticOfEcosystem } =
    useGetProductStatisticOfEcosystem(ecosystemId, websiteFilter, tagFilter);

  useEffect(() => {
    setIsAuthenticated(LoggedInStatus());
    if (isSuccessAllEcosystemOfUser) {
      setEcosystems(allEcosystemOfUser);
    }
    sessionStorage.setItem("ecosystemId", ecosystemId);
    isSuccessAllEcosystemOfUser &&
    allEcosystemOfUser.forEach((ecosystem) => {
      if (ecosystem?.id === parseInt(ecosystemId)) {
        setEcosystemName(ecosystem?.name);
      }
    });
  }, [allEcosystemOfUser, isSuccessAllEcosystemOfUser, ecosystemId]);

  useEffect(() => {
    const filterSession = JSON.parse(sessionStorage.getItem(PRODUCT_FILTER_SESSION));

    // Check if we navigated from the product detail page (i.e., isFromDetail is true)
    if (location?.state?.isFromDetail && filterSession) {
      dispatch(changeDatePicker(filterSession.filter.dateFrom));
      dispatch(checkWebsiteFilter(filterSession.filter.website));
      dispatch(checkTagFilter(filterSession.filter.tag));
    }
  }, [location?.state?.isFromDetail, dispatch]);

  const subHeader = (websiteId) => {
    if (websiteId > 0) {
      let _webisteId = parseInt(websiteId);
      return websites.data[0].find((w) => w.id === _webisteId).name;
    }
  };

  const updateLogin = (val) => {
    setIsAuthenticated(val);
  };

  const logout = () => {
    setIsAuthenticated(false);
  };

  const handleMainFilterOnChange = useCallback(
    (filterType, checkedValues) => {
      switch (filterType) {
        case WEBSITE_FILTER:
          dispatch(checkWebsiteFilter(checkedValues));
          break;
        case TAG_FILTER:
          dispatch(checkTagFilter(checkedValues));
          break;
        default:
      }
    },
    [dispatch],
  );

  const handleSelectChartType = useCallback((chartType) => {
    setChartType(chartType);
    setHideLegends([]);
  }, []);

  const handleMainDatePickerOnSelect = useCallback(
    (e) => {
      dispatch(changeDatePicker(e));
    },
    [dispatch],
  );

  const canShowMainFilter = useMemo(
    () =>
      !isAllWebsitesOfCurrentEcosystemLoading &&
      !isAllTagsOfCurrentEcosystemLoading &&
      isSuccessAllEcosystemOfUser,
    [
      isAllTagsOfCurrentEcosystemLoading,
      isAllWebsitesOfCurrentEcosystemLoading,
      isSuccessAllEcosystemOfUser,
    ],
  );

  const { mutate: finishLearnEcosystem } = useFinishLearn();

  const handleProductMessageBoxOnClose = () => {
    finishLearnEcosystem({
      id: user.data.id,
      learnName: "finish_learn_product",
      value: 1,
    });
    user.data.finish_learn_product = 1;
    sessionStorage.setItem("user", JSON.stringify(user));
  };

  const handleProductDatatableMessageBoxOnClose = () => {
    finishLearnEcosystem({
      id: user.data.id,
      learnName: "finish_learn_product_datatable",
      value: 1,
    });
    user.data.finish_learn_product_datatable = 1;
    sessionStorage.setItem("user", JSON.stringify(user));
  };

  return (
    <>
      {isAuthenticated === false ||
      isAuthenticated === "exit" ||
      isAuthenticated === undefined ? (
        <Navigate to="/" />
      ) : isAuthenticated === null ? ( // need revisit
        <Loading pad={200} />
      ) : error !== false ? (
        <MessageModal modalState={this.errorModalState} message={error} />
      ) : (
        <>
          <Header
            updateLogin={updateLogin}
            logout={logout}
            loggedIn={isAuthenticated}
            ecosystems={isSuccessAllEcosystemOfUser && ecosystems}
            ecosystemId={ecosystemId}
          />
          <SubHeader
            ecosystemName={ecosystemName}
            ecosystemId={ecosystemUuid}
            websiteId={websiteId}
            websiteName={subHeader(websiteId)}
          />
          <MainSection>
            {user?.data && !user?.data?.finish_learn_product && (
              <MessageBox
                handleOnClose={handleProductMessageBoxOnClose}
                message={
                  <>
                    The Changes and Status graphs plot changes of structured
                    data over a given period.{" "}
                    <MessageBoxModal
                      message="Click here to watch a video"
                      title="Product and Availability Changes Graph"
                      videoSrc="https://player.vimeo.com/video/811656376?h=6382cb9a14&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    />{" "}
                    on how to use this feature. You can find more information on
                    our{" "}
                    <a href="https://datanoodle.com/get-started.html">
                      Get started guide
                    </a>
                    .
                  </>
                }
                style={{ marginBottom: "10px" }}
              />
            )}
            <MainSectionTitle title={"Product Changes"} />
            <MainFilterContainer>
              {canShowMainFilter && (
                <>
                  <MainDatePicker
                    onSelect={handleMainDatePickerOnSelect}
                    ecosystem={allWebsitesOfCurrentEcosystemData?.data[0]}
                    filterType={PRODUCT_TRACKING}
                  />
                  <MainFilter
                    filterValues={{
                      websites: allWebsitesOfCurrentEcosystemData,
                      tags: allTagsOfCurrentEcosystemData,
                    }}
                    filterType={PRODUCT_TRACKING}
                    onChange={handleMainFilterOnChange}
                  />
                </>
              )}
            </MainFilterContainer>

            <ScoreBoardContainer
              statisticData={{
                "Total products": productStatisticOfEcosystem?.totalProducts,
                "Total product value":
                productStatisticOfEcosystem?.totalProductValue,
                "Total in stock":
                productStatisticOfEcosystem?.totalProductInStock,
              }}
            />

            {!canShowMainFilter ? (
              <Loading />
            ) : chartType === CHART_TYPE_CHANGES ? (
              <ProductRevisionChangesChart
                ecosystem={allWebsitesOfCurrentEcosystemData?.data[0]}
                chartTypeLabels={[CHART_TYPE_CHANGES, CHART_TYPE_AVAILABILITY]}
                dateFrom={datePicker}
                handleSelectChartType={handleSelectChartType}
                chartType={chartType}
                websiteFilter={websiteFilter}
                tagFilter={tagFilter}
                hideLegends={hideLegends}
                setHideLegends={setHideLegends}
              />
            ) : (
              <ProductRevisionAvailabilityChart
                ecosystem={allWebsitesOfCurrentEcosystemData?.data[0]}
                chartTypeLabels={[CHART_TYPE_CHANGES, CHART_TYPE_AVAILABILITY]}
                dateFrom={datePicker}
                handleSelectChartType={handleSelectChartType}
                chartType={chartType}
                websiteFilter={websiteFilter}
                tagFilter={tagFilter}
                hideLegends={hideLegends}
                setHideLegends={setHideLegends}
              />
            )}
            {user?.data && !user?.data?.finish_learn_product_datatable && (
              <MessageBox
                handleOnClose={handleProductDatatableMessageBoxOnClose}
                message={
                  <>
                    The Product Data table gives you visibility of product
                    changes like price and availability.{" "}
                    <MessageBoxModal
                      message="Click here to watch a video"
                      title="Product Data"
                      videoSrc="https://player.vimeo.com/video/794611766?h=fc68e0ed3c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    />{" "}
                    on how to use this feature. You can find more information on
                    our{" "}
                    <a href="https://datanoodle.com/get-started.html">
                      Get started guide
                    </a>
                    .
                  </>
                }
                style={{ marginBottom: "5px", marginTop: "20px" }}
              />
            )}

            <ProductDataTable
              title="Product Data"
              ecosystemId={ecosystemUuid}
            />
          </MainSection>
          <Footer />
        </>
      )}
    </>
  );
};

export default ProductPage;
