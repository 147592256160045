import { Space } from "antd";
import React from "react";
import CreateEcosystemButton from "../../Button/CreateEcosystemButton";
import CreateWebsiteButton from "../../Button/CreateWebsiteButton";
import "./index.scss";

const WelcomeHeader = ({
  hasEcosystems,
  ecosystems,
  teamId,
}) => {
  return (
    <Space className="welcome-header">
      <CreateEcosystemButton teamId={teamId}/>
      {hasEcosystems && (
        <CreateWebsiteButton
          ecosystems={ecosystems}
        />
      )}
    </Space>
  );
};

export default WelcomeHeader;
