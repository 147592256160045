import React, { useMemo, useCallback } from "react";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "./PriceHistoryChart.scss";
import { Row } from "antd";
import CustomRevisionLegend from "../../CustomChartComponents/CustomRevisionLegend";

const PriceHistoryChart = ({ data, tickConfig }) => {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="price">{`$${payload[0].payload.price.toLocaleString()}`}</p>
          <span
            className={`price-change ${payload[0].payload.priceChange.includes("-")
                ? "negative"
                : "positive"
              }`}
          >{`${payload[0].payload.priceChange}%`}</span>
        </div>
      );
    }

    return null;
  };

  const legendContents = useMemo(
    () => [
      {
        id: "priceHistory",
        value: data.length,
        color: "#00f9b2",
        label: "Price History",
        prefix: "",
      },
    ],
    [data.length]
  );

  const renderLegend = useCallback(() => {
    return (
      <div className="legend">
        <Row justify="start">
          {legendContents.map((legendContent) => (
            <CustomRevisionLegend
              prefix={legendContent.prefix}
              key={legendContent.id}
              color={legendContent.color}
              id={legendContent.id}
              value={legendContent.value}
              label={legendContent.label}
            />
          ))}
        </Row>
      </div>
    );
  }, [legendContents]);

  return (
    <ResponsiveContainer height={400}>
      <ComposedChart data={data}>
        <XAxis
          dataKey="date"
          axisLine={false}
          tickLine={false}
          tickMargin={20}
          tick={tickConfig}
        />
        <YAxis
          width={100}
          axisLine={false}
          tickMargin={20}
          tickLine={false}
          tickCount={5}
          tick={tickConfig}
        />
        <Tooltip
          content={<CustomTooltip />}
          contentStyle={{ backgroundColor: "#000" }}
        />
        <Legend align="left" content={renderLegend} />
        <CartesianGrid strokeDasharray="0" vertical={false} stroke="#e6e6e6" />
        <Bar dataKey="price" maxBarSize={50} fill="#00fcb4" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default PriceHistoryChart;
