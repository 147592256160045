import React, { useEffect, useState } from "react";
import { PostData } from "../../services/PostData";
import Cookies from "universal-cookie";
import "./UserLogin.scss";
import { Input, Modal } from "antd";
import { isValidEmail } from "../../utils/MailUtil";
import { isEmpty } from "../../utils/StringUtil";
import { useSendResetPasswordMail } from "../../utils/Mutations/UserMutation";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    userId: "",
    event: "login",
  },
  dataLayerName: "Datanoodle",
};

export function UserLogin(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [buttonClass, setButtonClass] = useState("login-box-btn");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [isNotFoundError, setIsNotFoundError] = useState(false);
  const [isBadRequestError, setIsBadRequestError] = useState(false);
  const [isFound, setIsFound] = useState(false);

  const {
    mutate: forgotPasswordMutate,
    data: forgotPasswordData,
    isSuccess: forgotPasswordIsSuccess,
    isLoading: forgotPasswordIsLoading,
  } = useSendResetPasswordMail(email);

  useEffect(() => {
    if (forgotPasswordData?.status === 404) {
      setIsNotFoundError(true);
    }
    if (forgotPasswordData?.status === 200) {
      setIsFound(true);
    }
  }, [forgotPasswordData, forgotPasswordIsSuccess]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    forgotPasswordMutate({ email: email });
  };

const handleLogin = async (e) => {
  e.preventDefault();

  setButtonClass("login-box-btn button is-success is-rounded is-loading");
  const allGood = await login();
  if (allGood) {
    props.updateLogin(true);
  }
};
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsNotFoundError(false);
    setEmail("");
    setIsBadRequestError(false);
    setIsFound(false);
  };

  const handleOnChangeEmail = (e) => {
    setEmail(e.target.value);
    setIsBadRequestError(
      !isValidEmail(e.target.value) && !isEmpty(e.target.value),
    );
  };

  function login() {
    if (username !== "" && password !== "") {
      const loginDetails = {
        email: username,
        password: password,
      };
      const postOptions = {
        postRoute: "/oauth/access_token",
        method: "POST",
        token: false,
        formData: loginDetails,
      };
      PostData(postOptions)
        .then((result) => {
          const responseJSON = result;
          if (responseJSON.success === true) {
            tagManagerArgs.dataLayer.userId = responseJSON.data.gtm_id;
            TagManager.dataLayer(tagManagerArgs);
            let udStr = JSON.stringify(responseJSON);
            udStr = btoa(udStr);
            sessionStorage.setItem("DataNoodleSession", udStr);
            const cookies = new Cookies();
            cookies.set("DataNoodleSession", udStr, { path: "/" });
            // setSess(responseJSON);
            props.updateLogin();
          } else {
            // Email and password not matched
            setButtonClass("button is-success");
            props.loginError("Login failed. Please try again");
            return false;
          }
        })
        .catch((error) => {
          props.loginError(error);
          return false;
        });
    } else {
      // Email and password fields not filled in
      setButtonClass("button is-success");
      props.loginError("Please enter your email and password");
      return false;
    }
  }

  function loginMenuActive() {
    props.loginMenuActive();
  }

  return (
    <div
      className={
        props.loginMenuIsActive
          ? "navbar-item has-dropdown is-active"
          : "navbar-item has-dropdown"
      }
    >
      <div className="navbar-link not-hover-color" onClick={loginMenuActive}>
        Login
      </div>
      <div className="navbar-dropdown is-right">
        <div className="navbar-item login-modal">
          <div className="login-box">
            <h4 className="is-size-5 login-box-title">Login to DataNoodle</h4>
            <hr />
            <form onSubmit={handleLogin} >
              <div className="field">
                <label className="label">Email address</label>
                <div className="control">
                  <input
                    className="input"
                    type="email"
                    name="username"
                    placeholder="name@example.com"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">
                  Password{" "}
                  <span className="forgot-password-link" onClick={showModal}>
                  Forgot your password?
                </span>
                  <Modal
                    wrapClassName="forgot-password-modal"
                    title={<p>Reset Password</p>}
                    open={isModalOpen}
                    onOk={handleOk}
                    okText={"Send email"}
                    onCancel={handleCancel}
                    cancelText={isNotFoundError || isFound ? "Close" : "Cancel"}
                    closable={false}
                    confirmLoading={forgotPasswordIsLoading}
                    okButtonProps={{
                      disabled: isBadRequestError || isEmpty(email),
                      style: {
                        display: isNotFoundError || isFound ? "none" : "block",
                        opacity: isBadRequestError || isEmpty(email) ? 0.5 : 1,
                      },
                    }}
                  >
                    {isNotFoundError ? (
                      <>
                        {/* <p>Oops</p> */}
                        {/* <p className="ml-4"> */}
                        {/*   We can't find that email on our system */}
                        {/* </p> */}
                        <p>Success!</p>
                        <p className="ml-4">
                          If you have an email account registered we have sent an
                          email to your address with password reset instructions
                        </p>
                      </>
                    ) : isFound ? (
                      <>
                        <p>Success!</p>
                        <p className="ml-4">
                          If you have an email account registered we have sent an
                          email to your address with password reset instructions
                        </p>
                      </>
                    ) : (
                      <>
                        <label className="label">Email Address</label>
                        <div className="control">
                          <Input
                            className={`input mb-3 ${isBadRequestError ? "is-danger" : ""
                            }`}
                            type="text"
                            value={email}
                            onChange={handleOnChangeEmail}
                          />
                        </div>
                      </>
                    )}
                  </Modal>
                </label>
                <div className="control">
                  <Input
                    className="input"
                    type="password"
                    name="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label className="checkbox">
                    <Input type="checkbox" /> Remember me
                  </label>
                </div>
              </div>
              <div className="buttons is-centered mb-2">
                <button
                  className={buttonClass}
                  type={"submit"}
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
