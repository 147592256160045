import React, { useState, useEffect, Fragment } from "react";
import { useParams, Navigate } from "react-router-dom";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import MessageModal from "../components/Modals/MessageModal";
import { LoggedInStatus } from "../services/LoggedInStatus";
import Loading from "../components/Animations/Loading";
import "../css/customs/Home.scss";
import SigninForm from "../components/SigninForm";

const Login = () => {
  const [loggedIn, setLoggedIn] = useState(null);
  const [loginMessage, setLoginMessage] = useState("");
  const [loginModalVal, setLoginModalVal] = useState(false);
  const [loading, setLoading] = useState(true);
  const { home } = useParams();

  useEffect(() => {
    const li = LoggedInStatus(home);
    setLoggedIn(li);
    setLoading(false);
  }, [home]);

  const modalState = () => {
    setLoginMessage("");
  };

  const updateLogin = () => {
    setLoggedIn(true);
  };

  const handleLoginError = (msg = "") => {
    setLoginMessage("Incorrect email address or password");
  };

  const updateLoginModalVal = (val) => {
    setLoginModalVal(val);
  };

  if (loggedIn === true) {
    return <Navigate to={"/welcome"} />;
  }
  if (loading || loggedIn === null) {
    return <Loading pad={200} />;
  }
  if (loginMessage !== "") {
    return (
      <MessageModal modalState={modalState} message={loginMessage} />
    );
  }

  return (
    <Fragment>
      <Header
        updateLogin={updateLogin}
        loginError={handleLoginError}
        updateLoginModalVal={updateLoginModalVal}
        loginModalVal={loginModalVal}
        pageName={"Home"}
      />
      <SigninForm
        updateLogin={updateLogin}
        loginError={handleLoginError}
        updateLoginModalVal={updateLoginModalVal}
      />
      <Footer />
    </Fragment>
  );
};

export default Login;
