import React from "react";
import "./MainSection.scss";

const MainSection = ({ children }) => {
  return (
    <section className="main-section">
      <div className="is-clearfix">
        <div className="container">{children}</div>
      </div>
    </section>
  );
};

export default MainSection;
