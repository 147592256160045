import { Checkbox } from "antd";
import React, { useCallback } from "react";
import "./PopoverContent.scss";

const PopoverContent = ({
  setVisibleColumns,
  visibleColumns,
  visibleColumnTitle,
  setNbVisibleColums,
  filterBtn
}) => {
  const handleOnChange = useCallback(
    (e) => {
      const tempVisibleColumns = {
        ...visibleColumns,
      };
      tempVisibleColumns[e.target.name] = e.target.checked;
      setVisibleColumns(tempVisibleColumns);
      const nbTempVisibleColumns = Object.values(tempVisibleColumns).filter(
        (val) => val
      );
      setNbVisibleColums(nbTempVisibleColumns.length - 1);
    },
    [setNbVisibleColums, setVisibleColumns, visibleColumns]
  );

  return Object.entries(visibleColumnTitle).map(([key, value], index) => (
    <div key={index}>
      <Checkbox
        defaultChecked={visibleColumns[value]}
        className="website-column-list__checkbox"
        onChange={handleOnChange}
        key={index}
        name={value}
      >
        <span className="website-column-list__checkbox__item">{key}</span>
      </Checkbox>
      <br />
    </div>
  ));
};

export default PopoverContent;
