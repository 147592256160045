import React from "react";
import { Switch } from "antd";
import WebsitePopover from "../components/WebsitePopover";

export const ECOSYSTEM_TABLE_COLUMNS = [
  {
    title: "Website",
    dataIndex: "name",
    key: "name",
    align: "left",
    render: (websiteName) => {
      return <span>{websiteName}</span>;
    },
  },
  {
    title: "URL",
    dataIndex: "homepage",
    key: "homepage",
    align: "left",
    render: (homepage) => {
      return (
        <a href={homepage} className="cell-link">
          {homepage}
        </a>
      );
    },
  },
  {
    title: "Tracking",
    dataIndex: "homepage",
    key: "homepage",
    align: "center",
    render: (tracking) => {
      return <Switch defaultChecked className="tracking-switch" />;
    },
  },
  {
    title: "URLs",
    dataIndex: "numberUrl",
    key: "numberUrl",
    align: "center",
  },
  {
    title: "Products",
    dataIndex: "numberProduct",
    key: "numberProduct",
    align: "center",
    render: (nbOfProduct) => {
      return nbOfProduct === 0 ? (
        <a
          style={{ color: "#5a8efa" }}
          href="https://datanoodle.com/structured-data.html"
        >
          Not Found
        </a>
      ) : (
        nbOfProduct
      );
    },
  },
  {
    title: "Integration",
    key: "numberProduct",
    align: "center",
    render: (nbOfProduct) => {
      return <span>+ Google Analytics</span>;
    },
  },
  {
    title: "Ownership",
    dataIndex: "owner",
    key: "owner",
    align: "left",
    render: (owner) => {
      return <p>{owner}</p>;
    },
  },
  {
    title: "Industry",
    dataIndex: "industry",
    key: "industry",
    align: "left",
  },
  {
    title: "Type",
    dataIndex: "businessType",
    key: "businessType",
    align: "left",
    render: (businessType) => {
      return <p>{businessType}</p>;
    },
  },
  {
    title: "",
    key: "id",
    align: "left",
    render: (website) => (
      <>
        <WebsitePopover
          key={website.id}
          websiteId={website?.id}
          websiteName={website?.name}
          ecosystemId={website?.ecosystem_id}
        />
      </>
    ),
  },
];

export const DEFAULT_WEBPAGE_VISIBLE_COLUMNS = {
  url: true,
  website_name: true,
  num_revisions: true,
  status_code_description: true,
  status_code: true,
  webpage_created_at: true,
  last_crawled_at: true,
  web_page_updated_at: true,
  web_page_tags: true,
};

export const VISIBLE_COLUMN_TITLE = {
  Website: "website_name",
  Edits: "num_revisions",
  Status: "status_code_description",
  "Status Code": "status_code",
  Discovered: "webpage_created_at",
  "Last crawled": "last_crawled_at",
  "Last edited": "web_page_updated_at",
  "Vertical tag": "web_page_tags",
};

export const DEFAULT_PRODUCT_VISIBLE_COLUMNS = {
  website_page: true,
  website_name: true,
  product_num_revisions: true,
  product_revision_price: true,
  product_revision_price_change: true,
  product_revision_currency: true,
  product_revision_sku: true,
  product_updated_at: true,
  product_revision_availability: true,
  product_last_availability_at: true,
  product_created_at: true,
  product_revision_brand: true,
  web_page_tags: true,
};

export const PRODUCT_VISIBLE_COLUMN_TITLE = {
  Website: "website_name",
  Edits: "product_num_revisions",
  Price: "product_revision_price",
  "% change": "product_revision_price_change",
  Currency: "product_revision_currency",
  SKU: "product_revision_sku",
  "Last edited": "product_updated_at",
  Availability: "product_revision_availability",
  Duration: "product_last_availability_at",
  Discovered: "product_created_at",
  Brand: "product_revision_brand",
  "Vertical tag": "web_page_tags",
};

export const DEFAULT_WEBPAGE_FONT_SIZE = "font-size-12";
export const DEFAULT_PRODUCT_FONT_SIZE = "font-size-12";
