const WEBPAGE_FILTER_SESSION = "webpageFilterSession";
const PRODUCT_FILTER_SESSION = "productFilterSession";
const FILTER_SESSION_NAME = "filterSessionName";

const WEBPAGE_VISIBLE_COLUMN_SESSION = "webpageVisibleColumnSession";
const PRODUCT_VISIBLE_COLUMN_SESSION = "productVisibleColumnSession";

const WEBPAGE_FONT_SIZE_SESSION = "webpageFontSizeSession";
const PRODUCT_FONT_SIZE_SESSION = "productFontSizeSession";

export {
  WEBPAGE_FILTER_SESSION,
  PRODUCT_FILTER_SESSION,
  FILTER_SESSION_NAME,
  WEBPAGE_VISIBLE_COLUMN_SESSION,
  PRODUCT_VISIBLE_COLUMN_SESSION,
  WEBPAGE_FONT_SIZE_SESSION,
  PRODUCT_FONT_SIZE_SESSION,
};
