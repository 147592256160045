export const industries = {
    "Accounting & Auditing": "accountingAuditing",
    "Alcohol/Liquor": "alcoholLiquor",
    "Amusement & Recreation": "amusementRecreation",
    "Apparel/Fashion": "apparelFashion",
    "Appliances": "appliances",
    "Bags": "bags",
    "Banking": "banking",
    "Consumer Electronics": "consumerElectronics",
    "Beauty & Cosmetics": "cosmeticBodyCare",
    "Digital Services": "digitalServices",
    "Education (online Courses)": "educationOnlineCourses",
    "Food & Beverage": "foodBeverage",
    "Footwear": "footwear",
    "Furniture & Lighting": "furnitureLighting",
    "Groceries": "grocery",
    "Health & Wellness": "healthWellness",
    "Home & Garden": "homeGarden",
    "Hotels": "hotels",
    "Insurance": "insurance",
    "Jewellery & Watches": "jewelleryWatches",
    "Legal Services": "law",
    "Logistics": "logistics",
    "Office Supplies": "officeSupplies",
    "Pet Care": "petCare",
    "Pharmacies": "pharmacies",
    "Real Estate": "realEstate",
    "Restaurants & Bars": "restaurants",
    "Sports & Fitness": "sportsFitness",
    "Telecommunications": "telecom",
    "Toys & Hobbies": "toysHobbies",
    "Travel": "travel",
    "Video Games, Consoles & Accessories": "videoGames",
    "Other": "other",
  };