import React from "react";
import WelcomeContent from "./WelcomeContent";
import WelcomeHeader from "./WelcomeHeader";

const Welcome = ({
  teamId,
  ecosystems,
  isLoadingGetAllEcosystems,
}) => {
  return (
    <>
      <WelcomeHeader
        teamId={teamId}
        hasEcosystems={ecosystems?.length > 0}
        ecosystems={ecosystems}
      />
      <WelcomeContent
        ecosystems={ecosystems}
        teamId={teamId}
        isLoadingGetAllEcosystems={isLoadingGetAllEcosystems}
      />
    </>
  );
};

export default Welcome;
