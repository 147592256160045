import React, { Fragment, useState } from "react";
import Loading from "../Animations/Loading";

/**
 * props
 *
 * REQUIRED
 * modalState = true|false
 * changeModalState() (!modalState)
 * buttonText = string
 * fields[
 *  {
 *      REQUIRED
 *      type: string (text|textarea|select|checkbox) radio not supported yet
 *      label: string
 *
 *      IF APPLICABLE
 *      selectOptions: object {id:value, ...}
 *
 *      OPTIONAL
 *      placeholder: string
 *      help: string
 *      defaultValue: string
 *      iconLeft: string (className) not supported yet
 *      iconRight: string (className) not supported yet
 *  }
 * ]
 *
 * OPTIONAL
 * title = string
 * description = string
 */

function FormModal(props) {
  // Initialise field values
  const ff = Array(props.fields.length).fill("", 0, props.fields.length);
  props.fields.map((field, i) => (ff[i] = field.defaultValue ?? ""));
  const [fieldValues, setFieldValues] = useState(ff);

  function updateValue(e) {
    const newFields = [...fieldValues];
    newFields[e.target.getAttribute("data-idx")] = e.target.value;
    setFieldValues(newFields);
  }

  function closeModal() {
    props.changeModalState();
  }

  function submitForm() {
    props.response(fieldValues);
  }

  if (fieldValues === null) {
    return <Loading />;
  } else {
    return (
      <div className={props.modalState ? "modal is-active" : "modal"}>
        <div className="modal-background" onClick={closeModal}></div>
        <div className="modal-content small-modal modal-padding has-background-white">
          {props.title && (
            <div className="modal-heading">
              <h2 className="title is-size-4 has-text-weight-normal">
                {props.title}
              </h2>
              {props.description && <p>{props.description}</p>}
            </div>
          )}
          {props.fields.map((field, i) => {
            let dv = field.defaultValue ?? "";
            switch (field.type) {
              case "text":
                return (
                  <div className="field" key={i}>
                    {field.label && (
                      <label className="label">{field.label}</label>
                    )}
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        name={field.name}
                        placeholder={field.placeholder ?? ""}
                        defaultValue={dv}
                        onChange={updateValue}
                        data-idx={i}
                        onKeyUp={(e) => e.key === "Enter" && submitForm}
                      />
                    </div>
                    {field.help && <p className="help">{field.help}</p>}
                  </div>
                );
              case "textarea":
                return <div className="is-warning">Field type error!</div>;
              case "select":
                return (
                  <div className="field" key={i}>
                    {field.label && (
                      <label className="label">{field.label}</label>
                    )}
                    <div className="control">
                      <div className="select" key={i}>
                        <select onChange={updateValue} data-idx={i}>
                          <Fragment>
                            <option>Please select</option>
                            {field.selectOptions.map((option, j) => (
                              <option key={j} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </Fragment>
                        </select>
                      </div>
                    </div>
                    {field.help && <p className="help">{field.help}</p>}
                  </div>
                );
              case "checkbox":
                return <div className="is-warning">Field type error!</div>;
              case "label":
                return (
                  <div className="field" key={i}>
                    {field.label && (
                      <label className="label has-text-lightgray has-text-weight-normal">
                        {field.label}
                      </label>
                    )}
                  </div>
                );
              default:
                return <div className="is-warning">Field type error!</div>;
            }
          })}
          <div className="buttons is-right">
            <button
              className="button button-outlined-light-blue"
              onClick={closeModal}
            >
              {props.buttonTextCancel ?? "ERROR!"}
            </button>
            <button
              className="button button-contained-light-blue"
              onClick={submitForm}
            >
              {props.buttonText ?? "ERROR!"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default FormModal;
