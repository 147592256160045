import React, { Fragment, useState } from "react";
import SignupFailureModal from "./../Modals/SignupFailureModal/SignupFailureModal";
import { PostData } from "../../services/PostData";
import { Navigate } from "react-router";
import Cookies from "universal-cookie";
import Loading from "./../Animations/Loading";
import "./SignupForm.scss";
import TagManager from "react-gtm-module";
import { Input } from "antd";

const tagManagerArgs = {
  dataLayer: {
    userId: "",
    event: "register",
  },
  dataLayerName: "Datanoodle",
};

const tagManagerLoginArgs = {
  dataLayer: {
    userId: "",
    event: "login",
  },
  dataLayerName: "Datanoodle",
};

function SignupForm() {
  const [signupValues, setSignupValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    trial: true,
    plan: "starter",
    plan_schedule: "monthly",
  });
  const [errValues, setErrValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const [signupModal, setSignupModal] = useState(false);
  const [takenEmail, setTakenEmail] = useState("");

  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  function closeSignupFailureModal() {
    setSignupModal(false);
  }

  function signupChange(e) {
    setSignupValues({ ...signupValues, [e.target.name]: e.target.value });
    setDisableBtn(false);
  }

  function signup() {
    // setSignupActive(true);
    setDisableBtn(true);
    let errors = false;
    const errList = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    };
    if (/^([a-zA-Z0-9 _-]+)$/.test(signupValues.first_name)) {
      errList.firstName = "";
    } else {
      errList.firstName = "This field is invalid";
      errors = true;
    }
    if (/^([a-zA-Z0-9 _-]+)$/.test(signupValues.last_name)) {
      errList.lastName = "";
    } else {
      errList.lastName = "This field is invalid";
      errors = true;
    }
    if (/.+@.+/.test(signupValues.email)) {
      errList.email = "";
    } else {
      errList.email = "This field is invalid";
      errors = true;
    }
    if (signupValues.password.length < 8) {
      errList.password = "This field must be at least 8 characters in length";
      errors = true;
    } else {
      errList.password = "";
    }
    setErrValues(errList);
    if (!errors) {
      const postOptions = {
        postRoute: "/user",
        method: "POST",
        token: false,
        formData: signupValues,
      };
      PostData(postOptions).then((result) => {
        const responseJSON = result;
        if (responseJSON.success === true) {
          tagManagerArgs.dataLayer.userId = responseJSON.data.gtm_id;
          TagManager.dataLayer(tagManagerArgs);
          login(result.data.email);
        } else {
          if (result.errors.email) {
            setTakenEmail(signupValues.email);
          }
          setSignupModal(true);
        }
      });
    }
  }
  function login(email) {
    const loginDetails = {
      email: email,
      password: signupValues.password,
    };
    const postOptions = {
      postRoute: "/oauth/access_token",
      method: "POST",
      token: false,
      formData: loginDetails,
    };
    PostData(postOptions).then((result) => {
      const responseJSON = result;
      if (responseJSON.success === true) {
        tagManagerLoginArgs.dataLayer.userId = responseJSON.data.gtm_id;
        TagManager.dataLayer(tagManagerLoginArgs);
        let udStr = JSON.stringify(responseJSON);
        udStr = btoa(udStr);
        sessionStorage.setItem("DataNoodleSession", udStr);
        const cookies = new Cookies();
        cookies.set("DataNoodleSession", udStr, { path: "/" });
        // setSess(responseJSON);
        updateLogin();
      } else {
        setLoading(true);
      }
    });
  }

  function updateLogin() {
    setLoggedIn(true);
  }

  if (loggedIn === true) {
    return <Navigate to={"/welcome"} />;
  }
  if (loading || loggedIn === null) {
    return <Loading pad={200} />;
  }
  return (
    <Fragment>
      <SignupFailureModal
        closeSignupFailureModal={closeSignupFailureModal}
        signupModal={signupModal}
        takenEmail={takenEmail}
      />
      <section
        className="section home-content has-background-light-grey"
        style={{ flexGrow: 2 }}
      >
        <div className="container">
          <div className="box max-width-box">
            <h4 className="subtitle is-4 dropdown-seprate has-text-centered">
              Create your Account
            </h4>
            <div className="content">
              <div className="field-body">
                <div className="field">
                  <label>First name</label>
                  <p className="control is-expanded">
                    <Input
                      className="input"
                      type="text"
                      name="first_name"
                      onChange={signupChange}
                    />
                  </p>
                  <p className="help is-danger">{errValues.firstName}</p>
                </div>
                <div className="field">
                  <label>Last name</label>
                  <p className="control is-expanded">
                    <Input
                      className="input"
                      type="text"
                      name="last_name"
                      onChange={signupChange}
                    />
                  </p>
                  <p className="help is-danger">{errValues.lastName}</p>
                </div>
              </div>

              <div className="field">
                <label>Email</label>
                <div className="control">
                  <Input
                    className="input"
                    type="email"
                    name="email"
                    onChange={signupChange}
                  />
                </div>
                <p className="help is-danger">{errValues.email}</p>
              </div>
              <div className="field mb-5">
                <label>Password</label>
                <div className="control">
                  <Input
                    className="input"
                    type="password"
                    name="password"
                    onChange={signupChange}
                  />
                </div>
                <p className="help is-danger">{errValues.password}</p>
              </div>
              <div className="buttons is-centered">
                <button
                  disabled={disableBtn}
                  className="button is-success is-rounded create-acc-btn"
                  onClick={(e) => signup()}
                >
                  Create Your Account
                </button>
              </div>
              <div className="is-size-7 terms-privacy">
                By clicking "Create Your Account" I agree to Datanoodle's{" "}
                <a
                  href="https://datanoodle.com/terms.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://datanoodle.com/privacy.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default SignupForm;