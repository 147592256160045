import { useQuery } from "react-query";
import axiosClient, { setAccessToken } from "../../client/restApi";

const ECOSYSTEM_ID_INDEX = 2;
const WEBSITE_FILTER_INDEX = 3;
const TAG_FILTER_INDEX = 4;

export const useGetProductStatisticOfEcosystem = (
  ecosystemId,
  websiteFilter,
  tagFilter
) => {
  return useQuery(
    [
      "productTracking",
      "product-statistic",
      ecosystemId,
      websiteFilter,
      tagFilter,
    ],
    fetchProductStatisticOfEcosystem,
    { enabled: ecosystemId !== undefined }
  );
};

const fetchProductStatisticOfEcosystem = async ({ queryKey }) => {
  await setAccessToken();
  const response = await axiosClient.get(
    `/ecosystems/${queryKey[ECOSYSTEM_ID_INDEX]}/products-statistic`,
    {
      params: {
        website: queryKey[WEBSITE_FILTER_INDEX],
        tag: queryKey[TAG_FILTER_INDEX],
      },
    }
  );
  return response.data.data || {};
};
