import React, { Fragment, useState } from "react";
import { PostData } from "../services/PostData";
import { Input } from "antd";

function UpdatePasswordForm(props) {
  const [updateValues, setUpdateValues] = useState({
    password: "",
    newpassword: "",
    password_confirmation: "",
  });
  const [errValues, setErrValues] = useState({
    password: "",
    newpassword: "",
    password_confirmation: "",
  });
  const [updatePassword, setUpdatePassword] = useState(false);
  const [resultPassword, setResultPassword] = useState(true);

  function updateValue(e) {
    setUpdateValues({ ...updateValues, [e.target.name]: e.target.value });
  }

  function update() {
    let errors = false;
    const errList = {
      password: "",
      newpassword: "",
      confirmPassword: "",
    };
    if (updateValues.password.length < 7) {
      errList.password = "This field is invalid";
      errors = true;
    } else {
      errList.password = "";
    }
    if (updateValues.newpassword.length < 7) {
      errList.newpassword = "This field is invalid";
      errors = true;
    } else {
      errList.newpassword = "";
      if (updateValues.password === updateValues.newpassword) {
        errList.newpassword = "Can not use current password";
        errors = true;
      }
    }
    if (updateValues.password_confirmation.length < 7) {
      errList.confirmPassword = "This field is invalid";
      errors = true;
    } else {
      errList.confirmPassword = "";
      if (updateValues.password_confirmation !== updateValues.newpassword) {
        errList.newpassword = "Passwords don't match";
        errList.confirmPassword = "Passwords don't match";
        errors = true;
      } else {
        errList.confirmPassword = "";
      }
    }

    setErrValues(errList);
    if (!errors) {
      const postOptions = {
        postRoute: "/user/" + props.userId + "/password",
        method: "PUT",
        token: true,
        formData: updateValues,
      };
      PostData(postOptions).then((result) => {
        console.log(result);
        const responseJSON = result;

        if (responseJSON.data === "Wrong password information") {
          setUpdatePassword(false);
          setResultPassword(false);
        } else {
          setUpdatePassword(true);
        }
      });
    }
  }

  return (
    <Fragment>
      <div style={{ backgroundColor: "#f7fbfa", flexGrow: 2 }}>
        {updatePassword && (
          <div className="notification is-success">
            Your password has been successfully changed
          </div>
        )}
        {!updatePassword && !resultPassword && (
          <div class="notification is-danger">Password is Incorrect</div>
        )}
        <div className="card-profile center-screen">
          <div className="card-content">
            <div className="content">
              <div className="field">
                <label>Password</label>
                <div className="control">
                  <Input
                    className="input"
                    type="password"
                    name="password"
                    placeholder="Password"
                    defaultValue=""
                    onChange={updateValue}
                  />
                </div>
                <p className="help is-danger">{errValues.password}</p>
              </div>
              <div className="field">
                <label>New password</label>
                <div className="control">
                  <Input
                    className="input"
                    type="password"
                    name="newpassword"
                    placeholder="New password"
                    defaultValue=""
                    onChange={updateValue}
                  />
                </div>
                <p className="help is-danger">{errValues.newpassword}</p>
              </div>
              <div className="field">
                <label>Confirm password</label>
                <div className="control">
                  <Input
                    className="input"
                    type="password"
                    name="password_confirmation"
                    placeholder="Confirm password"
                    defaultValue=""
                    onChange={updateValue}
                  />
                </div>
                <p className="help is-danger">{errValues.confirmPassword}</p>
              </div>
              <button className="button is-primary" onClick={update}>
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default UpdatePasswordForm;
