import { Button, Col, Input, Radio, Row, Space } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import "./index.scss";

const PopoverContent = ({ setIsSelect, setFilters, filters, type, customKeys }) => {
  const [operator, setOperator] = useState("");
  const [value, setValue] = useState("");
  const [choice, setChoice] = useState("");

  useEffect(() => {
    setIsSelect(operator !== "");
  }, [setIsSelect, operator]);

  const onChange = useCallback(
    (e) => {
      setOperator(e.target.approach);
      setIsSelect(operator !== "");
      setValue("");
      setChoice(e.target.value);
    },
    [operator, setIsSelect],
  );

  const handleApplyOnClick = useCallback(() => {
    let changedFilters = { ...filters };

    function splitAndPreserveQuotes(input) {
      let parts = [];
      let currentPart = "";
      let quoted = false;

      for (let i = 0; i < input.length; i++) {
        const char = input[i];
        const nextChar = input[i + 1];

        if (char === "\"") {
          quoted = !quoted;
          // If quoted part ends and the next character is alphanumeric, we should split here
          if (!quoted && nextChar && /\w/.test(nextChar)) {
            parts.push(currentPart + char);
            currentPart = "";
          } else {
            currentPart += char;
          }
        } else if ((char === "," || char === " ") && !quoted) {
          // Unquoted comma and whitespace always splits
          parts.push(currentPart.trim());
          currentPart = "";
          // Trim next part if it's a space
          if (nextChar === " ") {
            i++;
          }
        } else {
          currentPart += char; // collect char into current part
        }
      }

      parts.push(currentPart.trim());  // push final part after loop completes

      var filteredArr = parts.filter(Boolean);
      return filteredArr.join(",");
    }

    if (operator === "contains" || operator === "isAnyOf") {
      const valueArray = splitAndPreserveQuotes(value);
      changedFilters[type] = {
        value: valueArray,
        operator: operator,
      };
    } else {
      changedFilters[type] = {
        value: value.toLowerCase(),
        operator: operator,
      };
    }

    setFilters(changedFilters);
  }, [filters, operator, setFilters, type, value]);

  const handleRefreshOnClick = useCallback(() => {
    setOperator("");
    setValue("");
    setChoice("");

    const changedFilters = { ...filters };
    if (type in changedFilters) {
      delete changedFilters[type];
    }

    setFilters(changedFilters);
  }, [filters, setFilters, type]);

  const handleInputOnChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  return (
    <Radio.Group
      onChange={onChange}
      value={choice}
      className="text-filter-popover-content"
    >
      <Space direction="vertical">
        {customKeys && typeof customKeys === "object" && Object.entries(customKeys).map(([key, value]) => (
          <Radio key={key} value={value} approach={key}>
            <Space direction="vertical" style={{width:"200px"}}>
              <span className="text-filter-title">{value}</span>
              {operator === key ? (
                <Input
                  className="text-filter-input"
                  onChange={handleInputOnChange}
                />
              ) : null}
            </Space>
          </Radio>
        ))}
        <Row align="middle" justify="space-between">
          <Col>
            <Button className="text-filter-button" onClick={handleApplyOnClick}>
              Apply
            </Button>
          </Col>
          <Col>
            <img
              style={{ cursor: "pointer" }}
              src="/images/icon-refresh.png"
              alt="Icon Refresh"
              onClick={handleRefreshOnClick}
            />
          </Col>
        </Row>
      </Space>
    </Radio.Group>
  );
};

export default PopoverContent;
