import { Col, Row } from "antd";
import React, { useMemo } from "react";
import Loading from "../../../../components/Animations/Loading";
import LineChartContainer from "../LineChartContainer";
import SinglePointChartContainer from "../SinglePointChartContainer";
import "./WebsitePageRevisionChangesChart.scss";
import MainChartFilter from "./MainChartFilter";
import { useGetAllChangesBelongsToEcosystem } from "../../../utils/Queries/WebpageRevisionQueries";

const WebsitePageRevisionChangesChart = ({
  chartTypeLabels,
  dateFrom,
  handleSelectChartType,
  ecosystem,
  websiteFilter,
  tagFilter,
  chartType,
  setHideLegends,
  hideLegends,
}) => {
  const colorPallette = useMemo(
    () => [
      "#2e4cbd",
      "#8843b8",
      "#c035a7",
      "#e92f8e",
      "#ff3f70",
      "#ff5f51",
      "#ff8230",
      "#ffa600",
    ],
    []
  );

  const {
    data: chartData,
    isLoading,
    isSuccess,
    isError,
  } = useGetAllChangesBelongsToEcosystem(
    ecosystem?.id,
    dateFrom,
    websiteFilter,
    tagFilter
  );

  return (
    <div className="main-webpage-changes-chart-container">
      <MainChartFilter
        chartTypeLabels={chartTypeLabels}
        dateFrom={dateFrom}
        handleSelectChartType={handleSelectChartType}
        ecosystem={ecosystem}
        chartType={chartType}
      />
      <Row className="main-chart">
        <Col span={23}>
          {isLoading ? (
            <Loading />
          ) : isSuccess && !isError && chartData?.chartData?.length > 0 ? (
            <LineChartContainer
              chartData={chartData}
              chartTotal={chartData?.total}
              colorPallette={colorPallette}
              chartType={chartType}
              hideLegends={hideLegends}
              setHideLegends={setHideLegends}
            />
          ) : (
            <SinglePointChartContainer
              chartData={chartData}
              chartTotal={chartData?.total}
              colorPallette={colorPallette}
              hideLegends={hideLegends}
              setHideLegends={setHideLegends}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default WebsitePageRevisionChangesChart;
