import React, { Fragment } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./SubHeader.scss";

export default function SubHeader(props) {
  const history = useNavigate();
  let pathname = useLocation().pathname;
  let trackingDashboardActive =
    pathname.startsWith(`/ecosystem`) &&
      pathname.endsWith("/tracking-dashboard")
      ? true
      : false;
  let websitePagesActive =
    pathname.startsWith(`/ecosystem`) && pathname.endsWith("/webpages")
      ? true
      : false;
  let productsActive =
    pathname.startsWith(`/ecosystem`) && pathname.endsWith("/products")
      ? true
      : false;
  let settingsActive =
    pathname.startsWith(`/ecosystem`) && pathname.endsWith("/vertical-tags")
      ? true
      : false;
  let productRevision =
    pathname.includes("/products") && pathname.includes("revisions");

  const previousBreadcrumbUrl = productRevision
    ? "/" + pathname.split("/").slice(1, 3).join("/") + "/products"
    : "/" + pathname.split("/").slice(1, 4).join("/");

  const breadcrumbs = [];
const handleGoBack = () => {
  history.goBack();
};

  if (props.revisionId) {
    breadcrumbs.push({
      link:
        "/website-pages/" + props.websiteId + "/revisions/" + props.revisionId,
      name: props.revisionName,
    });
  }

  return (
    <Fragment>
      <section
        className={
          "section sub-header pt-5" +
          (trackingDashboardActive || websitePagesActive || productsActive || settingsActive
            ? " pb-0"
            : " pb-5")
        }
      >
        <div className="container">
          {props.userName && (
            <>
              <h1>Hi, {props.userName}</h1>
            </>
          )}
          {props.ecosystemName !== null &&
            props.ecosystemName !== undefined && (
              <Fragment>
                <h1>{props.ecosystemName}</h1>
                {(trackingDashboardActive ||
                  websitePagesActive ||
                  productsActive ||
                  settingsActive) && (
                  <div className="nav-subheader pt-4">
                    <Link
                      to={`/ecosystem/${props.ecosystemId}/tracking-dashboard`}
                      className={
                        "nav-subheader-link" +
                        (trackingDashboardActive ? " is-active" : "")
                      }
                    >
                      Tracking
                    </Link>

                    <Link
                      to={`/ecosystem/${props.ecosystemId}/webpages`}
                      className={
                        "nav-subheader-link" +
                        (websitePagesActive ? " is-active" : "")
                      }
                    >
                      Website data
                    </Link>
                    <Link
                      to={`/ecosystem/${props.ecosystemId}/products`}
                      className={
                        "nav-subheader-link" +
                        (productsActive ? " is-active" : "")
                      }
                    >
                      Product data
                    </Link>
                    <Link
                      to={`/ecosystem/${props.ecosystemId}/vertical-tags`}
                      className={
                        "nav-subheader-link" +
                        (settingsActive ? " is-active" : "")
                      }
                    >
                      Vertical tags
                    </Link>
                  </div>
                )}
              </Fragment>
            )}
        </div>
      </section>
      {breadcrumbs.length > 0 && (
        <section className="section breadcrumbs py-4">
          <div className="container">
            <nav
              className="breadcrumb"
              aria-label="breadcrumbs"
              style={{ whiteSpace: "pre-wrap" }}
            >
              <ul style={{ marginBottom: "0px" }}>
                {breadcrumbs.map((bc, i) => (
                  <Link
                    to={{
                      pathname: previousBreadcrumbUrl,
                      state: { prevPath: pathname, isSaveSession: true },
                    }}
                    onClick={handleGoBack}
                    aria-current={i === breadcrumbs.length - 1 ? "page" : ""}
                    style={{ color: "#000" }}
                    key={i}
                  >
                    <li
                      className={
                        i === breadcrumbs.length - 1 ? "is-active" : ""
                      }
                      key={i}
                    >
                      <i
                        key={i}
                        className="fa fa-chevron-circle-left cursor-pointer"
                        aria-hidden="true"
                      ></i>
                      &nbsp;&nbsp;
                      {bc.name}
                    </li>
                  </Link>
                ))}
              </ul>
            </nav>
          </div>
        </section>
      )}
    </Fragment>
  );
}
