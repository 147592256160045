import { useQuery } from "react-query";
import axiosClient, { setAccessToken } from "../../client/restApi";
import { isEmptyDate } from "../DateTimeUtil";

const ECOSYSTEM_ID_INDEX = 2;
const DATE_FROM_INDEX = 3;
const WEBSITE_FILTER_INDEX = 4;
const TAG_FILTER_INDEX = 5;

export const useGetAllChangesBelongsToEcosystem = (
  ecosystemId,
  dateFrom,
  websiteFilter,
  tagFilter
) => {
  return useQuery(
    [
      "websiteTracking",
      "webpages-changesChart",
      ecosystemId,
      dateFrom,
      websiteFilter,
      tagFilter,
    ],
    fetchAllChangesBelongsToEcosystem,
    { enabled: !isEmptyDate(dateFrom) }
  );
};

const fetchAllChangesBelongsToEcosystem = async ({ queryKey }) => {
  await setAccessToken();
  const response = await axiosClient.get(
    `/ecosystems/${queryKey[ECOSYSTEM_ID_INDEX]}/webpages-changes-chart`,
    {
      params: {
        dateFrom: queryKey[DATE_FROM_INDEX],
        website: queryKey[WEBSITE_FILTER_INDEX],
        tag: queryKey[TAG_FILTER_INDEX],
      },
    }
  );
  return response.data.data || {};
};

export const useGetAllStatusBelongsToEcosystem = (
  ecosystemId,
  dateFrom,
  websiteFilter,
  tagFilter,
  chartType
) => {
  return useQuery(
    [
      "websiteTracking",
      "webpages-statusChart",
      ecosystemId,
      dateFrom,
      websiteFilter,
      tagFilter,
    ],
    fetchAllStatusBelongsToEcosystem,
    { enabled: !isEmptyDate(dateFrom) }
  );
};

const fetchAllStatusBelongsToEcosystem = async ({ queryKey }) => {
  await setAccessToken();
  const response = await axiosClient.get(
    `/ecosystems/${queryKey[ECOSYSTEM_ID_INDEX]}/webpages-status-chart`,
    {
      params: {
        dateFrom: queryKey[DATE_FROM_INDEX],
        website: queryKey[WEBSITE_FILTER_INDEX],
        tag: queryKey[TAG_FILTER_INDEX],
      },
    }
  );
  return response.data.data || {};
};
