import { createSlice } from '@reduxjs/toolkit';

const currencySlice = createSlice({
  name: 'currencies',
  initialState: {
    currencies: [],
    loading: false,
    error: null,
  },
  reducers: {
    setCurrencies(state, action) {
      state.currencies = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { setCurrencies, setLoading, setError } = currencySlice.actions;

export default currencySlice.reducer;
