import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import "./SubHeader.scss";

export default function SubHeaderUser(props) {
  let pathname = useLocation().pathname;
  let userProfileActive = pathname.startsWith(
    "/user/" + props.userId + "/profile"
  )
    ? true
    : false;
  let productsActive = pathname.startsWith(
    "/user/" + props.userId + "/password"
  )
    ? true
    : false;
  let settingsActive = pathname.startsWith("/user/" + props.userId + "/billing")
    ? true
    : false;

  const breadcrumbs = [];

  if (props.ecosystemId) {
    breadcrumbs.push({
      link: "/user/" + props.userId + "/settings/",
      name: props.ecosystemName,
    });
  }
  if (props.websiteId) {
    breadcrumbs.push({
      link: "/website-pages/" + props.websiteId,
      name: props.websiteName,
    });
  }
  if (props.revisionId) {
    breadcrumbs.push({
      link:
        "/website-pages/" + props.websiteId + "/revisions/" + props.revisionId,
      name: props.revisionName,
    });
  }

  return (
    <Fragment>
      <section
        className={
          "section sub-header pt-5" +
          (userProfileActive || productsActive || settingsActive
            ? " pb-0"
            : " pb-5")
        }
      >
        <div className="container">
          <Fragment>
            <h1 className="has-text-white is-size-3">{props.firstName}</h1>
            {(userProfileActive || productsActive || settingsActive) && (
              <div className="nav-subheader pt-4">
                <Link
                  to={"/user/" + props.userId + "/profile"}
                  className={
                    "nav-subheader-link" +
                    (userProfileActive ? " is-active" : "")
                  }
                >
                  Profile
                </Link>
                <Link
                  to={"/user/" + props.userId + "/password"}
                  className={
                    "nav-subheader-link" + (productsActive ? " is-active" : "")
                  }
                >
                  Password
                </Link>
                <Link
                  to={"/user/" + props.userId + "/billing"}
                  className={
                    "nav-subheader-link" + (settingsActive ? " is-active" : "")
                  }
                >
                  Billings
                </Link>
              </div>
            )}
          </Fragment>
        </div>
      </section>
      {breadcrumbs.length > 0 && (
        <section className="section breadcrumbs py-1">
          <div className="container">
            <nav className="breadcrumb" aria-label="breadcrumbs">
              <ul>
                {breadcrumbs.map((bc, i) => (
                  <li
                    className={i === breadcrumbs.length - 1 ? "is-active" : ""}
                    key={i}
                  >
                    <Link
                      to={bc.link}
                      aria-current={i === breadcrumbs.length - 1 ? "page" : ""}
                    >
                      {bc.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </section>
      )}
    </Fragment>
  );
}
