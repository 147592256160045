import React, { Fragment, useState } from "react";
import SignupFailureModal from "../Modals/SignupFailureModal/SignupFailureModal";
import { PostData } from "../../services/PostData";
import "./UpdateProfileForm.scss";
import { Input } from "antd";

function UpdateProfileForm(props) {
  const [updateValues, setUpdateValues] = useState({
    first_name: props.firstName,
    last_name: props.lastName,
    username: props.userName,
    email: props.email,
  });
  const [errValues, setErrValues] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
  });

  const [updateProfile, setupdateProfile] = useState(false);

  const [updateModal, setUpdateModal] = useState(false);

  function closeUpdateFailureModal() {
    setUpdateModal(false);
  }

  function updateValue(e) {
    setUpdateValues({ ...updateValues, [e.target.name]: e.target.value });
  }

  function getThisUser() {
    props.getThisUser();
  }

  function updateUser() {
    let errors = false;
    const errList = {
      firstName: "",
      lastName: "",
      username: "",
      email: "",
    };
    if (
      /^([a-zA-Z0-9]+)$/.test(updateValues.username) &&
      updateValues.username.length > 3
    ) {
      errList.username = "";
    } else {
      errList.username = "This field is invalid";
      errors = true;
    }
    if (/^([a-zA-Z0-9 _-]+)$/.test(updateValues.first_name)) {
      errList.firstName = "";
    } else {
      errList.firstName = "This field is invalid";
      errors = true;
    }
    if (/^([a-zA-Z0-9 _-]+)$/.test(updateValues.last_name)) {
      errList.lastName = "";
    } else {
      errList.lastName = "This field is invalid";
      errors = true;
    }
    if (/.+@.+/.test(updateValues.email)) {
      errList.email = "";
    } else {
      errList.email = "This field is invalid";
      errors = true;
    }
    setErrValues(errList);
    if (!errors) {
      const postOptions = {
        postRoute: "/user/" + props.userId,
        method: "PUT",
        token: true,
        formData: updateValues,
      };
      PostData(postOptions).then((result) => {
        const responseJSON = result;
        if (responseJSON.success === true) {
          getThisUser();
          setupdateProfile(true);
        } else {
          setUpdateModal(true);
        }
      });
    }
  }
  return (
    <Fragment>
      <div style={{ backgroundColor: "#f7fbfa", flexGrow: 2 }}>
        {updateProfile && (
          <div class="notification is-success">
            Your profile has been successfully changed
          </div>
        )}
        <SignupFailureModal
          closeSignupFailureModal={closeUpdateFailureModal}
          signupModal={updateModal}
        />
        <div className="card-profile">
          <div className="card-content">
            <div className="content">
              <div className="field">
                <label>First name</label>
                <div className="control">
                  <Input
                    className="input"
                    type="text"
                    name="first_name"
                    defaultValue={props.firstName}
                    onChange={updateValue}
                  />
                </div>
                <p className="help is-danger">{errValues.firstName}</p>
              </div>
              <div className="field">
                <label>Last name</label>
                <div className="control">
                  <Input
                    className="input"
                    type="text"
                    name="last_name"
                    defaultValue={props.lastName}
                    onChange={updateValue}
                  />
                </div>
                <p className="help is-danger">{errValues.lastName}</p>
              </div>
              <div className="field">
                <label>Username</label>
                <div className="control">
                  <Input
                    className="input"
                    type="text"
                    name="username"
                    defaultValue={props.userName}
                    onChange={updateValue}
                  />
                </div>
                <p className="help is-danger">{errValues.username}</p>
              </div>
              <div className="field">
                <label>Email address</label>
                <div className="control">
                  <Input
                    className="input"
                    type="email"
                    name="email"
                    defaultValue={props.email}
                    onChange={updateValue}
                  />
                </div>
                <p className="help is-danger">{errValues.email}</p>
              </div>
              <div className="buttons">
                <button className="button is-primary" onClick={updateUser}>
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default UpdateProfileForm;
