import React, { useState, useEffect, Fragment } from "react";
import { Navigate } from "react-router-dom";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import MessageModal from "../components/Modals/MessageModal";
import { LoggedInStatus } from "../services/LoggedInStatus";
import SignupForm from "../components/SignupForm";
import Loading from "../components/Animations/Loading";
import Cookies from "universal-cookie";
import "../css/customs/Home.scss";

const HomePage = () => {
  const [loggedIn, setLoggedIn] = useState(null);
  const [loginMessage, setLoginMessage] = useState("");
  const [loginModalVal, setLoginModalVal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const dnSess = sessionStorage.getItem("DataNoodleSession");
    if (!dnSess) {
      const cookies = new Cookies();
      cookies.remove("DataNoodleSession");
    }

    const li = LoggedInStatus("home");
    setLoggedIn(li);
    setLoading(false);
  }, []);

  const modalState = () => {
    setLoginMessage("");
  };

  const updateLogin = () => {
    setLoggedIn(true);
  };

  const handleLoginError = (msg = "") => {
    setLoginMessage("Incorrect email address or password");
  };

  const updateLoginModalVal = (val) => {
    setLoginModalVal(val);
  };

  if (loggedIn === true) {
    return <Navigate to={"/welcome"} />;
  }
  if (loading || loggedIn === null) {
    return <Loading pad={200} />;
  }
  if (loginMessage !== "") {
    return (
      <MessageModal modalState={modalState} message={loginMessage} />
    );
  }

  return (
    <Fragment>
      <Header
        updateLogin={updateLogin}
        loginError={handleLoginError}
        updateLoginModalVal={updateLoginModalVal}
        loginModalVal={loginModalVal}
        pageName={"Home"}
      />
      <SignupForm />
      <Footer />
    </Fragment>
  );
};

export default HomePage;
