import { FILTER_SESSION_NAME } from "../constants/FilterSessionName";

const getFilterSession = (filterSessionKey) => {
  const filterSessionName = sessionStorage.getItem(FILTER_SESSION_NAME);
  if (filterSessionKey) {
    if (filterSessionKey !== filterSessionName) {
      return null;
    }
  }

  if (filterSessionName !== "" && filterSessionName !== null) {
    const filterSession = JSON.parse(sessionStorage.getItem(filterSessionName));
    return filterSession;
  }

  return null;
};

export { getFilterSession };
