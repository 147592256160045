import { PostData } from './PostData';

export function GetEcosystems(mustUpdate = false) {

    return new Promise((resolve, reject) => {
        // Get all ecosystems
        let allEcosystems = sessionStorage.getItem('ecosystems');
        if (allEcosystems !== null && !mustUpdate) {
            const ecosystems = JSON.parse(allEcosystems);
            resolve(ecosystems);
        } else {
            const postOptions = {
                postRoute: '/ecosystems',
                method: 'GET',
                token: true
            }
            PostData(postOptions).then((result) => {
                const res = result;
                if(res.data !== undefined) {
                    sessionStorage.setItem('ecosystems', JSON.stringify(res));
                    resolve(res);
                } else {
                    reject(res);
                }
            });
        }
    });

}